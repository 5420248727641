import React from 'react'
import CardForm from './CardForm'
import { connect } from 'react-redux'
import {
  fetchCard,
  createCard,
  updateCard,
  clearCard,
} from '../../actions/remote_api'
import { submit, stopSubmit } from 'redux-form'

class CardFormPage extends React.Component {
  formId = 'card'
  state = { formData: {} }

  componentDidMount() {
    const { fetchCard, id, clearCard } = this.props

    clearCard()
    if (id !== undefined) {
      fetchCard(id)
    }
  }

  componentWillUnmount() {
    this.props.clearCard()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.card) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.card },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }
  }

  handleSubmit = formData => {
    const { createCard, updateCard, id } = this.props
    const card = formData
    if (id === undefined) createCard(card, { onResponse: this.handleResponse })
    else updateCard(card, { onResponse: this.handleResponse })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit()
  }

  submit = e => {
    this.props.submit(this.formId)
    e.preventDefault()
  }

  handleMakeClassToServiceMapping = () => ({})

  render() {
    return (
      <form onSubmit={this.submit}>
        <CardForm
          form={this.formId}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          makeClassToServiceMapping={this.handleMakeClassToServiceMapping}
          enableReinitialize
        />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { card } }) => ({
    card: card ? card.single : { firstName: '', lastName: '' },
    errors: card ? card.singleErrors : null,
  }),
  { createCard, updateCard, fetchCard, clearCard, submit, stopSubmit },
)(CardFormPage)
