import React from 'react'
import Icon from 'react-icons-kit';
import { check, close } from 'react-icons-kit/fa'

const StepperForReservation = ({ instance }) => {
    if(!instance)
    return undefined

    return (<div className="stepwizard-container">
        <div className="stepwizard">
            <div className="stepwizard-row">

                {instance && <StepTentativeRender />}

                {instance && <StepComfirmedRender status={instance.status} />}

                {instance && <StepAssignRender jobAssignStatus={instance.jobAssignStatus} />}

                {instance && <StepServiceRender status={instance.status} />}

            </div>
        </div>
    </div>)

}

const StepAssignRender = ({ jobAssignStatus }) => {

    if (jobAssignStatus !== 'inComplete')
        return (
            <div className="stepwizard-step">
                <p><small><b>ASSIGNED</b></small></p>
                <a className="btn btn-primary btn-circle " data-toggle="tab"><Icon icon={check} /></a>
            </div>
        )
    else
        return (
            <div className="stepwizard-step">
                <p><small>ASSIGNED</small></p>
                <a className="btn btn-default btn-circle" disabled="disabled" data-toggle="tab"><Icon icon={close} /></a>
            </div>)
}

const StepComfirmedRender = ({ status }) => {

    if (status === 'tentative')
        return (
            <div className="stepwizard-step">
                <p><small>CONFIRMED</small></p>
                <a className="btn btn-default btn-circle " disabled="disabled" data-toggle="tab"><Icon icon={close} /></a>
            </div>
        )
    else
        return (
            <div className="stepwizard-step">
                <p><small><b>CONFIRMED</b></small></p>
                <a className="btn btn-primary btn-circle" data-toggle="tab"><Icon icon={check} /></a>
            </div>)

}

const StepTentativeRender = () => {

    return (
        <div className="stepwizard-step">
            <p><small><b>TENTATIVE</b></small></p>
            <a className="btn btn-primary btn-circle" data-toggle="tab"><Icon icon={check} /></a>
        </div>)

}

const StepServiceRender = ({ status }) => {
    if (status === 'confirmed' || status === 'tentative')
        return (
            <div className="stepwizard-step">
                <p><small>COMPLETED</small></p>
                <a className="btn btn-default btn-circle " disabled="disabled" data-toggle="tab"><Icon icon={close} /></a>
            </div>
        )
    else
        return (
            <div className="stepwizard-step">
                <p><small><b>{status && status.toUpperCase()}</b></small></p>
                <a className="btn btn-primary btn-circle" href="#step-3" data-toggle="tab"><Icon icon={check} /></a>
            </div>)

}

export default StepperForReservation