import React from 'react'
import SteppedPricingProductTableDetailStepItem from './SteppedPricingProductTableDetailStepItem'
import { Translate } from 'react-redux-i18n'
import styledComponents from 'styled-components'
import SteppedPricingProductControl from './SteppedPricingProductControl'
import { DetailedText } from '../../commons/Text'

const SteppedPricingProductTableDetail = ({
  info,
  pricingProduct,
  onChange,
  onBlur,
  formKeyPath,
  formSyncErrors,
}) => (
  <div>
    {info.steps.map((step, index) => (
      <SteppedPricingProductTableDetailStepItem
        key={step._id || index}
        step={step}
        seq={index + 1}
      />
    ))}
    <ButtonRow>
      <DetailedText>
        <Translate value="pricingContractPage.steppedQuotaResetMessage" />
        <b>{info.resetCountingInterval}</b>
      </DetailedText>
      <SteppedPricingProductControl
        info={info}
        pricingProduct={pricingProduct}
        onChange={onChange}
        onBlur={onBlur}
        formKeyPath={formKeyPath}
        formSyncErrors={formSyncErrors}
      />
    </ButtonRow>
  </div>
)

export default SteppedPricingProductTableDetail

const ButtonRow = styledComponents.div`
  margin-top: 8px;
`
