import React from 'react'
import PricingContractProductPriceForm from './PricingContractProductPriceForm'
import { connect } from 'react-redux'
import { isNumber } from 'lodash'
import {
  fetchPricingContract,
  createPricingContract,
  updatePricingContract,
  clearPricingContract,
} from '../../actions/remote_api'
import {
  submit,
  stopSubmit,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form'
import { pricingProductType } from '../../services/pricingProduct'
import { createFetchId, getFetchPricingContract } from './index'

const formId = 'pricingContract'

class PricingContractProductPricePage extends React.Component {
  state = {
    formData: { isTemplate: true, contractorType: this.props.contractorType },
  }

  componentDidMount() {
    const { fetchPricingContract, id, clearPricingContract } = this.props

    clearPricingContract('single', {
      fetchId: createFetchId(this.props.contractorType),
    })
    if (id !== undefined) {
      fetchPricingContract(id, {
        fetchId: createFetchId(this.props.contractorType),
      })
    }
  }

  componentWillUnmount() {
    this.props.clearPricingContract('single', {
      fetchId: createFetchId(this.props.contractorType),
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pricingContract) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.pricingContract },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }
  }

  handleSubmit = formData => {
    const { createPricingContract, updatePricingContract, id } = this.props
    const pricingContract = formData

    const isInvalid = pricingContract.prices.some(
      price =>
        price.type === pricingProductType.stepped &&
        price.steppedPriceInfo.steps.some(step => !isNumber(step.price)),
    )
    if (isInvalid) return

    if (id === undefined)
      createPricingContract(pricingContract, {
        onResponse: this.handleResponse,
      })
    else
      updatePricingContract(pricingContract, {
        onResponse: this.handleResponse,
      })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit(this.props.contractorType)
  }

  submit = e => {
    this.props.submit(formId)
    e.preventDefault()
  }

  render() {
    return (
      <form onSubmit={this.submit}>
        <PricingContractProductPriceForm
          form={formId}
          initialValues={this.state.formData}
          formValues={this.props.formValues}
          formSyncErrors={this.props.formSyncErrors}
          onSubmit={this.handleSubmit}
          enableReinitialize
        />
      </form>
    )
  }
}

export default connect(
  (state, props) => {
    const pricingContract = getFetchPricingContract(state, props.contractorType)
    return {
      pricingContract: pricingContract ? pricingContract.single : undefined,
      errors: pricingContract ? pricingContract.singleErrors : null,
      formValues: getFormValues(formId)(state),
      formSyncErrors: getFormSyncErrors(formId)(state),
    }
  },
  {
    createPricingContract,
    updatePricingContract,
    fetchPricingContract,
    clearPricingContract,
    submit,
    stopSubmit,
  },
)(PricingContractProductPricePage)
