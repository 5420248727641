import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import TopupTable from './TopupTable'
import TopupFormPage from './TopupFormPage'
import { findAccessLevel } from '../../modules/accessRoute'

export class TopupPage extends React.Component {
  handleSubmit = () => {
    this.props.history.push(`/topup`)
  }

  table = () => <TopupTable path="/topup" />

  formPage = ({
    match: {
      params: { id },
    },
  }) => <TopupFormPage id={id} onSubmit={this.handleSubmit} />

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/topup"
          component={this.table}
          ifAnyGranted={findAccessLevel('/topup')}
        />

        <PrivateRoute
          path="/topup/new"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/topup')}
        />

        <PrivateRoute
          path="/topup/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/topup')}
        />
      </div>
    )
  }
}
export default withRouter(TopupPage)
