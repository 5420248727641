import React from 'react'
import {
  Button,
} from 'react-bootstrap'
import styled from 'styled-components'
import { Icon } from 'react-icons-kit'

const ButtonWithIconLayout = styled.div`
  display: flex;
`

export const ButtonIcon = (props) => (
  <Button {...props}>
    <ButtonWithIconLayout>
      {props.children}
    </ButtonWithIconLayout>
  </Button>
)

export const ButtonIconIcon = styled(
  ({ isSingle, ...props}) => <Icon {...props} />
).attrs({ size: 20 })`
  ${(props) => !props.isSingle ? 'margin-right: 5px;' : null}
`