import React from 'react'
import { ButtonGroup, ButtonToolbar, Button, Glyphicon } from 'react-bootstrap'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { I18n } from 'react-redux-i18n'
import { makeDetailLink } from '../table'
import { isEmpty, omitBy, isEqual, values } from 'lodash'
import { withFilter } from '../filter/withFilter'
import { InsertButton } from '../commons/InsertButton'
import LinkButton from '../commons/LinkButton'
import numernal from 'numeral'

const pagingFormat = value => numernal(value).format('0,0')

const withTableConfigs = filterName => (
  Component,
  TableFilterPanel,
  tableKey = '_id',
) =>
  withDataManager(filterName)(
    class TableConfig extends React.Component {
      state = {
        isShowFilterPanel: false,
      }

      toggleFilterPanel = () => {
        this.setState({
          ...this.state,
          isShowFilterPanel: !this.state.isShowFilterPanel,
        })
      }

      isFiltering = () =>
        !isEmpty(omitBy(this.props.filter, value => isEmpty(value)))

      render() {
        const {
          instances,
          path,
          totalCount,
          currentPage,
          onPageChange,
          sizePerPage,
          customDefaultSizePerPage,
          sortName,
          sortOrder,
          onSortChange,
          onOpenDetailPopup,
          onConfirmDelete,
          customCreateButtonGroup,
          ...props
        } = this.props

        const confirmDeleteHandler = makeConfirmDeleteHandler(
          tableKey,
          onConfirmDelete,
          instances,
        )
        const defaultOptions = {
          btnGroup: customCreateButtonGroup
            ? customCreateButtonGroup(props)
            : createButtonGroup(path),
          deleteText: I18n.t('action.delete').toUpperCase(),
          handleConfirmDeleteRow: (next, codes) => confirmDeleteHandler(codes),
          page: currentPage,
          sizePerPage: sizePerPage,
          sizePerPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '50', value: 50 },
          ],
          paginationShowsTotal: (start, to, totalCount) => (
            <div>
              <h4>Total: {pagingFormat(totalCount)}</h4>
              <h5>
                Showing: {pagingFormat(start)} - {pagingFormat(to)}
              </h5>
            </div>
          ),
          sortName,
          sortOrder,
          onPageChange,
          onSortChange,
        }
        const defaultSettings = {
          data: instances,
          selectRow: { mode: 'checkbox' },
          fetchInfo: { dataTotalSize: totalCount || 0 },
          insertRow: true,
          deleteRow: true,
          remote: true,
          pagination: true,
          keyField: '_id',
        }
        return (
          <Component
            {...props}
            path={path}
            instances={instances}
            onOpenDetailPopup={onOpenDetailPopup}
            onConfirmDelete={onConfirmDelete}
            detailLink={
              onOpenDetailPopup ? makeDetailLink(onOpenDetailPopup) : undefined
            }
            editButton={createEditButton(path)}
            defaultOptions={defaultOptions}
            confirmDeleteHandler={confirmDeleteHandler}
            defaultSettings={defaultSettings}
            renderToolbar={renderToolbar(
              this.state.isShowFilterPanel,
              this.toggleFilterPanel,
              this.isFiltering(),
            )}
            isShowFilterPanel={this.state.isShowFilterPanel}
            onToggleFilterPanel={this.toggleFilterPanel}
            renderFilterPanel={renderFilterPanel(TableFilterPanel, {
              ...props,
              isShow: this.state.isShowFilterPanel,
              filter: this.props.filter,
              onClearFilter: this.props.onClearFilter,
              onFilterChange: this.props.onFilterChange,
            })}
          />
        )
      }
    },
  )

const makeConfirmDeleteHandler = (
  tableKey,
  onConfirmDelete,
  instances,
) => keyList =>
  onConfirmDelete(instances.filter(i => keyList.some(k => k === i[tableKey])))

const createButtonGroup = path => props => {
  return (
    <ButtonGroup>
      <InsertButton to={`${path}/new`}>
        <i className="glyphicon glyphicon-plus" />{' '}
        {I18n.t('action.add').toUpperCase()}
      </InsertButton>
      {props.deleteBtn}
    </ButtonGroup>
  )
}

const createEditButton = path => (cell, row, options = {}) => (
  <EditButton
    path={path}
    overridenPath={options.path}
    id={row.id}
    disabled={options.disabled}
  />
)

const EditButton = ({ path, id, overridenPath, disabled }) => (
  <LinkButton
    className="btn btn-info"
    to={overridenPath || `${path}/edit/${id}`}
    disabled={disabled}
  >
    <i className="glyphicon glyphicon-edit" /> Edit
  </LinkButton>
)

const renderToolbar = (isShowFilterPanel, onToggleFilterPanel, isFiltering) => {
  return (
    <ButtonToolbar className="pull-right">
      <ButtonGroup>
        <Button
          onClick={onToggleFilterPanel}
          className={isFiltering && 'btn-info'}
        >
          <Glyphicon glyph="filter" /> {I18n.t('action.filter')}{' '}
          <Glyphicon
            glyph={isShowFilterPanel ? 'triangle-bottom' : 'triangle-top'}
          />
        </Button>
      </ButtonGroup>
    </ButtonToolbar>
  )
}

const renderFilterPanel = (TableFilterPanel, props) => {
  return <TableFilterPanel {...props} />
}

const withDataManager = filterName => component =>
  withFilter(filterName)(props => (
    <TableDataManagerContainer {...props} paginatedComponent={component} />
  ))

const isEmptyObject = obj => !values(obj).some(props => props !== undefined)

class TableDataManagerContainer extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.isRefetchItems) {
      this.handleDataChange(nextProps.filter)
      nextProps.onRefetchSuccess()
    }

    if (!isEqual(nextProps.filter, this.props.filter)) {
      this.handleDataChange(nextProps.filter)
    }

    if (isEmptyObject(nextProps.filter)) {
      this.initialFilterProps()
    }
  }

  componentDidMount() {
    if (isEmpty(this.props.filter)) this.initialFilterProps()
  }

  initialFilterProps = () => {
    this.props.updateFilterProps({
      currentPage: this.props.startPage || 1,
      sizePerPage:
        this.props.sizePerPage || this.props.customDefaultSizePerPage
          ? this.props.customDefaultSizePerPage
          : 5,
      sort: this.props.defaultSortName,
      order: this.props.defaultSortOrder,
    })
  }

  handlePageChange = (currentPage, sizePerPage) => {
    this.props.updateFilterProps({
      currentPage,
      sizePerPage,
    })
  }

  handleSortChange = (sort, order) => {
    this.props.updateFilterProps({
      sort,
      order,
    })
  }

  handleFilterChange = (key, value) => {
    this.props.updateFilterQuery(key, value)
  }

  handleClearFilter = () => {
    this.props.clearFilter()
  }

  handleDataChange = filter => {
    const { onDataChange } = this.props
    if (onDataChange) onDataChange(filter)
  }

  refetch = () => {
    this.handleDataChange(this.props.filter)
  }

  render() {
    const { paginatedComponent: Component, ...props } = this.props

    return (
      <Component
        {...props}
        totalCount={this.props.totalCount}
        currentPage={this.props.filter.currentPage}
        sizePerPage={this.props.filter.sizePerPage}
        onPageChange={this.handlePageChange}
        sortName={this.props.filter.sort}
        sortOrder={this.props.filter.order}
        onSortChange={this.handleSortChange}
        filter={this.props.filter.query || {}}
        onClearFilter={this.handleClearFilter}
        onFilterChange={this.handleFilterChange}
        refetch={this.refetch}
      />
    )
  }
}

export default withTableConfigs
