import constants from '../constants'
import immutable from 'object-path-immutable'

export default (state = {}, action) => {
  switch (action.type) {
    case constants.UPDATE_QUERY_FILTER:
    case constants.INIT_FILTER:
    case constants.UPDATE_FILTER_PROPS:
    case constants.CLEAR_FILTER:
      return {
        ...state,
        [action.name]: filterByName(state[action.name], action),
      }
    default:
      return state
  }
}

const filterByName = (state = {}, action) => {
  switch (action.type) {
    case constants.UPDATE_QUERY_FILTER:
      return {
        ...state,
        query: immutable.set(state.query || {}, action.keyPath, action.value),
        currentPage: 1,
      }
    case constants.INIT_FILTER:
      return {
        currentPage: 1,
        ...action.defaults,
      }
    case constants.UPDATE_FILTER_PROPS:
      return {
        ...state,
        ...action.props,
      }
    case constants.CLEAR_FILTER:
      return {
        ...state,
        query: {},
      }
    default:
      return state
  }
}
