import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import AirlineTable from './AirlineTable'
import AirlineFormPage from './AirlineFormPage'
import { findAccessLevel } from '../../modules/accessRoute'

export class AirlinePage extends React.Component {
  handleSubmit = () => {
    this.props.history.push(`/airline`)
  }

  table = () => <AirlineTable path="/airline" />

  formPage = ({
    match: {
      params: { id },
    },
  }) => <AirlineFormPage id={id} onSubmit={this.handleSubmit} />

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/airline"
          component={this.table}
          ifAnyGranted={findAccessLevel('/airline')}
        />

        <PrivateRoute
          path="/airline/new"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/airline')}
        />

        <PrivateRoute
          path="/airline/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/airline')}
        />
      </div>
    )
  }
}
export default withRouter(AirlinePage)
