import React from 'react'
import { Panel, Form, Row, Col, FormControl, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

export default ({ isShow, filter, onFilterChange, onClearFilter }) => {

  const fieldsLabel = I18n.t('productPage.fields')
  return (
    <Panel collapsible expanded={isShow}>
      <Form horizontal>
        <Row>
          <Col md={4}>
            <FormControl
              type="text"
              value={filter.name || ''}
              onChange={e => onFilterChange('name', e.currentTarget.value)}
              placeholder={fieldsLabel.name} />
          </Col>
          <Col md={8}>
            <Button className="pull-right" onClick={onClearFilter}>
              <span className="glyphicon glyphicon-trash"></span>  Clear Filter
            </Button>
          </Col>
        </Row>
      </Form>
    </Panel>
  )
}