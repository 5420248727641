import React from 'react'
import { Col, Panel } from 'react-bootstrap'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export default ({ reports, onReportMenuClick }) => (
  <div>
    <h1 className="page-header">Report</h1>
    <hr />
    {/* <Link to="report/reservationReport"> */}
    {/*   <Col md={3}> */}
    {/*     <ReportCard> */}
    {/*       <Header>Reservation Report</Header> */}
    {/*     </ReportCard> */}
    {/*   </Col> */}
    {/* </Link> */}
    {/**/}
    {/* <Link to="report/airlineReport"> */}
    {/*   <Col md={3}> */}
    {/*     <ReportCard> */}
    {/*       <Header>Airline Report</Header> */}
    {/*     </ReportCard> */}
    {/*   </Col> */}
    {/* </Link> */}
    {/**/}
    {/* <Link to="report/cardReport"> */}
    {/*   <Col md={3}> */}
    {/*     <ReportCard> */}
    {/*       <Header>Card Report</Header> */}
    {/*     </ReportCard> */}
    {/*   </Col> */}
    {/* </Link> */}

    <Link to="report/beta/reservationReport">
      <Col md={3}>
        <ReportCard>
          <Header>Reservation Report (Beta)</Header>
        </ReportCard>
      </Col>
    </Link>

    <Link to="report/beta/airlineReport">
      <Col md={3}>
        <ReportCard>
          <Header>Airline Report (Beta)</Header>
        </ReportCard>
      </Col>
    </Link>
    
    <Link to="report/beta/cardReport">
      <Col md={3}>
        <ReportCard>
          <Header>Card Report (Beta)</Header>
        </ReportCard>
      </Col>
    </Link>

    <Col md={3}>
      <ReportCard>Coming Soon</ReportCard>
    </Col>
    <Col md={3}>
      <ReportCard>Coming Soon</ReportCard>
    </Col>
    <Col md={3}>
      <ReportCard>Coming Soon</ReportCard>
    </Col>
    <Col md={3}>
      <ReportCard>Coming Soon</ReportCard>
    </Col>
    <Col md={3}>
      <ReportCard>Coming Soon</ReportCard>
    </Col>
  </div>
)

const ReportCard = styled(Panel) `
  min-height: 150px;
  cursor: pointer;
`

const Header = styled.span`
  font-size: 18px;
  font-weight: bold;
`
