import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Row, Col, Button } from 'react-bootstrap'
import { ListField } from '../form/index'
import AirlinePrivilegeClassSearchSelectField from '../service/AirlinePrivilegeClassSearchSelectField'
import ServiceMappingSearchSelectField from '../service/ServiceMappingSearchSelectField'

export default props => (
  <ListField {...props} inputFieldItem={AirlineClassToServiceMappingForm} />
)

const AirlineClassToServiceMappingForm = ({ path, onRemove, ...rest }) => {
  const label = I18n.t('classToServiceMappingField')
  return (
    <Row>
      <Col sm={3}>
        <AirlinePrivilegeClassSearchSelectField
          name={`${path}.privilegeClass`}
          label={label.privilegeClass}
          selectValue={privilegeClass => privilegeClass.name}
        />
      </Col>
      <Col sm={7}>
        <ServiceMappingSearchSelectField
          name={`${path}.meta.serviceMappingId`}
          label={label.serviceMappingId}
          selectValue={serviceMapping => serviceMapping._id}
        />
      </Col>
      <Col sm={2} style={{ marginTop: 24 }}>
        <Button onClick={onRemove} bsStyle="danger" className="pull-right">
          {I18n.t('action.remove').toUpperCase()}
        </Button>
      </Col>
    </Row>
  )
}
