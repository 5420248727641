import React from 'react'
import { Button } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import _ from 'lodash'
import traverse from 'traverse'
import SteppedPricingProductModal from './SteppedPricingProductModal'

const setIsShow = isModalShow => state => ({ isModalShow })

class SteppedPricingProductControl extends React.Component {
  state = { isModalShow: false }

  handleShow = () => this.setState(setIsShow(true))

  handleClose = () => this.setState(setIsShow(false))

  handleDone = () => {
    const { formSyncErrors, formKeyPath } = this.props
    const steppedPriceInfoErrors = _.get(formSyncErrors, formKeyPath)
    const isValid = traverse(steppedPriceInfoErrors).reduce(function(
      acc,
      error,
    ) {
      if (this.isLeaf && error) acc &= false
      return acc
    },
    true)

    if (isValid) this.setState(setIsShow(false))
  }

  render() {
    const { info, pricingProduct, onChange, onBlur, formKeyPath } = this.props
    return (
      <span>
        <Button className="pull-right" onClick={this.handleShow}>
          <Translate value="action.edit" />
        </Button>
        <SteppedPricingProductModal
          info={info}
          pricingProduct={pricingProduct}
          isShow={this.state.isModalShow}
          handleClose={this.handleClose}
          handleDone={this.handleDone}
          onChange={onChange}
          onBlur={onBlur}
          formKeyPath={formKeyPath}
        />
      </span>
    )
  }
}

export default SteppedPricingProductControl
