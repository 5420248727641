import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isFunction } from 'lodash'
import {
  updateFilterQuery,
  updateFilterProps,
  clearFilter,
} from '../../actions/filter'

const getFilterName = (filterName, props) =>
  isFunction(filterName) ? filterName(props) : filterName

export const withFilter = filterName => Component =>
  compose(
    connect(
      ({ filter }, ownProps) => ({
        filter: filter[getFilterName(filterName, ownProps)] || {},
      }),
      (dispatch, ownProps) => ({
        updateFilterQuery: (keyPath, value) =>
          dispatch(
            updateFilterQuery(
              getFilterName(filterName, ownProps),
              keyPath,
              value,
            ),
          ),

        updateFilterProps: props =>
          dispatch(
            updateFilterProps(getFilterName(filterName, ownProps), props),
          ),

        clearFilter: () =>
          dispatch(clearFilter(getFilterName(filterName, ownProps))),
      }),
    ),
    withFilterEnhancer,
  )(Component)

const withFilterEnhancer = Component =>
  class FilterEnhancer extends React.Component {
    render() {
      return <Component {...this.props} />
    }
  }
