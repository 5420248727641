import React from 'react'
import { FormControl } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { resetCountingInterval } from '../../../services/pricingProduct'

class SteppedPricingProductResetCountingIntervalSelect extends React.Component {
  renderOptions = () => {
    return Object.values(resetCountingInterval).map(type => (
      <option key={type} value={type}>
        {I18n.t(
          `pricingContractPage.fields.pricingProduct.resetCountingIntervalOptions.${type}`,
        )}
      </option>
    ))
  }

  render() {
    const { value, onChange } = this.props
    return (
      <FormControl componentClass="select" value={value} onChange={onChange}>
        {this.renderOptions()}
      </FormControl>
    )
  }
}

export default SteppedPricingProductResetCountingIntervalSelect
