import React from 'react'
import { reduxForm } from 'redux-form'
import { Toolbar, DateTimeField } from '../form'
import { required, validateWith, date } from '../form/validator'
import { Button, Col } from 'react-bootstrap'

import { I18n } from 'react-redux-i18n'

const validate = values => {
  const errors = {}
  errors.startDate = validateWith(values.startDate, [required, date])
  errors.endDate = validateWith(values.endDate, [required, date])
  return errors
}

const InvoiceIntervalForm = ({ initialValues }) => {
  const { form, fields } = I18n.t('invoiceIntervalPage')
  const { display: dateDisplay } = I18n.t('date')
  return (
    <div className="container">
      <Toolbar title={initialValues._id ? form.update : form.new}>
        <Button className="btn-primary" type="submit">
          Submit
        </Button>
      </Toolbar>
      <Col sm={4}>
        <DateTimeField
          name="startDate"
          label={fields.startDate}
          dateFormat={dateDisplay}
          timeFormat={false}
          addStar={true}
        />
      </Col>
      <Col sm={4}>
        <DateTimeField
          name="endDate"
          label={fields.endDate}
          dateFormat={dateDisplay}
          timeFormat={false}
          addStar={true}
        />
      </Col>
    </div>
  )
}

export default reduxForm({
  validate,
})(InvoiceIntervalForm)
