import React from 'react'
import { reduxForm } from 'redux-form'
import { Button, Row, Col } from 'react-bootstrap'
import _ from 'lodash'
import { TextField, SwitchField, ListField, Toolbar } from '../form'
import { required, validateWith } from '../form/validator'

import { I18n } from 'react-redux-i18n'
import ServiceSearchSelectField from '../service/ServiceSearchSelectField'

const validate = values => {
  const errors = {}
  errors.name = validateWith(values.name, [required])
  errors.services = validateServices(values.services)
  errors.walkinPrice = {
    price: validateWith(_.get(values, 'walkinPrice.price'), [required]),
    addendumPrice: validateWith(_.get(values, 'walkinPrice.addendumPrice'), [
      required,
    ]),
  }
  return errors
}

const validateServices = services => {
  if (services) {
    const serviceSet = new Set(
      services.map(s => (s ? s.name + '_' + s.class : undefined)),
    )
    if (serviceSet.size !== services.length && services.every(s => s)) {
      return services.map(s =>
        I18n.t('productPage.form.servicesDuplicationError'),
      )
    }
    return services.map(s => validateWith(s, [required]))
  }
  return undefined
}

const ProductForm = ({ initialValues, makeNewService, ...rest }) => {
  const { form, fields } = I18n.t('productPage')
  return (
    <div className="container">
      <Toolbar title={initialValues._id ? form.update : form.new}>
        <Button className="btn-primary" type="submit">
          Submit
        </Button>
      </Toolbar>
      <Col sm={6}>
        <TextField name="name" label={fields.name} />
      </Col>
      <Col sm={3}>
        <TextField
          name="walkinPrice.price"
          label={fields.price}
          className="text-right"
          type="number"
        />
      </Col>
      <Col sm={3}>
        <TextField
          name="walkinPrice.addendumPrice"
          label={fields.addendumPrice}
          className="text-right"
          type="number"
        />
      </Col>
      <Col sm={12}>
        <ListField
          name="services"
          label={fields.services}
          inputFieldItem={ServiceField}
          itemLabels={fields}
          makeNewItem={makeNewService}
        />
      </Col>
      <Col sm={4}>
        <SwitchField
          name="isActive"
          label={fields.isActive}
          initialValue={true}
          isValidate={false}
        />
      </Col>
    </div>
  )
}

const ServiceField = ({ path, itemLabels, onRemove, index, ...rest }) => (
  <Row>
    <Col sm={6}>
      <ServiceSearchSelectField label={`#${index + 1}`} name={path} />
    </Col>
    <Col sm={6} style={{ marginTop: 24 }}>
      <Button onClick={onRemove} bsStyle="danger" className="pull-right">
        REMOVE
      </Button>
    </Col>
  </Row>
)

export default reduxForm({
  validate,
})(ProductForm)
