import React from 'react'
import { Table, FormControl, InputGroup } from 'react-bootstrap'
import styledComponents from 'styled-components'
import { I18n } from 'react-redux-i18n'
import Icon from 'react-icons-kit'
import { search } from 'react-icons-kit/ionicons'

const ProductCheckboxSelectTable = ({
  children,
  searchText,
  onSearchTextChange,
  isLoading = true,
  isEmpty = false,
}) => (
  <div>
    <InputGroup>
      <InputGroup.Addon>
        <Icon icon={search} />
      </InputGroup.Addon>
      <FormControl
        value={searchText}
        onChange={onSearchTextChange}
        placeholder={I18n.t('search')}
      />
    </InputGroup>
    <Scrollable>
      <Table>
        <thead>
          <tr>
            <th width="48" />
            <th>Product</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>

      {isLoading && <h4>LOADING</h4>}
      {isEmpty && <h4>NO DATA</h4>}
    </Scrollable>
  </div>
)

const Scrollable = styledComponents.div`
  height: calc(100vh - 225px);
  overflow: auto;
`

export default ProductCheckboxSelectTable

export const ProductCheckboxSelectTableItem = ({
  input: { name, checked, onChange },
  productName,
  disabled,
}) => (
  <tr>
    <td className="text-center">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </td>
    <td>{productName}</td>
  </tr>
)
