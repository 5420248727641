import React from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import styledComponents from 'styled-components'
import _ from 'lodash'

class PricingContractSideMenu extends React.Component {
  goTo = _.memoize(url => () => {
    this.props.history.push(url)
  })

  menus = [
    { title: 'Agent', to: '/pricingContract/agent' },
    { title: 'Airline', to: '/pricingContract/airline' },
    { title: 'Card', to: '/pricingContract/card' },
    { title: 'Template', to: '/pricingContract/template' },
  ]

  isActive = url => this.props.location.pathname === url

  render() {
    return (
      <SideMenuLayout>
        <ListGroup>
          {this.menus &&
            this.menus.map((menu, i) => (
              <ListGroupItem
                key={menu.to}
                active={this.isActive(menu.to)}
                disabled={menu.disabled}
                onClick={this.goTo(menu.to)}
              >
                <strong>{menu.title}</strong>
              </ListGroupItem>
            ))}
        </ListGroup>
      </SideMenuLayout>
    )
  }
}

export default withRouter(PricingContractSideMenu)

const SideMenuLayout = styledComponents.div`
  flex: 1;
  min-width: 200px;
  margin-right: 16px;
`
