import { removeUndefinedFromObject } from "../utils/url";

export const createURL = (url, query) => {
  if (query) {
    const { page, limit, sort, order, ...rest } = query
    const params = new URLSearchParams(
      removeUndefinedFromObject({
        ...rest,
        _page: page,
        _limit: limit,
        _sort: sort,
        _order: order,
      })
    );
    return `${url}?${params.toString()}`
  }
  return url
}

export const createCRUDActions = (defaultFetchId, url) => ({
  list: (query, options = {}) => {
    const _fetchId = options.fetchId ? options.fetchId : defaultFetchId
    return {
      type: `FETCH_${_fetchId.toUpperCase()}_LIST`,
      meta: { fetch_id: _fetchId },
      $payload: {
        url: createURL(url, query),
      },
    }
  },

  get: (id, { resultTransform, fetchId } = {}) => ({
    type: `FETCH_${defaultFetchId.toUpperCase()}_SINGLE`,
    meta: {
      fetch_id: fetchId || defaultFetchId,
      type: 'single',
      resultTransform,
    },
    $payload: {
      url: `${url}/${id}`,
    },
  }),

  create: (resource, { onResponse, resultTransform, fetchId } = {}) => ({
    type: `CREATE_${defaultFetchId.toUpperCase()}`,
    meta: {
      fetch_id: fetchId || defaultFetchId,
      type: 'create',
      resultTransform,
    },
    $payload: {
      url: url,
      options: {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(resource),
      },
      onResponse,
    },
  }),

  update: (resource, { onResponse, resultTransform, fetchId } = {}) => ({
    type: `UPDATE_${defaultFetchId.toUpperCase()}`,
    meta: {
      fetch_id: fetchId || defaultFetchId,
      type: 'update',
      resultTransform,
    },
    $payload: {
      url: `${url}/${resource._id}`,
      options: {
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(resource),
      },
      onResponse,
    },
  }),

  delete: (_id, { onResponse, resultTransform, fetchId } = {}) => ({
    type: `DELETE_${defaultFetchId.toUpperCase()}`,
    meta: {
      fetch_id: fetchId || defaultFetchId,
      type: 'delete',
      _id,
      resultTransform,
    },
    $payload: {
      url: `${url}/${_id}`,
      options: {
        headers: { 'Content-Type': 'application/json' },
        method: 'DELETE',
      },
      responseType: 'text',
      onResponse,
    },
  }),

  clear: (type = 'single', options = {}) => ({
    type: 'CLEAR_FETCH_DATA',
    meta: { fetch_id: options.fetchId || defaultFetchId, type },
  }),
})
