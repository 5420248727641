import types from '../constants'

export const showAlert = (info, meta) => ({
  type: types.SHOW_ALERT,
  info,
  meta
})

export const hideAlert = () => ({
  type: types.HIDE_ALERT
})

export const triggerAction = name => ({
  type: types.TRIGGER_ALERT_ACTION,
  name
})

export const actionEnd = () => ({
  type: types.ALERT_ACTION_END,
})