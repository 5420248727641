import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { fetchTopup } from '../../actions/remote_api'
import { withDetailPopup } from '../detailPopup'
import { dateFormat, dateTimeFormat } from '../table/index'
import { priceFormat } from '../../services/currency'

const TopupDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('topupPage')
  return (
    <form className="form-horizontal">
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.agent}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance && instance.agent.name}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.issuedDate}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {dateFormat(instance.issuedDate)}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.price}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance.price ? priceFormat(instance.price) : '-'}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.isPaid}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {I18n.t(`topupPage.fields.isPaidValue.${instance.isPaid}`)}
          </p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.createdBy}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance.createdBy}</p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">
          {fields.createdDateTime}
        </label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {dateTimeFormat(instance.createdDateTime)}
          </p>
        </div>
      </div>
    </form>
  )
}

let TopupDetailPopupContainer = withDetailPopup(TopupDetailPopup, () => ({
  header: I18n.t('topupPage.detail.header'),
}))

export default connect(
  state => ({
    instance: get(state, 'fetch.topup.single'),
  }),
  { fetchInstance: fetchTopup },
)(TopupDetailPopupContainer)
