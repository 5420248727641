import React from 'react'
import styled from 'styled-components'
import Icon from 'react-icons-kit'
import {
  iosRefresh,
  iosCheckmark,
  iosClose,
  iosHelp,
} from 'react-icons-kit/ionicons'

const iconForStatus = status => {
  switch (status) {
    case 'loading':
      return iosRefresh
    case 'valid':
      return iosCheckmark
    case 'invalid':
      return iosClose
    default:
      return iosHelp
  }
}

const colorForStatus = status => {
  switch (status) {
    case 'loading':
      return '#7f8c8d'
    case 'valid':
      return '#2ecc71'
    case 'invalid':
      return '#c0392b'
    default:
      return 'unset'
  }
}

const StatusColor = styled.div`
  color: ${({ status }) => colorForStatus(status)};
`

const ReservableStatusIcon = ({ status: { name } }) => (
  <StatusColor status={name}>
    <Icon size={36} icon={iconForStatus(name)} />
  </StatusColor>
)

export default ReservableStatusIcon
