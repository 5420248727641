import React from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import Icon from 'react-icons-kit'
import { arrowReturnLeft } from 'react-icons-kit/ionicons'
import {
  userTimes,
  refresh,
  checkSquare,
  checkCircle,
  eyeSlash,
  trash,
  ban,
  money,
} from 'react-icons-kit/fa'

import { RightBarButtonHolder } from '../../detailPopup'
import { BtnInBar, IconInBtnInBar } from '../../detailPopup/DetailPopup'

export default ({
  instance,
  onReservationConfirm,
  onReservationReconfirm,
  onReservationRevertConfirm,
  onReservationComplete,
  onReservationNoShow,
  onReservationMissedCase,
  onReservationRevertFinalStatus,
  onReservationCancel,
  onReservationRefund,
  onReservationDelete,
  isShows,
}) => {
  const otherTitle = () => {
    return <span className="glyphicon glyphicon-option-vertical" />
  }

  const isOtherActionsShow =
    isShows.revertConfirm ||
    isShows.cancel ||
    isShows.refund ||
    isShows.delete ||
    isShows.revertFinalStatus

  if (!instance) return null

  return (
    <RightBarButtonHolder>
      {isShows.confirm && (
        <BtnInBar bsStyle="success" onClick={onReservationConfirm}>
          <IconInBtnInBar icon={checkSquare} size={16} />{' '}
          {I18n.t('action.confirm')}
        </BtnInBar>
      )}
      {isShows.reconfirm && (
        <BtnInBar onClick={onReservationReconfirm}>
          <IconInBtnInBar icon={refresh} size={16} />{' '}
          {I18n.t('action.reconfirm')}
        </BtnInBar>
      )}
      {isShows.completed && (
        <BtnInBar bsStyle="success" onClick={onReservationComplete}>
          <IconInBtnInBar icon={checkCircle} size={16} />{' '}
          {I18n.t('action.completed')}
        </BtnInBar>
      )}
      {isShows.noShow && (
        <BtnInBar bsStyle="warning" onClick={onReservationNoShow}>
          <IconInBtnInBar icon={eyeSlash} size={16} /> {I18n.t('action.noShow')}
        </BtnInBar>
      )}
      {isShows.missedCase && (
        <BtnInBar bsStyle="warning" onClick={onReservationMissedCase}>
          <IconInBtnInBar icon={userTimes} size={16} />{' '}
          {I18n.t('action.missedCase')}
        </BtnInBar>
      )}

      {isOtherActionsShow && (
        <DropdownButton
          bsStyle="default"
          noCaret
          title={otherTitle()}
          id="dropdown-no-caret"
        >
          {isShows.revertConfirm && (
            <MenuItem onClick={onReservationRevertConfirm}>
              <Icon icon={arrowReturnLeft} size={14} />{' '}
              {I18n.t('action.revertConfirm')}
            </MenuItem>
          )}
          {isShows.cancel && (
            <MenuItem bsStyle="danger" onClick={onReservationCancel}>
              <Icon icon={ban} size={14} /> {I18n.t('action.cancel')}
            </MenuItem>
          )}
          {isShows.refund && (
            <MenuItem bsStyle="info" onClick={onReservationRefund}>
              <Icon icon={money} size={14} /> {I18n.t('action.refund')}
            </MenuItem>
          )}
          {isShows.revertFinalStatus && (
            <MenuItem bsStyle="info" onClick={onReservationRevertFinalStatus}>
              <Icon icon={arrowReturnLeft} size={14} />{' '}
              {I18n.t('action.revertFinalStatus')}
            </MenuItem>
          )}
          {isShows.delete && <MenuItem divider />}
          {isShows.delete && (
            <MenuItem bsStyle="danger" onClick={onReservationDelete}>
              <Icon icon={trash} size={14} /> {I18n.t('action.delete')}
            </MenuItem>
          )}
        </DropdownButton>
      )}
    </RightBarButtonHolder>
  )
}
