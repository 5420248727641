import React from 'react'
import { connect } from 'react-redux'
import { defaultTo } from 'lodash'

import {
  fetchPrivilegeClasses,
} from '../../actions/remote_api'
import SearchSelect from '../../modules/select/SearchSelect'

class AirlinePrivilegeClassSearchSelect extends React.Component {
  render() {
    return (
      <SearchSelect
        {...this.props}
        options={this.props.options}
        createQuery={defaultTo(this.props.createQuery, createQuery)}
        createLabel={createLabel}
        defaultValueQuery={defaultValueQuery}
        selectId="privilegeClassOptions"
        preFetch
      />
    )
  }
}

const createQuery = searchText => ({
  _sort: 'name',
  _order: 'asc',
})

const defaultValueQuery = initialId => ({
  _id: initialId,
})

const createLabel = a => a.name

export default connect(null, {
  fetchOptions: fetchPrivilegeClasses,
  clearOptions: undefined,
})(AirlinePrivilegeClassSearchSelect)
