import { combineReducers } from 'redux'
import auth from './auth'
import fetch from './fetch'
import alert from './alert'
import { i18nReducer as i18n } from 'react-redux-i18n'
import { reducer as toastr } from 'react-redux-toastr'
import { reducer as form } from 'redux-form'
import filter from './filter'
import pricingContract from './pricingContract'
import invoice from './invoice'
import createReservationForm from './reservationForm'

const createReducer = ({ getServiceDateTime }) =>
  combineReducers({
    auth,
    fetch,
    i18n,
    toastr,
    form: form.plugin({
      pricingContract,
      invoice,
      reservation: createReservationForm({
        getServiceDateTime,
      }),
    }),
    alert,
    filter,
  })

export default createReducer
