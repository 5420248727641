import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { I18n } from 'react-redux-i18n'
import Icon from 'react-icons-kit'
import { check } from 'react-icons-kit/fa'
import { Link } from 'react-router-dom'

import { withTableConfigs, withDeleteModal, dateTimeFormat } from '../table'
import { withDetailPopupProvider } from '../detailPopup'
import ReservationDetailPopup from './ReservationDetailPopup'
import ReservationTableFilterPanel from './ReservationTableFilterPanel'
import Loader from '../Loader'
import TableLoading from '../table/TableLoading'

let ReservationTable = ({
  defaultOptions,
  defaultSettings,
  options,
  settings,
  i18nPageKey,
  detailLink,
  editButton,
  pageTitle,
  path,
  isJobAssignable = true,
  bothType = false,
  renderFilterPanel,
  renderToolbar,
  headerI18nKey,
  isAgentColumnShow = true,
  isLoading,
}) => {
  const header = headerI18nKey ? I18n.t(headerI18nKey) : undefined
  const { fields, table } = I18n.t(
    i18nPageKey ? i18nPageKey : 'reservationPage',
  )

  const customSettings = { ...defaultSettings, ...settings }
  const customOptions = {
    ...defaultOptions,
    ...options,
  }

  return (
    <div>
      <h1 className="page-header">{header || table.header}</h1>
      {renderFilterPanel}
      {renderToolbar}
      <Loader loading={<TableLoading />} isLoading={isLoading}>
        <BootstrapTable
          {...customSettings}
          trClassName={rowClassNameFormat}
          options={customOptions}
        >
          <TableHeaderColumn
            width="10%"
            dataField="mainPax.lastName"
            dataFormat={fullNameFormat(detailLink)}
            dataAlign="left"
            dataSort
          >
            {fields.mainPax.name}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="5%"
            dataField="confirmationNumber"
            dataAlign="center"
            dataSort
          >
            {fields.confirmationNumber}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="7%"
            dataField="product"
            dataFormat={p => (p ? p.name : undefined)}
            tdStyle={{ whiteSpace: 'normal' }}
          >
            {fields.product}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="5%"
            dataField="serviceDateTime"
            dataSort
            dataFormat={dateTimeFormat}
            tdStyle={{ whiteSpace: 'normal' }}
          >
            {fields.serviceDateTime}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="2%"
            dataField="guestPaxAdult"
            dataAlign="right"
            tdStyle={{ whiteSpace: 'normal' }}
          >
            {table.guestPaxAdult}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="2%"
            dataField="guestPaxChild"
            dataAlign="right"
            tdStyle={{ whiteSpace: 'normal' }}
          >
            {table.guestPaxChild}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="2%"
            dataField="guestPaxInfant"
            dataAlign="right"
            tdStyle={{ whiteSpace: 'normal' }}
          >
            {table.guestPaxInfant}
          </TableHeaderColumn>
          {isAgentColumnShow && (
            <TableHeaderColumn
              width="7%"
              dataField="agent"
              dataFormat={a => (a ? a.name : undefined)}
              tdStyle={{ whiteSpace: 'normal' }}
            >
              {fields.agent}
            </TableHeaderColumn>
          )}
          <TableHeaderColumn
            width="5%"
            dataField="flightNumber"
            dataSort
            dataAlign="center"
          >
            {fields.flightNumber}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="5%"
            dataField="status"
            dataSort
            dataFormat={s => s.toUpperCase()}
            dataAlign="center"
          >
            {fields.status}
          </TableHeaderColumn>
          {bothType && (
            <TableHeaderColumn
              width="4%"
              dataField="createByKiosk"
              dataFormat={renderCreateByKiosk}
              dataSort
              dataAlign="center"
            >
              {fields.createByKiosk}
            </TableHeaderColumn>
          )}
          <TableHeaderColumn
            width="5%"
            dataField="edit"
            editable={false}
            dataFormat={isJobAssignable ? createButton(path) : editButton}
            dataAlign="center"
            hiddenOnInsert
          />
        </BootstrapTable>
      </Loader>
    </div>
  )
}

const fullNameFormat = detailLink => (
  _,
  { mainPax: { fullName }, ...reservation },
) => detailLink(fullName, reservation)

const rowClassNameFormat = (row, rowIdx) => {
  // row is whole row object
  // rowIdx is index of row
  return row.flag === 'overTime' ? 'danger' : 'default'
}

const renderCreateByKiosk = c => {
  return c === true ? <Icon icon={check} /> : ''
}

const createButton = path => (cell, row) => {
  let assignBtnClass = 'btn-assigned'
  if (row.jobAssignStatus === 'noRequired') assignBtnClass = 'btn-default'
  else if (row.jobAssignStatus === 'inComplete') assignBtnClass = 'btn-warning'

  return (
    <div className="btn-group-vertical">
      <EditButton path={path} id={row.id} />
      <AssignJobButton
        path={path}
        assignBtnClass={assignBtnClass}
        id={row.id}
      />
    </div>
  )
}

const EditButton = ({ path, id }) => (
  <Link className="btn btn-info" to={`${path}/edit/${id}`}>
    <i className="glyphicon glyphicon-edit" /> Edit
  </Link>
)

const AssignJobButton = ({ path, assignBtnClass, id }) => (
  <Link className={`btn ${assignBtnClass}`} to={`${path}/jobAssignments/${id}`}>
    <i className="glyphicon glyphicon-user" /> Assign
  </Link>
)

const createReservationTable = filterName => {
  let table = withTableConfigs(filterName)(
    ReservationTable,
    ReservationTableFilterPanel,
  )
  table = withDetailPopupProvider(table, ReservationDetailPopup)
  table = withDeleteModal(table)

  return table
}

export const AllReservationTable = createReservationTable('reservation')
export const KioskReservationTable = createReservationTable('kioskReservation')
export const WarningReservationTable = createReservationTable(
  'warningReservation',
)
