import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import VoucherBookTable from './VoucherBookTable'
import VoucherBookFormPage from './VoucherBookFormPage'
import { findAccessLevel } from '../../modules/accessRoute'

export class VoucherBookPage extends React.Component {
  handleSubmit = () => {
    this.props.history.push(`/voucherBook`)
  }

  table = () => <VoucherBookTable path="/voucherBook" />

  formPage = ({
    match: {
      params: { id },
    },
  }) => <VoucherBookFormPage id={id} onSubmit={this.handleSubmit} />

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/voucherBook"
          component={this.table}
          ifAnyGranted={findAccessLevel('/voucherBook')}
        />

        <PrivateRoute
          path="/voucherBook/new"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/voucherBook')}
        />

        <PrivateRoute
          path="/voucherBook/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/voucherBook')}
        />
      </div>
    )
  }
}
export default withRouter(VoucherBookPage)
