import React from 'react'
import { isEmpty } from 'lodash'
import ProductCheckboxSelectTable, {
  ProductCheckboxSelectTableItem,
} from '../product/ProductCheckboxSelectTable'

const PriceProductCheckboxSelectTable = ({
  priceProducts,
  onCheckPriceProduct,
  getIsChecked,
  searchText,
  onSearchTextChange,
  isLoading,
}) => (
  <div>
    <ProductCheckboxSelectTable
      searchText={searchText}
      onSearchTextChange={onSearchTextChange}
      isLoading={isLoading}
      isEmpty={isEmpty(priceProducts)}
    >
      {priceProducts &&
        priceProducts.map((price, index) => (
          <ProductCheckboxSelectTableItem
            key={price.productId}
            input={{
              name: price.productId,
              checked: getIsChecked(price),
              onChange: onCheckPriceProduct,
            }}
            productName={price.product.name}
          />
        ))}
    </ProductCheckboxSelectTable>
  </div>
)

export default PriceProductCheckboxSelectTable
