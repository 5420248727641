import React from 'react'
import { Panel, Form, Col, FormControl, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

export default ({ isShow, filter, onFilterChange, onClearFilter }) => {

  const fieldsLabel = I18n.t('userPage.fields')
  return (
    <Panel collapsible expanded={isShow}>
      <Form horizontal>
        <Col md={4}>
          <FormControl
            type="text"
            value={filter.username || ''}
            onChange={e => onFilterChange('username', e.currentTarget.value)}
            placeholder={fieldsLabel.username} />
        </Col>
        <Col md={4}>
          <FormControl
            type="text"
            value={filter.displayName || ''}
            onChange={e => onFilterChange('displayName', e.currentTarget.value)}
            placeholder={fieldsLabel.displayName} />
        </Col>
        <Col md={4}>
          <FormControl
            type="text"
            value={filter.email || ''}
            onChange={e => onFilterChange('email', e.currentTarget.value)}
            placeholder={fieldsLabel.email} />
        </Col>
        <Col md={4}>
          <Button className="pull-right" onClick={onClearFilter}>
            <span className="glyphicon glyphicon-trash"></span>  Clear Filter
            </Button>
        </Col>
      </Form>
    </Panel>
  )
}
