import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import ServiceTxnTableContainer from './ServiceTxnTableContainer'
import { findAccessLevel } from '../../modules/accessRoute';
// import StaffFormPage from './StaffFormPage'

export class ServiceTxnPage extends React.Component {
  handleSubmit = () => {
    this.props.history.push(`/serviceTxn`)
  }

  table = () => <ServiceTxnTableContainer path="/serviceTxn" />

  // formPage = ({ match: { params: { id } } }) => (
  //   <StaffFormPage id={id} onSubmit={this.handleSubmit} />
  // )

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/serviceTxn"
          component={this.table}
          ifAnyGranted={findAccessLevel('/serviceTxn')}
        />

        {/* <PrivateRoute
          path="/serviceTxn/new"
          component={this.formPage}
          ifAnyGranted={normalAccess} /> */}

        {/* <PrivateRoute
          path="/serviceTxn/edit/:id"
          component={this.formPage}
          ifAnyGranted={normalAccess} /> */}
      </div>
    )
  }
}
export default withRouter(ServiceTxnPage)
