import React from 'react'
import { ButtonGroup, Button, Row, Col, ControlLabel } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import _ from 'lodash'
import { dateFormat, dateTimeFormat } from '../table'

const ProcessingInvoiceIntervalPanel = ({
  processingInvoiceInterval,
  cancelProcess,
  restartProcess,
  isShowRecoveryControl = false,
}) => {
  if (!processingInvoiceInterval)
    return (
      <div className="panel panel-default">
        <div className="panel-body">
          <h4>{I18n.t('invoiceIntervalPage.processingPanel.noProcess')}</h4>
        </div>
      </div>
    )
  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h4>{I18n.t('invoiceIntervalPage.processingPanel.processing')}</h4>
      </div>
      <div className="panel-body">
        <Row>
          <Col md={2}>
            <ControlLabel>
              {I18n.t('invoiceIntervalPage.fields.startDate')}
            </ControlLabel>
            <p>{dateFormat(processingInvoiceInterval.startDate)}</p>
          </Col>
          <Col md={2}>
            <ControlLabel>
              {I18n.t('invoiceIntervalPage.fields.endDate')}
            </ControlLabel>
            <p>{dateFormat(processingInvoiceInterval.endDate)}</p>
          </Col>
          <Col md={2}>
            <ControlLabel>
              {I18n.t('invoiceIntervalPage.fields.processInfo.startDateTime')}
            </ControlLabel>
            <p>
              {dateTimeFormat(
                _.get(processingInvoiceInterval, 'processInfo.startDateTime'),
              )}
            </p>
          </Col>
          <Col md={2}>
            <ControlLabel>
              {I18n.t(
                'invoiceIntervalPage.fields.processInfo.lastUpdateDateTime',
              )}
            </ControlLabel>
            <p>
              {dateTimeFormat(
                _.get(
                  processingInvoiceInterval,
                  'processInfo.lastUpdateDateTime',
                ),
              )}
            </p>
          </Col>
          {isShowRecoveryControl && (
            <Col md={2}>
              <ButtonGroup vertical>
                <Button bsStyle="warning" onClick={cancelProcess}>
                  {I18n.t('invoiceIntervalPage.processingPanel.cancel')}
                </Button>
                <Button bsStyle="danger" onClick={restartProcess}>
                  {I18n.t('invoiceIntervalPage.processingPanel.restart')}
                </Button>
              </ButtonGroup>
            </Col>
          )}
        </Row>
      </div>
    </div>
  )
}

export default ProcessingInvoiceIntervalPanel
