import _ from 'lodash'

export const invoiceIntervalStatus = {
  draft: 'draft',
  processing: 'processing',
  completed: 'completed',
}

function processRunningForPeriod(
  invoiceInterval,
  currentTime,
  enableRecoveryControlAfterProcessRunFor,
) {
  const startDateTime = _.get(
    invoiceInterval,
    'processInfo.startDateTime',
  )

  if (!startDateTime) return undefined

  return currentTime.diff(
    startDateTime,
    enableRecoveryControlAfterProcessRunFor.unitOfTime,
  )
}

export function isShowRecoveryControlForInvoiceInterval(
  invoiceInterval,
  currentTime,
  enableRecoveryControlAfterProcessRunFor,
) {
  const startedRunningPeriod = processRunningForPeriod(
    invoiceInterval,
    currentTime,
    enableRecoveryControlAfterProcessRunFor,
  )

  if (startedRunningPeriod)
    return (
      startedRunningPeriod > enableRecoveryControlAfterProcessRunFor.value
    )

  return false
}
