import React from 'react'

export default function WebAirlineReport({
  data: {
    options: { data, dataset },
  },
}) {
  const { logoUrl } = data
  return (
    <div className="w-[840px] mx-auto py-6 px-6 bg-white print:bg-transparent shadow print:shadow-none">
      <div className="w-full py-2 px-1 flex flex-col space-y-4">
        {dataset.airlines.length > 0 ? (
          dataset.airlines.map((airline, index) => {
            return (
              <div
                className={
                  index !== dataset.airlines.length - 1 ? 'break-after-page' : ''
                }
              >
                <div className="flex flex-col w-full items-center justify-center p-4">
                  <img
                    className="object-contain w-32"
                    src={airline.airlineLogoURL || logoUrl}
                    alt={airline.airline}
                  />
                  <span className="text-2xl font-semibold text-center">
                    {airline.title}
                  </span>
                </div>

                <div className="w-full flex space-x-4 justify-flex-1 justify-between">
                  <span className="space-x-2">
                    <span className="font-semibold uppercase">Airline:</span>
                    <span>{airline.airline}</span>
                  </span>
                  <span className="space-x-2">
                    <span className="font-semibold uppercase">Flight Number</span>
                    <span>{airline.flightNumber || '-'}</span>
                  </span>
                  <span className="space-x-2">
                    <span className="font-semibold uppercase">DATE:</span>
                    <span>
                      {airline.startDate} - {airline.endDate}
                    </span>
                  </span>
                </div>

                <div className="w-full flex space-x-4">
                  <span className="flex-1 space-x-2">
                    <span className="font-semibold uppercase">Station:</span>
                    <span>{airline.stationName || '-'}</span>
                  </span>
                  <span className="">
                    <span className="font-semibold uppercase">
                      STD:.............................................................
                    </span>
                  </span>
                </div>

                {airline.serviceTxns && airline.serviceTxns.length > 0 ? (
                  <table className="table table-fixed w-full text-base text-left rtl:text-right text-stone-500 rounded-lg overflow-hidden mt-4">
                    <thead className="text-base text-stone-700 uppercase bg-stone-100">
                      <tr>
                        <th className="px-3 py-2 w-[30px] text-right">No.</th>
                        <th className="px-3 py-2">Passenger Name</th>
                        <th className="px-3 py-2 w-[45px] text-center">Seat</th>
                        <th className="px-3 py-2 w-[60px] text-center">Class</th>
                        <th className="px-3 py-2 w-[45px] text-center">IN</th>
                        <th className="px-3 py-2 w-[45px] text-center">OUT</th>
                        <th className="px-3 py-2 w-[80px] text-center">
                          VCH/SEQ NO.
                        </th>
                        <th className="px-3 py-2 w-[80px] text-center">
                          MEMBER CARD NO.
                        </th>
                        <th className="px-3 py-2">Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {airline.serviceTxns.map((item, index) => {
                        return (
                          <tr
                            key={item._id}
                            className="odd:bg-white even:bg-stone-50 border-b"
                          >
                            <td className="px-3 py-2 text-right">{index + 1}</td>
                            <td className="px-3 py-2">{item.paxName}</td>
                            <td className="px-3 py-2">{item.seatNumber}</td>
                            <td className="px-3 py-2 text-center">
                              {item.travelClass}
                            </td>
                            <td className="px-3 py-2 text-center">
                              {item.inTime}
                            </td>
                            <td className="px-3 py-2 text-center">
                              {item.outTime}
                            </td>
                            <td className="px-3 py-2 text-center">
                              {item.reference1}
                            </td>
                            <td className="px-3 py-2 text-center">
                              {item.reference2}
                            </td>
                            <td className="px-3 py-2">{item.remark}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="p-10 w-full text-center text-2xl font-semibold">
                    No Results
                  </div>
                )}

                <div className="w-full flex mt-6 justify-between">
                  <span className="font-semibold flex-1 space-x-2">
                    <span>REPORTED BY:</span>
                    <span>{airline.user_displayName}</span>
                  </span>
                  <span className="font-semibold space-x-2">CERTIFIED BY:</span>
                  <span>......................................</span>
                </div>

                <div className="w-full flex mt-1 justify-between">
                  <span className="font-semibold flex-1 space-x-2">
                    SUPERVISOR OF CORAL EXECUTIVE LOUNGE
                  </span>
                  <span className="font-semibold space-x-2">
                    {airline.airline} Staff
                  </span>
                </div>

                <div className="w-full flex justify-center my-4">
                  <span className="flex rounded-lg py-4 px-6 bg-stone-100 font-semibold space-x-6">
                    <span className="uppercase">
                      Total {airline.totalPaxAmount.toLocaleString()} Pax
                    </span>
                    <span className="uppercase">
                      Economy {airline.totalPaxAmountByEconomy.toLocaleString()}{' '}
                      Pax
                    </span>
                    <span className="uppercase">
                      Business {airline.totalPaxAmountByBusiness.toLocaleString()}{' '}
                      Pax
                    </span>
                    <span className="uppercase">
                      First Class{' '}
                      {airline.totalPaxAmountByFirstClass.toLocaleString()} Pax
                    </span>
                  </span>
                </div>
              </div>
            )
          })
        ) : (
          <div className="p-10 w-full text-center text-2xl font-semibold">
            No Results
          </div>
        )}
      </div>
    </div>
  )
}
