import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { fetchStation } from '../../actions/remote_api'
import { withDetailPopup } from '../detailPopup'
import { makeServiceTitle } from '../'

const StationDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('stationPage')
  return (
    <form className="form-horizontal">
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.code}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.code}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.name}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.name}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.service}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance && makeServiceTitle(instance.service)}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.capacity}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance.capacity ? instance.capacity : '-'}
          </p>
        </div>
      </div>
    </form>
  )
}

let StationDetailPopupContainer = withDetailPopup(StationDetailPopup, () => ({
  header: I18n.t('stationPage.detail.header'),
}))

export default connect(
  state => ({ instance: get(state, 'fetch.station.single') }),
  { fetchInstance: fetchStation },
)(StationDetailPopupContainer)
