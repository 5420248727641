import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Col } from 'react-bootstrap'

import { payerType } from '../../../services/payerType'
import AgentSearchSelectField from '../../agent/AgentSearchSelectField'
import ReservableInfo from '../ReservableInfo'
import AirlineSearchSelectField from '../../airline/AirlineSearchSelectField'
import { TextAreaField } from '../../form'
import CardSearchSelectField from '../../card/CardSearchSelectField'
import VoucherBookSearchSelectField from '../../voucherBook/VoucherBookSearchSelectField'
import { escape } from '../../../utils/regex'

const PayerSelect = ({ values, initialValues, isLocked, reservableStatus }) => {
  if (!values) return null

  switch (values.payerType) {
    case payerType.agent:
      return (
        <div>
          <Col md={6}>
            <AgentSearchSelectField
              name="agent"
              label={I18n.t('agent')}
              addStar={true}
              disabled={isLocked}
            />
          </Col>
          <Col md={6}>
            <ReservableInfo info={reservableStatus.info} reservation={values} />
          </Col>
          <Col md={12}>
            <TextAreaField
              name="agentEmails"
              label={I18n.t('reservationPage.fields.agentEmails')}
              disabled={isLocked}
            />
          </Col>
        </div>
      )
    case payerType.airline:
      return (
        <Col md={6}>
          <AirlineSearchSelectField
            label={I18n.t('airline')}
            name="payerAirlineId"
            initialValue={initialValues.payerAirlineId}
            addStar={true}
            disabled={isLocked}
          />
        </Col>
      )
    case payerType.card:
      return (
        <CardSearchSelectField
          label={I18n.t('card')}
          name="payerCardId"
          initialValue={initialValues.payerCardId}
          addStar={true}
          disabled={isLocked}
        />
      )
    case payerType.voucher:
      return (
        <div>
          <Col md={6}>
            <VoucherBookSearchSelectField
              label={I18n.t('voucherBook')}
              name="payerVoucherBook"
              disabled={isLocked}
              addStar={true}
              createQuery={searchText => ({
                name_like: searchText ? escape(searchText.toLowerCase()) : undefined,
                status: 'valid',
              })}
            />
          </Col>
          <Col md={6}>
            <ReservableInfo info={reservableStatus.info} reservation={values} />
          </Col>
        </div>
      )
    default:
      return null
  }
}

export default PayerSelect
