import React from 'react'
import AirlineForm from './AirlineForm'
import { connect } from 'react-redux'
import {
  fetchAirline,
  createAirline,
  updateAirline,
  clearAirline,
} from '../../actions/remote_api'
import { submit, stopSubmit } from 'redux-form'

class AirlineFormPage extends React.Component {
  formId = 'airline'
  state = { formData: {} }

  componentDidMount() {
    const { fetchAirline, id, clearAirline } = this.props

    clearAirline()
    if (id !== undefined) {
      fetchAirline(id)
    }
  }

  componentWillUnmount() {
    this.props.clearAirline()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.airline) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.airline },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }
  }

  handleSubmit = formData => {
    const { createAirline, updateAirline, id } = this.props
    const airline = formData

    if (airline.logo && airline.logo.split('?')[0])
      airline.logo = airline.logo.split('?')[0]

    if (id === undefined)
      createAirline(airline, { onResponse: this.handleResponse })
    else updateAirline(airline, { onResponse: this.handleResponse })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit()
  }

  submit = e => {
    this.props.submit(this.formId)
    e.preventDefault()
  }

  logoUploadURL = () =>
    this.state.formData._id
      ? `/api/airlines/${this.state.formData._id}/logo`
      : undefined

  handleMakeNewClassToServiceMapping = () => ({})

  render() {
    return (
      <form onSubmit={this.submit}>
        <AirlineForm
          form={this.formId}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          logoUploadURL={this.logoUploadURL()}
          makeNewClassToServiceMapping={this.handleMakeNewClassToServiceMapping}
          enableReinitialize
        />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { airline } }) => ({
    airline: airline ? airline.single : { firstName: '', lastName: '' },
    errors: airline ? airline.singleErrors : null,
  }),
  {
    createAirline,
    updateAirline,
    fetchAirline,
    clearAirline,
    submit,
    stopSubmit,
  },
)(AirlineFormPage)
