import { I18n } from 'react-redux-i18n'
import { withProps } from 'recompose'
import ConfirmModal from './confirmModal/ConfirmModal'

export default withProps(() => ({
  title: I18n.t('invoicePage.manualInvoice.unmarkManualConfirmModal.title'),
  subtitle: I18n.t(
    'invoicePage.manualInvoice.unmarkManualConfirmModal.subtitle',
  ),
}))(ConfirmModal)
