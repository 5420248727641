import React from 'react'
import DateTime from 'react-datetime'
import { Row, Col, ControlLabel, Button, ButtonGroup } from 'react-bootstrap'
import moment from 'moment'
import PageLabel from './PageLabel'

export default ({
  dateValue = moment(),
  dateFormat = 'DD/MM/YYYY',
  pageNumber,
  totalPage,
  onPageChange,
  onDateChange,
  onDownloadAllServiceTxn,
  onDownloadServiceTxnBySeq,
  onDownloadServiceTxnInCSV,
  onViewAllServiceTxn,
  onViewServiceTxnBySeq,
  isNeedWorkSheet,
}) => (
  <Row>
    <Col md={4}>
      <ControlLabel>Date</ControlLabel>
      <DateTime
        value={dateValue}
        onChange={(value) => {
          if (!value || !moment.isMoment(value)) return
          onDateChange(value)
        }}
        dateFormat={dateFormat}
        timeFormat={false}
      />
    </Col>

    <Col md={3}>
      <ControlLabel>Seq.</ControlLabel>
      {totalPage > 0 && (
        <PageLabel
          pageNumber={pageNumber}
          totalPage={totalPage}
          onPageChange={onPageChange}
        />
      )}
    </Col>

    <Col md={5}>
      <div>
        <ButtonGroup>
          <Button
            className=" btn btn-primary pager-btn"
            disabled={!isNeedWorkSheet}
            onClick={onDownloadServiceTxnBySeq}
          >
            <span className="glyphicon glyphicon-open-file"></span> Pdf
          </Button>
          <Button
            className=" btn btn-primary pager-btn"
            disabled={!isNeedWorkSheet && totalPage < 1}
            onClick={onDownloadAllServiceTxn}
          >
            <span className="glyphicon glyphicon-level-up"></span> All Pdf
          </Button>
          <Button
            className=" btn btn-primary pager-btn"
            disabled={!isNeedWorkSheet && totalPage < 1}
            onClick={onDownloadServiceTxnInCSV}
          >
            <span className="glyphicon glyphicon-th-list"></span> All CSV
          </Button>
        </ButtonGroup>
      </div>

      <div className="mt-2">
        <ButtonGroup>
          <Button
            className="btn btn-primary pager-btn space-x-1 flex items-center m-auto"
            disabled={!isNeedWorkSheet}
            onClick={onViewServiceTxnBySeq}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
            <span>View #{pageNumber}</span>
          </Button>
          <Button
            className="btn btn-primary pager-btn space-x-1 flex items-center m-auto"
            disabled={!isNeedWorkSheet && totalPage < 1}
            onClick={onViewAllServiceTxn}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
            <span>View All</span>
          </Button>
        </ButtonGroup>
      </div>
    </Col>
  </Row>
)
