import React from 'react'
import styled from 'styled-components'
import TextArea from './TextArea';

export default props => (
  <DropOffAreaItemLayout>
    <TextArea
      textAreaProps={{
        rows: 3,
        placeholder: 'Enter drop off area message'
      }}
      {...props} />
  </DropOffAreaItemLayout>
)

const DropOffAreaItemLayout = styled.div``