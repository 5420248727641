import React from 'react'
import styledComponents from 'styled-components';

export default (props) => (
  <Layout>
    <Text>
      Select settings from the left
    </Text>
  </Layout>
)

const Layout = styledComponents.div`
  display: flex;
  min-height: 150px;
  height: 450px;
  margin: auto;
`

const Text = styledComponents.span`
  margin: auto;
  font-size: 32px;
  font-weight: bold;
`