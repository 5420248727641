import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { Badge } from 'react-bootstrap'

import { fetchInvoice } from '../../actions/remote_api'
import { withDetailPopup } from '../detailPopup'
import { dateTimeFormat } from '../table/index'
import { priceFormat } from '../../services/currency'
import InvoiceDetailPopupRightBarButtons from './InvoiceDetailPopupRightBarButtons'

const InvoiceDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('invoicePage')
  if (!instance) return null

  return (
    <form className="form-horizontal">
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.code}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance.code || <Badge>NO CODE</Badge>}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.debtorName}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance.debtor.name}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.price}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{priceFormat(instance.price)}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.tax}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{priceFormat(instance.tax)}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.grandTotal}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {priceFormat(instance.grandTotal)}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.status}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance.status.toUpperCase()}</p>
        </div>
      </div>

      <small>
        <div className="form-group">
          <label className="col-sm-4 control-label">
            {fields.lastUpdatedDateTime}
          </label>
          <div className="col-sm-8">
            <p className="form-control-static">
              {instance.log && dateTimeFormat(instance.log.lastUpdatedDateTime)}
              {' by '}
              {instance.log && instance.log.lastUpdatedBy}
            </p>
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-4 control-label">
            {fields.createdDateTime}
          </label>
          <div className="col-sm-8">
            <p className="form-control-static">
              {instance.log && dateTimeFormat(instance.log.createdDateTime)}
              {' by '}
              {instance.log && instance.log.createdBy}
            </p>
          </div>
        </div>
      </small>
    </form>
  )
}

let InvoiceDetailPopupContainer = withDetailPopup(
  InvoiceDetailPopup,
  () => ({
    header: I18n.t('invoicePage.detail.header'),
  }),
  InvoiceDetailPopupRightBarButtons,
)

export default connect(
  state => ({
    instance: get(state, 'fetch.invoice.single'),
  }),
  { fetchInstance: fetchInvoice },
)(InvoiceDetailPopupContainer)
