import React from 'react'
import { ControlLabel, Col, Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import PayerSelect from './PayerSelect'
import { RadioGroup, Radio } from '../../form/RadioGroup'

const PayerControl = ({
  fields,
  selectablePayerType,
  formValues,
  initialValues,
  isLocked,
  reservableStatus,
}) => (
  <div>
    <Row>
      <Col md={4}>
        <div>
          <ControlLabel>{fields.payerType}</ControlLabel>
        </div>
        <RadioGroup>
          {selectablePayerType.map(type => (
            <Radio key={type}>
              <Field
                name="payerType"
                component="input"
                type="radio"
                value={type}
                disabled={isLocked}
              />
              {type.toUpperCase()}
            </Radio>
          ))}
        </RadioGroup>
      </Col>
    </Row>

    <Row>
      <PayerSelect
        values={formValues}
        initialValues={initialValues}
        isLocked={isLocked}
        reservableStatus={reservableStatus}
      />
    </Row>
  </div>
)

export default PayerControl
