import React from 'react'
import { connect } from 'react-redux'
import { submit, stopSubmit, getFormValues, change } from 'redux-form'

import InvoiceForm from './InvoiceForm'
import {
  fetchInvoice,
  createInvoice,
  updateInvoice,
  clearInvoice,
} from '../../actions/remote_api'
import { invoiceStatus } from '../../services/invoice'
import { debtTypes } from '../../services/debtType'
import { payerType } from '../../services/payerType'

const formId = 'invoice'

class InvoiceFormPage extends React.Component {
  state = {
    formData: {
      debtType: debtTypes.reservation,
      payerType: payerType.manual,
      discount: 0,
    },
  }

  componentDidMount() {
    const { fetchInvoice, id, clearInvoice } = this.props

    clearInvoice()
    if (id !== undefined) {
      fetchInvoice(id)
    }
  }

  componentWillUnmount() {
    this.props.clearInvoice()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.invoice) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.invoice },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(formId, nextProps.errors)
    }
  }

  handleSubmit = formData => {
    const { createInvoice, updateInvoice, id } = this.props
    const invoice = formData
    if (id === undefined)
      createInvoice(invoice, {
        onResponse: this.handleResponse,
      })
    else
      updateInvoice(invoice, {
        onResponse: this.handleResponse,
      })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit()
  }

  submit = e => {
    this.props.submit(formId)
    e.preventDefault()
  }

  isInRestrictionStatus = () =>
    this.state.formData &&
    this.state.formData.status &&
    this.state.formData.status !== invoiceStatus.draft

  updateSubtotal = () => {}

  render() {
    return (
      <form onSubmit={this.submit}>
        <InvoiceForm
          form={formId}
          initialValues={this.state.formData}
          formValues={this.props.formValues}
          onSubmit={this.handleSubmit}
          enableReinitialize
        />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { invoice }, ...state }) => ({
    invoice: invoice ? invoice.single : {},
    errors: invoice ? invoice.singleErrors : null,
    formValues: getFormValues(formId)(state),
  }),
  {
    createInvoice,
    updateInvoice,
    fetchInvoice,
    clearInvoice,
    submit,
    stopSubmit,
    change,
  },
)(InvoiceFormPage)
