import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import { isNumber } from 'lodash'
import styledComponents from 'styled-components'
import { priceFormat } from '../../../services/currency'

export default ({ step: { start, limit, price, addendumPrice }, seq }) => (
  <TableRow>
    <Col sm={2}>#{seq}</Col>
    <Col sm={4}>
      {start} -{' '}
      {limit || (
        <Translate value="pricingContractPage.pricingProduct.noLimit" />
      )}
    </Col>
    <Col sm={3} className="text-right">
      {!isNumber(price) ? '-' : priceFormat(price)}
    </Col>
    <Col sm={3} className="text-right">
      {!isNumber(addendumPrice) ? '-' : priceFormat(addendumPrice)}
    </Col>
  </TableRow>
)

const TableRow = styledComponents(Row)`
  margin-left: -16px;
  margin-right: -16px;
`

export const SteppedPricingProductTableDetailStepAddendumPrice = ({
  step: { start, limit, price, addendumPrice },
  seq,
}) => (
  <TableRow>
    <Col sm={12} className="text-right">
      {!isNumber(addendumPrice) ? '-' : priceFormat(addendumPrice)}
    </Col>
  </TableRow>
)
