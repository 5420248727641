import React from 'react'
import { Modal, Button, Row, Col, FormGroup } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import PricingContractTemplateSelectField from './PricingContractTemplateSelectField'

export default ({
  isShow,
  handleClose,
  handleDone,
  info,
  pricingProductId,
  onChange,
  isValid,
  contractorType,
}) => (
  <Modal show={isShow} bsSize="sm">
    <Modal.Body>
      <Row>
        <Col md={12}>
          <FormGroup>
            <label>
              <Translate value="pricingContractPage.pricingContractTemplate.title" />
            </label>
            <PricingContractTemplateSelectField
              value={pricingProductId}
              onChange={onChange}
              contractorType={contractorType}
            />
          </FormGroup>
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle="default" onClick={handleClose}>
        <Translate value="action.cancel" />
      </Button>
      <Button
        bsStyle="primary"
        onClick={handleDone}
        disabled={!isValid}
      >
        <Translate value="action.done" />
      </Button>
    </Modal.Footer>
  </Modal>
)
