import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'

import { updateInvoice } from '../../../actions/remote_api'
import { invoiceStatus } from '../../../services/invoice'

function markManual(invoice) {
  return {
    ...invoice,
    status: invoiceStatus.manual,
  }
}

export const withMarkManualInvoice = compose(
  connect(
    null,
    (dispatch, { invoice }) => ({
      updateInvoice: onResponse => {
        dispatch(updateInvoice(markManual(invoice), { onResponse }))
      },
    }),
  ),
  withProps(({ updateInvoice }) => ({
    markManual: updateInvoice,
  })),
)

export default withMarkManualInvoice(({ markManual, children }) =>
  children(markManual),
)
