import React from 'react'
import { Panel, Form, Row, Col, FormControl, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import Select from 'react-select'

export default ({ isShow, filter, onFilterChange, onClearFilter }) => {

  const fieldsLabel = I18n.t('staffPage.fields')
  return (
    <Panel collapsible expanded={isShow}>
      <Form horizontal>
        <Row>
          <Col md={2}>
            <FormControl
              type="text"
              value={filter.code || ''}
              onChange={e => onFilterChange('code', e.currentTarget.value)}
              placeholder={fieldsLabel.code} />
          </Col>
          <Col md={2}>
            <FormControl
              type="text"
              value={filter.firstName || ''}
              onChange={e => onFilterChange('firstName', e.currentTarget.value)}
              placeholder={fieldsLabel.firstName} />
          </Col>
          <Col md={2}>
            <FormControl
              type="text"
              value={filter.lastName || ''}
              onChange={e => onFilterChange('lastName', e.currentTarget.value)}
              placeholder={fieldsLabel.lastName} />
          </Col>
          <Col md={2}>
            <Select
              value={filter.gender}
              onChange={value => onFilterChange('gender', value)}
              placeholder={fieldsLabel.gender}
              simpleValue
              options={genderOptions} />
          </Col>
          <Col md={2}>
            <FormControl
              type="text"
              value={filter.email || ''}
              onChange={e => onFilterChange('email', e.currentTarget.value)}
              placeholder={fieldsLabel.email} />
          </Col>
          <Col md={2}>
            <Button className="pull-right" onClick={onClearFilter}>
              <span className="glyphicon glyphicon-trash"></span>  Clear Filter
            </Button>
          </Col>
        </Row>
      </Form>
    </Panel>
  )
}

const genderOptions = [
  { label: I18n.t('fields.male'), value: 'male' },
  { label: I18n.t('fields.female'), value: 'female' },
]