import React from 'react'
import StationForm from './StationForm'
import { connect } from 'react-redux'
import { fetchStation, createStation, updateStation, clearStation } from '../../actions/remote_api'
import { submit, stopSubmit } from 'redux-form'

class StationFormPage extends React.Component {
  formId = 'station'
  state = { formData: {} }

  componentDidMount() {
    const { fetchStation, id, clearStation } = this.props;

    clearStation()
    if (id !== undefined) {
      fetchStation(id)
    }
  }

  componentWillUnmount() {
    this.props.clearStation()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.station) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.station }
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }
  }

  handleSubmit = formData => {
    const { createStation, updateStation, id } = this.props;
    const station = formData
    if (id === undefined)
      createStation(station, { onResponse: this.handleResponse })
    else
      updateStation(station, { onResponse: this.handleResponse })
  }

  handleResponse = ({ ok }) => {
    if (ok)
      this.props.onSubmit()
  }

  submit = e => {
    this.props.submit(this.formId)
    e.preventDefault()
  }

  imageUploadURL = () => this.state.formData._id ? `/api/stations/${this.state.formData._id}/image` : undefined 

  render() {
    return (
      <form onSubmit={this.submit}>
        <StationForm
          form={this.formId}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          imageUploadURL={this.imageUploadURL()}
          enableReinitialize />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { station }, ...state }) => ({
    station: station ? station.single : {},
    errors: station ? station.singleErrors : null,
  }),
  { createStation, updateStation, fetchStation, clearStation, submit, stopSubmit }
)(StationFormPage)