import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { TextField, SwitchField } from '../form/index'
import FormattedDateTimeField from '../form/FormattedDateTimeField'
import ContractorSelectField from './ContractorSelectField'

export default ({ fields, initialValues, contractorType }) => (
  <div>
    <Row>
      <Col md={12}>
        <TextField name="name" label={fields.name} addStar={true} />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <SwitchField
          name="isTemplate"
          label={fields.isTemplate}
          initialValue={true}
          isValidate={false}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ContractorSelectField
          contractorType={contractorType}
          initialValues={initialValues}
          fields={fields}
        />
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <FormattedDateTimeField
          name="startDate"
          label={fields.startDate}
          type="date"
        />
      </Col>
      <Col md={6}>
        <FormattedDateTimeField
          name="endDate"
          label={fields.endDate}
          type="date"
        />
      </Col>
    </Row>
  </div>
)
