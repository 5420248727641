import React from 'react'
import ReportMenuGrid from './ReportMenuGrid'
// import AirlineReportContainer from './AirlineReportContainer'
// import CardReportContainer from './CardReportContainer';
// import ReservationReportContainer from './ReservationReportContainer'
import { Switch } from 'react-router-dom'
import PrivateRoute from '../auth/PrivateRoute'

import BetaAirlineReportContainer from './BetaAirlineReportContainer'
import BetaCardReportContainer from './BetaCardReportContainer';
import BetaReservationReportContainer from './BetaReservationReportContainer'

export default () => (
  <Switch>
    {/* <PrivateRoute path="/report/airlineReport" component={AirlineReportContainer} ifAnyGranted={['user', 'report']} /> */}
    {/* <PrivateRoute path="/report/cardReport" component={CardReportContainer} ifAnyGranted={['user', 'report']} /> */}
    {/* <PrivateRoute path="/report/reservationReport" component={ReservationReportContainer} ifAnyGranted={['user', 'report']} /> */}
    <PrivateRoute path="/report/beta/airlineReport" component={BetaAirlineReportContainer} ifAnyGranted={['user', 'report']} />
    <PrivateRoute path="/report/beta/cardReport" component={BetaCardReportContainer} ifAnyGranted={['user', 'report']} />
    <PrivateRoute path="/report/beta/reservationReport" component={BetaReservationReportContainer} ifAnyGranted={['user', 'report']} />
    <PrivateRoute path="/report" component={ReportMenuGrid} ifAnyGranted={['user', 'report']} />
  </Switch>
)
