import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkIfAnyGrantedByGivenAuth } from '../../modules/authService'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!rest.auth.isPrepared) return null

      if (!rest.auth.isLoggedIn)
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )

      if (!checkIfAnyGrantedByGivenAuth(rest.auth, rest.ifAnyGranted))
        return (
          <Redirect
            to={{
              pathname: '/permission-denined',
              state: { from: props.location },
            }}
          />
        )

      return <Component {...props} />
    }}
  />
)

export default withRouter(
  connect(state => ({ auth: state.auth }))(PrivateRoute),
)
