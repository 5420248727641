import { actionTypes } from 'redux-form'
import _, { get } from 'lodash'
import immutable from 'object-path-immutable'

const createReservationForm = ({ getServiceDateTime }) => (state, action) => {
  switch (action.type) {
    case actionTypes.CHANGE:
      return change(state, action, { getServiceDateTime })
    default:
      return state
  }
}

const change = (state, action, { getServiceDateTime }) => {
  const {
    meta: { field },
    payload,
  } = action
  switch (field) {
    case 'payerVoucherBook': // do this with other payers
      return immutable(state)
        .set('values.payerVoucherBookId', get(payload, '_id'))
        .value()
    case 'product':
      return immutable(state)
        .set('values.productId', get(payload, '_id'))
        .set(
          'values.targetStationsByService',
          _
            .chain(payload)
            .get('services', [])
            .map(service => ({ service }))
            .value(),
        )
        .value()
    case 'agent':
      return immutable(state)
        .set('values.agentId', get(payload, '_id'))
        .value()
    case 'flightDateTime':
      const serviceDateTime = getServiceDateTime(
        payload,
        state.values.flightType,
        state.values.serviceDateTime,
      )
      return immutable(state)
        .set('values.serviceDateTime', serviceDateTime)
        .value()

    default:
      return state
  }
}

export default createReservationForm
