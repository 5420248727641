import React from 'react'
import { connect } from 'react-redux'
import UserInfo from './UserInfo'

const UserInfoContainer = ({ auth }) => (
  <UserInfo auth={auth} />
)

export default connect(
  state => ({ auth: state.auth })
)(UserInfoContainer)