import React from 'react'
import {
  fetchDropOffAreas,
  saveDropOffAreas,
  clearDropOffAreas,
} from '../../actions/optionsApi'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { memoize, isEqual, isEmpty } from 'lodash'
import Toolbar from './Toolbar'
import SettingsDropOffAreaItem from './SettingsDropOffAreaItem'
import styledComponents from 'styled-components'
import { Badge } from 'react-bootstrap'
const isNotEmpty = value => !isEmpty(value)

const setDropOffAreas = dropOffAreas => state => ({
  ...state,
  dropOffAreas,
})

const addDropOffArea = () => state => ({
  ...state,
  dropOffAreas: [...state.dropOffAreas, ''],
})

const setErrors = errors => state => ({
  ...state,
  errors,
})

const clearErrors = setErrors([])

const validate = dropOffArea => {
  return isEmpty(dropOffArea) ? 'Error cannot be empty message' : undefined
}

class SettingsDropOffArea extends React.Component {
  state = {
    dropOffAreas: [],
    errors: [],
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dropOffAreas &&
      !isEqual(this.props.dropOffAreas, nextProps.dropOffAreas)
    ) {
      this.setState(setDropOffAreas(nextProps.dropOffAreas))
    }
  }

  componentDidMount() {
    if (isEmpty(this.props.dropOffAreas)) this.props.fetchDropOffAreas()
    else this.setState(setDropOffAreas(this.props.dropOffAreas))

    this.createHandleInputChange = memoize(this.createHandleInputChange)
    this.createHandleRemove = memoize(this.createHandleRemove)
  }

  componentWillUnmount() {
    this.props.clearDropOffAreas('list')
  }

  createHandleInputChange = index => e => {
    const value = e.target.value
    this.setState(state => ({
      dropOffAreas: [
        ...state.dropOffAreas.slice(0, index),
        value,
        ...state.dropOffAreas.slice(index + 1),
      ],
    }))
  }

  createHandleRemove = index => () => {
    this.setState(state => ({
      dropOffAreas: [
        ...state.dropOffAreas.slice(0, index),
        ...state.dropOffAreas.slice(index + 1),
      ],
    }))
  }

  handleAdd = () => {
    this.setState(addDropOffArea())
  }

  validate = onValidateSuccess => {
    const errors = this.state.dropOffAreas.map(validate)
    if (isNotEmpty(errors) && errors.some(isNotEmpty)) {
      this.setState(setErrors(errors))
    } else {
      this.setState(clearErrors)
      onValidateSuccess()
    }
  }

  handleSave = () => {
    this.validate(() => {
      this.props.saveDropOffAreas(this.state.dropOffAreas, {
        onResponse: this.handleSaveSuccess,
      })
    })
  }

  handleSaveSuccess = async response => {
    if (response.ok) toastr.success('Save Success')
    else toastr.error('Save Failed', await response.text())
  }

  renderEmptyStateIfNeeded() {
    if (isEmpty(this.state.dropOffAreas))
      return (
        <EmptyStateLayout>
          <div>
            No drop off area. Click <Badge>+ Add</Badge> on the top left to add
            first drop off area.
          </div>
        </EmptyStateLayout>
      )
    return null
  }

  render() {
    return (
      <div>
        <Toolbar onAddClick={this.handleAdd} onSaveClick={this.handleSave} />
        {this.state.dropOffAreas.map((dropOffArea, index) => (
          <div key={index}>
            <SettingsDropOffAreaItem
              value={dropOffArea}
              onChange={this.createHandleInputChange(index)}
              error={this.state.errors[index]}
              onRemoveClick={this.createHandleRemove(index)}
            />
          </div>
        ))}
        {this.renderEmptyStateIfNeeded()}
      </div>
    )
  }
}

const EmptyStateLayout = styledComponents.div`
  display: flex;
  min-height: 250px;
  height: 400px;

  > * {
    margin: auto;
    font-size: 24px;
    max-width: 750px;
    text-align: center;
    > * {
      font-size: 18px;
    }
  }
`

export default connect(
  ({ fetch: { dropOffArea } }) => ({
    dropOffAreas: dropOffArea ? dropOffArea.list : undefined,
  }),
  {
    fetchDropOffAreas,
    saveDropOffAreas,
    clearDropOffAreas,
  },
)(SettingsDropOffArea)
