import React from 'react'
import ConfirmDeleteModal from '../form/ConfirmDeleteModal'

export default component => props => (
  <DeleteModalContainer {...props} children={component} />
)

class DeleteModalContainer extends React.Component {

  state = {
    isDeleteModalOpen: false,
    onDeleteInstance: null
  }

  handleDelete = instances => {
    const { deleteInstance, onRemove } = this.props
    instances.forEach(({ _id }) => deleteInstance(_id, onRemove))
  }

  makeDeleteHandler = instances => () => this.handleDelete(instances)

  handleConfirmDelete = instances => {
    this.setState({ isDeleteModalOpen: true, onDeleteInstance: this.makeDeleteHandler(instances) })
  }

  handleCloseModal = () => {
    this.setState({ isDeleteModalOpen: false });
  }

  render() {
    const { children: Children, ...props } = this.props
    return (
      <div>
        <ConfirmDeleteModal closeModal={this.handleCloseModal} 
          showModal={this.state.isDeleteModalOpen} 
          performDeletion={this.state.onDeleteInstance} />
        <Children {...props} onConfirmDelete={this.handleConfirmDelete} />
      </div>
    )
  }
}