export async function rateLimit(taskFn, rateMs, onProgressUpdate) {
  onProgressUpdate(true)
  try {
    const result = await taskFn()
    return result
  } finally {
    await timeout(rateMs)
    onProgressUpdate(false)
  }
}

export async function timeout(ms) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}
