import React from 'react'
import Option from '../form/Option'
import { I18n } from 'react-redux-i18n'
import _ from 'lodash'

const getInvoiceProfileOptions = () =>
  _.map(I18n.t('invoiceProfile'), (label, value) => ({
    value,
    label,
  }))

const InvoiceProfileOption = props => (
  <Option options={getInvoiceProfileOptions()} {...props} />
)

export default InvoiceProfileOption
