import React from 'react'
import { withHandlers } from 'recompose'
import { Row, Col } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { TextField } from '../../form/index'

const FixedPricingProductInput = ({ formKeyPath }) => (
  <Row>
    <Col md={2} className="text-right">
      <strong>{I18n.t('pricingContract.pricingProduct.price')}</strong>
    </Col>
    <Col md={4}>
      <TextField
        name={`${formKeyPath}.price`}
        placeholder={I18n.t('pricingContract.pricingProduct.price')}
        className="text-right"
        type="number"
        isFormGroupValidate
      />
    </Col>
    <Col md={2} className="text-right">
      <strong>{I18n.t('pricingContract.pricingProduct.addendumPrice')}</strong>
    </Col>
    <Col md={4}>
      <TextField
        name={`${formKeyPath}.addendumPrice`}
        placeholder={I18n.t('pricingContract.pricingProduct.addendumPrice')}
        className="text-right"
        type="number"
        isFormGroupValidate
      />
    </Col>
  </Row>
)

export default withHandlers({
  onChange: ({ onChange }) => e => {
    try {
      const value = Number(e.target.value)
      onChange(value)
    } catch (e) {}
  },
})(FixedPricingProductInput)
