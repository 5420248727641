import React from 'react'
import { I18n } from 'react-redux-i18n'

export default () => (
  <div>
    <h1>{I18n.t('permissionDenined.title')}</h1>
    <hr />
    <h4>{I18n.t('permissionDenined.subtitle')}</h4>
    <p>
      <i>{I18n.t('permissionDenined.subtitle2')}</i>
    </p>
  </div>
)
