import React from 'react'
import moment from 'moment'
import { I18n } from 'react-redux-i18n'
import styled from 'styled-components'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const formatOnNotNil = formatter => data =>
  data ? formatter(data) : '-'

export const dateFormat = formatOnNotNil(dateString =>
  moment(dateString).format(I18n.t('date.display')),
)

export const timeFormat = formatOnNotNil(dateString =>
  moment(dateString).format(I18n.t('time.display')),
)

export const dateTimeFormat = formatOnNotNil(dateString =>
  moment(dateString).format(I18n.t('dateTime.display')),
)

const tooltip = <Tooltip id="tooltip">Click to view details</Tooltip>

export const makeDetailLink = onOpenDetailPopup => (cell, row) => (
  <OverlayTrigger placement="top" overlay={tooltip}>
    <a onClick={() => onOpenDetailPopup(row._id)} role="button">
      <u>{cell}</u>
    </a>
  </OverlayTrigger>
)

export const renderClassToServiceMapping = classToServiceMapping => {
  const empytClassToServiceMappingMsg = I18n.t(
    'cardPage.table.empytClassToServiceMappingMsg',
  )
  if (classToServiceMapping)
    return (
      <ListItemTable>
        <tbody>
          {classToServiceMapping.map((c, index) => (
            <tr key={index}>
              <td width="30%">{c.privilegeClass}</td>
              <td width="30%">{c.serviceClass}</td>
              <td width="30%">{c.service}</td>
              <td width="10%">{c.duration ? c.duration : '-'}</td>
            </tr>
          ))}
        </tbody>
      </ListItemTable>
    )
  return <div>{empytClassToServiceMappingMsg}</div>
}

export const ListItemTable = styled.table.attrs({
  className: 'table',
})`
  background-color: rgba(0, 0, 0, 0) !important;

  tr:first-child {
    border-top: hidden;

    td {
      padding-top: 0px;
    }
  }

  tr:last-child td {
    padding-bottom: 0px;
  }
`

export { default as withTableConfigs } from './withTableConfigs'
export { default as withDeleteModal } from './withDeleteModal'

const Thumbnail = styled.img`
  max-height: ${props => props.maxHeight};
  max-width: ${props => props.maxWidth};
`

export class ThumbnailContainer extends React.Component {
  state = {
    thumbnail:
      urlWithTimeQuery(this.props.thumbnail) || '/assets/images/no_image.png',
  }

  handleError = () => {
    this.setState({ thumbnail: '/assets/images/no_image.png' })
  }

  render() {
    return (
      <Thumbnail
        {...this.props}
        src={this.state.thumbnail}
        className="img-responsive center-block"
        alt="thumbnail"
        onError={this.handleError}
      />
    )
  }
}

export const makeRenderThumbnail = props => thumbnail => (
  <ThumbnailContainer {...props} thumbnail={thumbnail} />
)

// To fix behavior of browser that won't reload image if the url is the same
// after uploading new image, user can't see if image is updated
// by adding query params /api/images/abc.png?t=xxxx
// will trick browser to think that it's different url from previous
// so the browser will refetch an image
export function urlWithTimeQuery(thumbnail) {
  return thumbnail ? `${thumbnail}?t=${moment().utc()}` : undefined
}
