import _ from 'lodash'
import isEqual from 'lodash/fp/isEqual'

export const invoiceStatus = {
  draft: 'draft',
  pending: 'pending',
  paid: 'paid',
  manual: 'manual',
  cancel: 'cancel',
}

export const isPayable = invoice => invoice.status === invoiceStatus.pending
export const isPrintable = invoice => invoice.status !== invoiceStatus.draft
export const isManual = invoice => invoice.status === invoiceStatus.manual

export const isPaidOverDue = (billingDate, dueDayCount, compareToDate) =>
  compareToDate.diff(billingDate, 'days') > dueDayCount

const cancellableStatus = [invoiceStatus.cancel, invoiceStatus.paid]
export const isCancellable = status =>
  !_.some(cancellableStatus, isEqual(status))
