export const payerType = {
  // prepaidAgent: 'prepaidAgent',
  // postpaidAgent: 'postpaidAgent',
  agent: 'agent',
  voucher: 'voucher',
  airline: 'airline',
  card: 'card',
  walkin: 'walkin',
  manual: 'manual',
}
