import { actionTypes } from 'redux-form'
import _ from 'lodash'
import { isMoment } from 'moment'

const createReservableMiddleware = ({
  formId,
  fetchReservable,
  fetchId,
  clearReservable,
  reservationFormReducer,
  observedFields = [],
}) => store => next => {
  const debounceNext = _.debounce(next, 500)
  return action => {
    if (
      !action.meta ||
      !action.meta.field ||
      !action.meta.form ||
      action.meta.form !== formId
    ) {
      return next(action)
    }
    const {
      meta: { field },
    } = action

    if (
      actionTypes.CHANGE === action.type &&
      _.some(observedFields, observedField => observedField === field)
    ) {
      const state = store.getState()
      const formState = _.get(state, `form.${formId}`)
      const nextState = reservationFormReducer(formState, action)
      const formValues = _.get(nextState, 'values')
      const reservationId = formValues._id

      const reservableInfo = {
        reservationId,
        ...formValues,
        [field]: action.payload,
      }
      if (isReserveOperationRequired(reservableInfo)) {
        debounceNext(
          fetchReservableAction({
            fetchReservable,
            fetchId,
            ...reservableInfo,
          }),
        )
      } else {
        next(clearReservable('single', { fetchId }))
      }
    }
    return next(action)
  }
}

const isReserveOperationRequired = ({
  payerType,
  payerVoucherBook,
  guestPaxAdult,
  guestPaxChild,
  guestPaxInfant,
  agent,
  product,
  serviceDateTime,
}) => {
  const isPayerSelected = getIsPayerSelected(payerType, payerVoucherBook, agent)
  const hasUsage =
    !_.isNil(guestPaxAdult) ||
    !_.isNil(guestPaxChild) ||
    !_.isNil(guestPaxInfant)

  return isPayerSelected && hasUsage && product && serviceDateTime
}

const getIsPayerSelected = (payerType, payerVoucherBook, agent) => {
  switch (payerType) {
    case 'agent':
      return !_.isNil(agent)
    case 'voucher':
      return !_.isNil(payerVoucherBook)
    default:
      return false
  }
}

const fetchReservableAction = ({
  fetchReservable,
  fetchId,
  payerType,
  payerVoucherBook,
  guestPaxAdult,
  guestPaxChild,
  guestPaxInfant,
  reservationId,
  agent,
  product,
  serviceDateTime,
  isCharged,
}) => {
  return fetchReservable(
    {
      payerType,
      payerVoucherBookId: _.get(payerVoucherBook, '_id'),
      agentId: _.get(agent, '_id'),
      guestPaxAdult,
      guestPaxChild,
      guestPaxInfant,
      reservationId,
      productId: _.get(product, '_id'),
      serviceDateTime: isMoment(serviceDateTime)
        ? serviceDateTime.toISOString()
        : serviceDateTime,
      isCharged,
    },
    { fetchId },
  )
}

export default createReservableMiddleware
