import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import InvoiceIntervalTable from './InvoiceIntervalTable'
import InvoiceIntervalFormPage from './InvoiceIntervalFormPage'
import { findAccessLevel } from '../../modules/accessRoute'

export class InvoiceIntervalPage extends React.Component {
  handleSubmit = () => {
    this.props.history.push(`/invoiceInterval`)
  }

  table = () => <InvoiceIntervalTable path="/invoiceInterval" />

  formPage = ({
    match: {
      params: { id },
    },
  }) => <InvoiceIntervalFormPage id={id} onSubmit={this.handleSubmit} />

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/invoiceInterval"
          component={this.table}
          ifAnyGranted={findAccessLevel('/invoiceInterval')}
        />

        <PrivateRoute
          path="/invoiceInterval/new"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/invoiceInterval')}
        />

        <PrivateRoute
          path="/invoiceInterval/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/invoiceInterval')}
        />
      </div>
    )
  }
}
export default withRouter(InvoiceIntervalPage)
