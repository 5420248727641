import React from 'react'
import { reduxForm } from 'redux-form'
import { Button, Col } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

import { TextField, Toolbar } from '../form'
import { required, validateWith } from '../form/validator'
import ClassToServiceMappingField from './ClassToServiceMappingField'

const validate = values => {
  const errors = {}
  errors.code = validateWith(values.code, [required])
  errors.name = validateWith(values.name, [required])
  errors.classToServiceMapping = (values.classToServiceMapping || []).map(
    c => ({
      privilegeClass: validateWith(c.privilegeClass, [required]),
      meta: {
        serviceMappingId: validateWith(
          c.meta ? c.meta.serviceMappingId : undefined,
          [required],
        ),
      },
    }),
  )
  return errors
}

const CardForm = ({ initialValues, makeClassToServiceMapping }) => {
  const { form, fields } = I18n.t('cardPage')
  return (
    <div className="container">
      <Toolbar title={initialValues._id ? form.update : form.new}>
        <Button className="btn-primary" type="submit">
          Submit
        </Button>
      </Toolbar>
      <Col sm={4}>
        <TextField name="code" label={fields.code} />
      </Col>
      <Col sm={8}>
        <TextField name="name" label={fields.name} />
      </Col>
      <Col sm={12}>
        <ClassToServiceMappingField
          name="classToServiceMapping"
          label={fields.classToServiceMapping}
          makeNewItem={makeClassToServiceMapping}
        />
      </Col>
    </div>
  )
}

export default reduxForm({
  validate,
})(CardForm)
