import React from 'react'
import { connect } from 'react-redux'
import { defaultTo } from 'lodash'

import { fetchCards, clearCard } from '../../actions/remote_api'
import SearchSelect from '../../modules/select/SearchSelect'

class CardSearchSelect extends React.Component {
  render() {
    return (
      <SearchSelect
        {...this.props}
        options={this.props.options}
        createQuery={defaultTo(this.props.createQuery, createQuery)}
        createLabel={createLabel}
        defaultValueQuery={defaultValueQuery}
        selectId="cardOptions"
      />
    )
  }
}

const createQuery = searchText => ({
  q: searchText ? searchText.toLowerCase() : undefined,
})

const defaultValueQuery = initialId => ({
  _id: initialId,
})

const createLabel = card => `${card.name} (${card.code})`

export default connect(null, {
  fetchOptions: fetchCards,
  clearOptions: clearCard,
})(CardSearchSelect)
