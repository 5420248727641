import React from 'react'
import { Button } from 'react-bootstrap'
import { compose, withStateHandlers, withProps, withHandlers } from 'recompose'
import { isNil } from 'lodash'
import { withRouter } from 'react-router-dom'
import SelectPricingContractTemplateModal from './SelectPricingContractTemplateModal'

const AddFromTemplateButton = ({
  children,
  isShow,
  handleClose,
  handleDone,
  handleShow,
  pricingProductId,
  onChange,
  isValid,
  contractorType,
}) => (
  <Button onClick={handleShow}>
    {children}
    <SelectPricingContractTemplateModal
      isShow={isShow}
      handleClose={handleClose}
      handleDone={handleDone}
      pricingProductId={pricingProductId}
      onChange={onChange}
      isValid={isValid}
      contractorType={contractorType}
    />
  </Button>
)

export default compose(
  withRouter,
  withStateHandlers(
    () => ({ pricingProductId: undefined, pricingProduct: undefined }),
    {
      onChange: () => (pricingProductId, pricingProduct) => {
        return { pricingProductId, pricingProduct }
      },
    },
  ),
  withStateHandlers(() => ({ isShow: false }), {
    toggleHide: () => () => ({ isShow: false }),
    toggleShow: () => () => ({ isShow: true }),
  }),
  withHandlers({
    handleClose: ({ toggleHide }) => () => toggleHide(),
    handleDone: ({ toggleHide, history, pricingProduct }) => () => {
      history.push(`/pricingContract/${pricingProduct.contractorType}/new`, {
        pricingContractTemplateId: pricingProduct._id,
      })
      toggleHide()
    },
    handleShow: ({ toggleShow }) => () => toggleShow(),
  }),
  withProps(({ pricingProductId }) => ({
    isValid: !isNil(pricingProductId),
  })),
)(AddFromTemplateButton)
