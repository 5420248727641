import React from 'react'
import { renderFieldGroup } from '../form/index'
import { Field } from 'redux-form'
import InvoiceProfileOption from './InvoiceProfileOption'

export const InvoiceProfileOptionFieldGroup = props => (
  <Field
    {...props}
    component={renderFieldGroup}
    inputField={InvoiceProfileOption}
  />
)
