import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { TextField, DateTimeField, renderFieldGroup, Toolbar, ImageUploadField } from '../form'
import { required, date, email, validateWith } from '../form/validator'
import { Col, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'

import { I18n } from 'react-redux-i18n'

const validate = values => {
  const errors = {}
  errors.code = validateWith(values.code, [required])
  errors.firstName = validateWith(values.firstName, [required])
  errors.lastName = validateWith(values.lastName, [required])
  errors.gender = validateWith(values.gender, [required])
  errors.birthDate = validateWith(values.birthDate, [required, date])
  errors.phone1 = validateWith(values.phone1, [required])
  errors.email = validateWith(values.email, [required, email])
  return errors
}

const StaffForm = ({ initialValues, photoUploadURL }) => {
  const { form, fields } = I18n.t('staffPage')
  const { display: dateDisplay } = I18n.t('date')
  return (
    <div className="container">
      <Toolbar title={initialValues._id ? form.update : form.new}>
        <Button className="btn-primary" type="submit">Submit</Button>
      </Toolbar>
      <Col sm={4}>
        <TextField name="code" label={fields.code} />
      </Col>
      <Col sm={4}>
        <TextField name="firstName" label={fields.firstName} />
      </Col>
      <Col sm={4}>
        <TextField name="lastName" label={fields.lastName} />
      </Col>
      <Col sm={4}>
        <GenderField name="gender" label={fields.gender} />
      </Col>
      <Col sm={4}>
        <DateTimeField name="birthDate" label={fields.birthDate} dateFormat={dateDisplay} timeFormat={false} />
      </Col>
      <Col sm={4}>
        <TextField name="email" label={fields.email} />
      </Col>
      <Col sm={4}>
        <TextField name="phone1" label={fields.phone1} />
      </Col>
      <Col sm={4}>
        <TextField name="phone2" label={fields.phone2} />
      </Col>
      <Col sm={4}>
        <TextField name="telegramUsername" label={fields.telegramUsername} />
      </Col>
      <Col sm={12}>
        {photoUploadURL ?
          <ImageUploadField name="photo" label={fields.photo}
            width={350} uploadURL={photoUploadURL} emptyLabel={fields.photoUploadEmpty} /> :
          fields.photoUploadInfo
        }
      </Col>
    </div>
  )
}

const GenderField = (props) => (
  <Field {...props} component={renderFieldGroup} inputField={GenderRadioGroup} />
)

const GenderRadioGroup = ({ value, onChange }) => {
  const { fields } = I18n.t('staffPage')
  return (
    <div>
      <ToggleButtonGroup type="radio" name="gender" defaultValue="male" value={value} onChange={onChange}>
        <ToggleButton value="male">{fields.male}</ToggleButton>
        <ToggleButton value="female">{fields.female}</ToggleButton>
      </ToggleButtonGroup>
    </div>
  )
}

export default reduxForm({
  validate
})(StaffForm)