import React from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import { displayPricingProductType } from '../../../services/pricingProduct'
import SteppedPricingProductPriceTable from './SteppedPricingProductPriceTable'
import SteppedPricingProductResetCountingIntervalSelectField from './SteppedPricingProductResetCountingIntervalSelectField'

export default ({
  isShow,
  handleClose,
  handleDone,
  info,
  pricingProduct,
  onChange,
  onBlur,
  formKeyPath,
}) => (
  <Modal show={isShow} bsSize="lg">
    <Modal.Body>
      <Row>
        <Col md={9}>
          <dl>
            <dt>
              <Translate value="pricingContractPage.fields.product" />
            </dt>
            <dd>{pricingProduct.product.name}</dd>
          </dl>
        </Col>
        <Col md={3}>
          <dl>
            <dt>
              <Translate value="pricingContractPage.fields.pricingProduct.type" />
            </dt>
            <dd>{displayPricingProductType(pricingProduct.type)}</dd>
          </dl>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <dl>
            <dt>
              <Translate value="pricingContractPage.steppedQuotaResetMessage" />
            </dt>
            <dd>
              <SteppedPricingProductResetCountingIntervalSelectField
                name={`${formKeyPath}.resetCountingInterval`}
              />
            </dd>
          </dl>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <SteppedPricingProductPriceTable
            steps={info.steps}
            onChange={onChange}
            onBlur={onBlur}
            formKeyPath={formKeyPath}
          />
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle="default" onClick={handleClose}>
        <Translate value="action.cancel" />
      </Button>
      <Button bsStyle="primary" onClick={handleDone}>
        <Translate value="action.done" />
      </Button>
    </Modal.Footer>
  </Modal>
)
