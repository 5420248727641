import React from "react";
import ReservationReport from "./BetaReservationReport";
import { requestReport } from "../../api";
import moment from "moment";
import { handleReportRequest } from "../../modules/report";
import { rateLimit } from "../../utils/rate-limit";

export default class ReservationReportContainer extends React.Component {
  state = {
    startDate: moment(),
    endDate: moment(),
    status: undefined,
    isLoading: false,
  };

  reportName = "/api/reports/queue/reservationReportBeta";

  createQuery = function(type) {
    return {
      type,
      startDate: this.state.startDate.toISOString(),
      endDate: this.state.endDate.toISOString(),
      status: this.state.status,
      stationId: this.state.stationId,
      agentId: this.state.agentId,
    };
  };

  handleDownloadPDF = () => {
    rateLimit(async () =>
      await handleReportRequest(
        requestReport(this.reportName, this.createQuery("pdf"))
      ),
      3000,
      (isLoading) => {
        this.setState(state => ({
          ...state,
          isLoading,
        }))
      }
    )
  };

  handleDownloadCSV = () => {
    rateLimit(async () =>
      await handleReportRequest(
        requestReport(this.reportName, this.createQuery("csv"))
      ),
      3000,
      (isLoading) => {
        this.setState(state => ({
          ...state,
          isLoading,
        }))
      }
    )
  };

  handleViewWebClick = () => {
    window.open(this.downloadHTMLLink(), '_blank')
  }

  downloadHTMLLink = () => {
    const url = new URL('/report/web', window.location.href)
    const query = this.createQuery()
    url.searchParams.set('name', 'reservationReportBeta')
    for (const key of Object.keys(query)) {
      if (query[key])
        url.searchParams.set(key, query[key])
    }
    return url.href
  }


  handleFilterChange = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  statusOptions = [
    { label: "COMPLETED", value: "completed" },
    { label: "NOSHOW", value: "noShow" },
    { label: "CANCELED", value: "canceled" },
  ];

  isDownloadable = () => {
    if (
      this.state.isLoading ||
      !this.state.startDate ||
      typeof this.state.startDate !== "object" ||
      !this.state.endDate ||
      typeof this.state.endDate !== "object"
    )
      return false;

    return this.state.startDate
      .startOf("day")
      .isBefore(this.state.endDate.endOf("day"));
  };

  render() {
    return (
      <ReservationReport
        onFilterChange={this.handleFilterChange}
        isDownloadable={this.isDownloadable()}
        onDownloadPDF={this.handleDownloadPDF}
        onDownloadCSV={this.handleDownloadCSV}
        onViewWebClick={this.handleViewWebClick}
        filter={this.state}
        startDateValid={this.startDateValid}
        endDateValid={this.endDateValid}
        statusOptions={this.statusOptions}
      />
    );
  }
}
