import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import ProductTable from './ProductTable'
import ProductFormPage from './ProductFormPage'
import { findAccessLevel } from '../../modules/accessRoute'

export class ProductPage extends React.Component {
  handleSubmit = () => {
    this.props.history.push(`/product`)
  }

  table = () => <ProductTable path="/product" />

  formPage = ({
    match: {
      params: { id },
    },
  }) => <ProductFormPage id={id} onSubmit={this.handleSubmit} />

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/product"
          component={this.table}
          ifAnyGranted={findAccessLevel('/product')}
        />

        <PrivateRoute
          path="/product/new"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/product')}
        />

        <PrivateRoute
          path="/product/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/product')}
        />
      </div>
    )
  }
}
export default withRouter(ProductPage)
