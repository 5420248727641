import React from 'react'
import { reduxForm } from 'redux-form'
import { Toolbar, TextField, TextAreaField } from '../form'
import { required, validateWith } from '../form/validator'
import { Button, Row, Col } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

import StatusBadge from '../commons/StatusBadge'
import { InvoiceProfileOptionFieldGroup } from './InvoiceProfileOptionFieldGroup'

const validate = values => {
  if (!values) return null

  return {
    name: validateWith(values.name, [required]),
    taxId: validateWith(values.taxId, [required]),
    address: validateWith(values.address, [required]),
    creditDayCount: validateWith(values.creditDayCount, [required]),
    invoiceProfile: validateWith(values.invoiceProfile, [required]),
  }
}

const DebtorForm = ({ initialValues, formValues, ...rest }) => {
  if (!formValues || !formValues.debtorType) return null
  const { fields } = I18n.t('debtorPage')
  return (
    <div className="container">
      <Toolbar customTitle={<DebtorFormTitle debtor={formValues} />}>
        <Button className="btn-primary" type="submit">
          Submit
        </Button>
      </Toolbar>

      <Row>
        <Col md={6}>
          <TextField label={fields.name} name="name" />
        </Col>
        <Col sm={6} md={3}>
          <TextField label={fields.taxId} name="taxId" />
        </Col>
        <Col sm={6} md={3}>
          <TextField
            label={fields.creditDayCount}
            name="creditDayCount"
            type="number"
            min="0"
            step="1"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={10}>
          <TextAreaField label={fields.address} name="address" />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <InvoiceProfileOptionFieldGroup
            label={fields.invoiceProfile}
            name="invoiceProfile"
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TextField
            label={fields.emails}
            name="emails"
            placeholder={I18n.t('emailPlaceholder')}
          />
        </Col>
      </Row>
    </div>
  )
}

const DebtorFormTitle = ({ debtor }) => {
  const { form, fields } = I18n.t('debtorPage')
  const titleTemplate = debtor._id ? form.update : form.new
  const contractorTitle = fields.debtorType[debtor.debtorType]
  const title = titleTemplate.replace('{0}', contractorTitle)
  return (
    <span>
      {title} {debtor.status && <StatusBadge>{debtor.status}</StatusBadge>}
    </span>
  )
}

export default reduxForm({
  validate,
})(DebtorForm)
