import React from 'react'
import { Field } from 'redux-form'
import SteppedPricingProductResetCountingIntervalSelect from './SteppedPricingProductResetCountingIntervalSelect'

const SteppedPricingProductResetCountingIntervalSelectField = props => (
  <Field
    {...props}
    component={({ input }) => (
      <SteppedPricingProductResetCountingIntervalSelect {...input} />
    )}
  />
)

export default SteppedPricingProductResetCountingIntervalSelectField
