import React from 'react'
import {
  FormGroup,
  FormControl,
  HelpBlock,
} from 'react-bootstrap';
import styledComponents from 'styled-components';
import {
  minusCircled,
} from 'react-icons-kit/ionicons'
import { ButtonIcon, ButtonIconIcon } from './ButtonIcon';

export default ({
  value,
  onChange,
  error,
  onRemoveClick,
  textAreaProps,
}) => {
  return (
    <FormGroup
      validationState={getValidationState(error)}>
      <RowLayout>
        <ActionsLayout>
          <ButtonIcon
            className="btn btn-danger"
            onClick={onRemoveClick}>
            <ButtonIconIcon
              size={20}
              icon={minusCircled}
              isSingle={true} />
          </ButtonIcon>
        </ActionsLayout>
        <FormControl
          type="text"
          placeholder="Enter text"
          {...textAreaProps}
          componentClass="textarea"
          value={value}
          onChange={onChange}
        />
        <FormControl.Feedback />
      </RowLayout>
      <HelpBlockLayout>
        <HelpBlock>{error}</HelpBlock>
      </HelpBlockLayout>
    </FormGroup>
  )
}

const getValidationState = error => (
  error ? "error" : null
)

const RowLayout = styledComponents.div`
  display: flex;
`

const ActionsLayout = styledComponents.div`
  min-width: 50px;
  margin-right: 15px;
`

const HelpBlockLayout = styledComponents.div `
  margin-left: 75px;
`