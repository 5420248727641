import React from 'react'
import { Row, Col } from 'react-bootstrap'

const VoucherBookFormLayout = ({ renderLeftPanel, renderRightPanel }) => (
  <Row>
    <Col md={6}>{renderLeftPanel()}</Col>
    <Col md={6}>{renderRightPanel()}</Col>
  </Row>
)

export default VoucherBookFormLayout
