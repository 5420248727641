import React from 'react'
import AlertModal from './AlertModal'
import { hideAlert, triggerAction } from '../../actions/alert'
import { connect } from 'react-redux'

const AlertModalContainer = props => (
  <AlertModal {...props} />
)

export default connect(
  ({ alert: { isShowAlert, info } }) => ({ isShowAlert, info }),
  { hideAlert, triggerAction }
)(AlertModalContainer)