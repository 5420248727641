import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import KioskReservationTableContainer from './KioskReservationTableContainer'
import ReservationFormPage from './ReservationFormPage'
import AssignJobFormPage from './AssignJobFormPage'
import { findAccessLevel } from '../../modules/accessRoute'

export class KioskReservationPage extends React.Component {
  handleSubmit = () => {
    this.props.history.push(`/reservation-kiosk`)
  }

  table = () => <KioskReservationTableContainer path="/reservation-kiosk" />

  formPage = ({
    match: {
      params: { id },
    },
  }) => <ReservationFormPage id={id} onSubmit={this.handleSubmit} />
  assignJobPage = ({
    match: {
      params: { id },
    },
  }) => <AssignJobFormPage id={id} onSubmit={this.handleSubmit} />

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/reservation-kiosk"
          component={this.table}
          ifAnyGranted={findAccessLevel('/reservation-kiosk')}
        />

        <PrivateRoute
          path="/reservation-kiosk/new"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/reservation-kiosk')}
        />

        <PrivateRoute
          path="/reservation-kiosk/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/reservation-kiosk')}
        />

        <PrivateRoute
          path="/reservation-kiosk/jobAssignments/:id"
          component={this.assignJobPage}
          ifAnyGranted={findAccessLevel('/reservation-kiosk')}
        />
      </div>
    )
  }
}
export default withRouter(KioskReservationPage)
