import React from 'react'
import { reduxForm } from 'redux-form'
import { TextField, Toolbar, ImageUploadField } from '../form'
import { required, validateWith } from '../form/validator'
import { Button, Col } from 'react-bootstrap'

import { I18n } from 'react-redux-i18n'
import ServiceSearchSelectField from '../service/ServiceSearchSelectField'

const validate = values => {
  const errors = {}
  errors.code = validateWith(values.code, [required])
  errors.name = validateWith(values.name, [required])
  errors.service = validateWith(values.service, [required])
  return errors
}

const StationForm = ({ initialValues, imageUploadURL }) => {
  const { form, fields } = I18n.t('stationPage')
  return (
    <div className="container">
      <Toolbar title={initialValues._id ? form.update : form.new}>
        <Button className="btn-primary" type="submit">
          Submit
        </Button>
      </Toolbar>
      <Col sm={4}>
        <TextField name="code" label={fields.code} />
      </Col>
      <Col sm={8}>
        <TextField name="name" label={fields.name} />
      </Col>
      <Col sm={8}>
        <ServiceSearchSelectField name="service" label={fields.service} />
      </Col>
      <Col sm={4}>
        <TextField
          type="number"
          min={0}
          step={1}
          name="capacity"
          label={fields.capacity}
        />
      </Col>
      <Col sm={12}>
        {imageUploadURL ?
          <ImageUploadField name="image" label={fields.image}
            width={350} uploadURL={imageUploadURL} emptyLabel={fields.imageUploadEmpty} /> :
          fields.imageUploadInfo
        }
      </Col>
    </div>
  )
}

export default reduxForm({
  validate,
})(StationForm)
