import {
  compose,
  withHandlers,
  withStateHandlers,
  lifecycle,
} from 'recompose'
import { connect } from 'react-redux'
import { get, debounce } from 'lodash'
import { createURL } from '../../actions/remote_api_utils'
import { escape } from '../../utils/regex'

const fetchProductItems = ({ searchText } = {}) => ({
  type: 'FETCH_PRODUCT_LIST',
  meta: { fetch_id: 'productItem' },
  $payload: {
    url: createURL('/api/products', {
      name_like: searchText ? escape(searchText) : undefined,
      limit: 100
    }),
  },
})

const withSearchProduct = compose(
  connect(
    state => ({
      productItems: get(state, 'fetch.productItem.list'),
      isLoading: get(state, 'fetch.productItem.isLoading.list', false),
    }),
    {
      fetchProductItems,
    },
  ),
  withHandlers({
    searchProductItems: ({ fetchProductItems }) =>
      debounce(searchText => {
        fetchProductItems({ searchText })
      }, 500),
  }),
  withStateHandlers(
    {
      searchText: '',
    },
    {
      onSearchTextChange: (state, { searchProductItems }) => e => {
        searchProductItems(e.target.value)
        return {
          searchText: e.target.value,
        }
      },
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchProductItems()
    },
  }),
)

export default withSearchProduct
