import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import styledComponent from 'styled-components'
import Icon from 'react-icons-kit'

class DetailPopup extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (
      this.props.isOpen !== nextProps.isOpen &&
      nextProps.isOpen &&
      nextProps.detailId
    ) {
      this.props.fetchInstance(nextProps.detailId)
    }
  }

  renderModalBody() {
    const { isOpen, detailId, instance, detailComponent: Detail } = this.props
    if (isOpen && instance && detailId === instance._id) {
      return <Detail instance={instance} />
    }
    return null
  }

  render() {
    const {
      isOpen,
      onClose,
      instance,
      options,
      rightBarButtonItem: RightBarButtonItem,
      onRefetchStart,
    } = this.props

    return (
      <Modal
        show={isOpen}
        onHide={onClose}
        backdrop={false}
        dialogClassName={options.customClass ? options.customClass : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Header>{options.header}</Header>
            {RightBarButtonItem && (
              <RightBarButtonItemPanel>
                <RightBarButtonItem
                  instance={instance}
                  onRefetchStart={onRefetchStart}
                  onHide={onClose}
                />
              </RightBarButtonItemPanel>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderModalBody()}</Modal.Body>
      </Modal>
    )
  }
}

export const withDetailPopup = (
  component,
  getOptions,
  rightBarButtonItem,
) => props => (
  <DetailPopup
    {...props}
    options={getOptions()}
    detailComponent={component}
    rightBarButtonItem={rightBarButtonItem}
  />
)

const Header = styledComponent.span`
  margin: 8px 16px;
  font-size: 28px;
`

const RightBarButtonItemPanel = styledComponent.div.attrs({
  className: 'pull-right',
})`
  margin-right: 16px;
`

export const RightBarButtonHolder = styledComponent.div`
  > * {
    margin: 0 4px;
  }
`

export const BtnInBar = styledComponent(Button)`
  display: inline-flex;
  vertical-align: middle;
`

export const IconInBtnInBar = styledComponent(Icon)`
  margin-top: 2px;
  margin-right: 2px;
`
