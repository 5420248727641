import React from 'react'
import { RadioGroup, Radio } from '../form/RadioGroup'

const choices = [
  { value: 'all', title: 'All' },
  { value: 'card', title: 'Card' },
  { value: 'cash', title: 'Cash' },
  { value: 'creditCard', title: 'Credit Card' },
  { value: 'others', title: 'Others' },
]

const WalkinRadioGroup = ({ value, onChange }) => (
  <RadioGroup>
    {choices.map(choice => (
      <Radio key={choice.value}>
        <input
          type="radio"
          value={choice.value}
          checked={choice.value === value}
          onClick={() => onChange(choice.value)}
        />
        {choice.title}
      </Radio>
    ))}
  </RadioGroup>
)

export default WalkinRadioGroup
