import React from 'react'
import { DateTimeField } from './index'
import { I18n } from 'react-redux-i18n'

const FormattedDateTimeField = ({ type, ...props }) => {
  const format = getFormat(type)
  return <DateTimeField {...props} {...format} />
}

const getFormat = type => {
  const dateFormat = I18n.t('date.display')
  const timeFormat = I18n.t('time.display')
  switch (type) {
    case 'dateTime':
      return {
        dateFormat,
        timeFormat,
      }
    case 'date':
      return {
        dateFormat,
        timeFormat: false,
      }
    case 'time':
      return {
        dateFormat: false,
        timeFormat,
      }
    default:
      throw new Error('no handle for type: ' + type)
  }
}

export default FormattedDateTimeField
