import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { debounce } from 'lodash'

import { fetchCards, deleteCard } from '../../actions/remote_api'
import {
  withTableConfigs,
  withDeleteModal,
  renderClassToServiceMapping,
} from '../table'
import { withDetailPopupProvider } from '../detailPopup'
import CardDetailPopup from './CardDetailPopup'
import CardTableFilterPanel from './CardTableFilterPanel'
import Loader from '../Loader'
import TableLoading from '../table/TableLoading'
import { escape } from '../../utils/regex'

class CardTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = filter => {
    this.props.fetchCards({
      page: filter.currentPage,
      limit: filter.sizePerPage,
      sort: filter.sort,
      order: filter.order,
      ...this.createQueryFilter(filter.query),
    })
  }

  createQueryFilter = filter => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.code) queryfilter.code_like = escape(filter.code)
    if (filter.name) queryfilter.name_like = escape(filter.name)

    return queryfilter
  }

  render() {
    return (
      <CardTable
        {...this.props}
        customDefaultSizePerPage={10}
        onDataChange={this.handleDataChange}
      />
    )
  }
}

export default connect(
  ({ fetch }) => ({
    isLoading: get(fetch, 'card.isLoading.list', false),
    instances: get(fetch, 'card.list', []),
    totalCount: get(fetch, 'card.totalCount'),
  }),
  { fetchCards, deleteInstance: deleteCard },
)(CardTableContainer)

let CardTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
  isLoading,
}) => {
  const { fields, table } = I18n.t('cardPage')
  return (
    <div>
      <h1 className="page-header">{table.header}</h1>
      {renderFilterPanel}
      {renderToolbar}
      <Loader loading={<TableLoading />} isLoading={isLoading}>
        <BootstrapTable {...defaultSettings} options={defaultOptions}>
          <TableHeaderColumn
            width="10%"
            dataField="code"
            dataFormat={detailLink}
            dataSort
          >
            {fields.code}
          </TableHeaderColumn>
          <TableHeaderColumn width="25%" dataField="name" dataSort>
            {fields.name}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="40%"
            dataField="classToServiceMapping"
            dataFormat={renderClassToServiceMapping}
          >
            {fields.classToServiceMapping}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="edit"
            editable={false}
            dataFormat={editButton}
            width="100px"
            dataAlign="center"
            hiddenOnInsert
          />
        </BootstrapTable>
      </Loader>
    </div>
  )
}

CardTable = withTableConfigs('card')(CardTable, CardTableFilterPanel)
CardTable = withDetailPopupProvider(CardTable, CardDetailPopup)
CardTable = withDeleteModal(CardTable)
