import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { withDetailPopup } from '../detailPopup'
import { fetchDebtor } from '../../actions/remote_api'

const DebtorDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('debtorPage')
  if (!instance) return null

  return (
    <form className="form-horizontal">
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.name}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance.name}</p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.taxId}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance.taxId}</p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.address}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance.address}</p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">
          {fields.invoiceProfile}
        </label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance.invoiceProfile}
          </p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">
          {fields.creditDayCount}
        </label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance.creditDayCount}
          </p>
        </div>
      </div>
    </form>
  )
}

let DebtorDetailPopupContainer = withDetailPopup(DebtorDetailPopup, () => ({
  header: I18n.t('debtorPage.detail.header'),
}))

export default connect(
  state => ({
    instance: get(state, 'fetch.debtor.single'),
  }),
  (dispatch, props) => ({
    fetchInstance: query => {
      return dispatch(fetchDebtor(query, { fetchId: 'debtor' }))
    },
  }),
)(DebtorDetailPopupContainer)
