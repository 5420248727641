import { createCRUDActions} from './remote_api_utils'

const reservationActions = createCRUDActions('reservation', '/api/reservations')

export const fetchReservations = reservationActions.list
export const fetchReservation = reservationActions.get
export const deleteReservation = reservationActions.delete
export const createReservation = reservationActions.create
export const updateReservation = reservationActions.update
export const clearReservation = reservationActions.clear

export const confirmReservation = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/reservations/${id}/confirm`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: "PUT"
    },
    onResponse
  }
})

export const revertConfirmReservation = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/reservations/${id}/revertConfirm`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: "PUT"
    },
    onResponse
  }
})

export const revertFinalStatusReservation = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/reservations/${id}/revertFinalStatus`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: "PUT"
    },
    onResponse
  }
})

export const completeReservation = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/reservations/${id}/complete`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: "PUT"
    },
    onResponse
  }
})

export const noShowReservation = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/reservations/${id}/noShow`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: "PUT"
    },
    onResponse
  }
})

export const missedCaseReservation = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/reservations/${id}/missedCase`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: "PUT"
    },
    onResponse
  }
})

export const cancelReservation = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/reservations/${id}/cancel`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: "PUT"
    },
    onResponse
  }
})

export const refundReservation = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/reservations/${id}/refund`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: "PUT"
    },
    onResponse
  }
})

export const reconfirmReservation = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/reservations/${id}/reconfirm`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: "PUT"
    },
    onResponse
  }
})

