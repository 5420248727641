import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import Switch from 'react-bootstrap-switch'
import { downloadReport } from '../../api'

export class PrintInvoiceReportModal extends React.Component {
  state = {
    isShow: false,
    addingSignature: true,
  }

  showModal = () => this.setState({ isShow: true })
  hideModal = () => this.setState({ isShow: false })

  handleAddingSignatureChange = (elm, state) =>
    this.setState({ addingSignature: state })

  printReport = () => {
    downloadReport('/api/reports/invoiceReport', {
      _id: this.props.invoiceId,
      addingSignature: this.state.addingSignature,
    })
    this.hideModal()
  }

  render() {
    const modal = (
      <Modal show={this.state.isShow} onHide={this.hideModal} bsSize="sm">
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t('invoicePage.printInvoiceReportModal.header')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Switch
            value={this.state.addingSignature}
            onChange={this.handleAddingSignatureChange}
            // state={initialValue}
            labelText={I18n.t(
              'invoicePage.printInvoiceReportModal.addDigitalSignature',
            )}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.printReport} bsStyle="primary">
            {I18n.t('action.print')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
    return this.props.children({ showModal: this.showModal, modal })
  }
}
