import { BigNumber } from 'bignumber.js'

export const defaultDueDayCount = 30

export const financialValue = ({
  price,
  taxPercentage,
  isTaxIncluded,
  quantity = 1,
}) => {
  const priceNumber = BigNumber(price).multipliedBy(quantity)
  const taxInFraction = BigNumber(taxPercentage).dividedBy(100)
  if (!isTaxIncluded)
    return {
      amount: toFinanceNumber(priceNumber), // price
      tax: toFinanceNumber(priceNumber.multipliedBy(taxInFraction)), // price * tax / 100
      total: toFinanceNumber(priceNumber.multipliedBy(taxInFraction.plus(1))), // price * (1 + tax / 100)
    }
  else
    return {
      amount: toFinanceNumber(priceNumber.dividedBy(taxInFraction.plus(1))), // price / (1 + tax /100)
      tax: toFinanceNumber(
        priceNumber
          .multipliedBy(taxInFraction)
          .dividedBy(taxInFraction.plus(1)),
      ), // price * (tax / 100) / (1 + tax /100)
      total: toFinanceNumber(priceNumber), // price
    }
}

const toFinanceNumber = bigNumber => bigNumber.toNumber()

export const toRoundedFinanceNumber = number =>
  BigNumber(number)
    .dp(2, BigNumber.ROUND_HALF_UP)
    .toNumber()
