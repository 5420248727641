import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { debounce, get } from 'lodash'

import { fetchProducts, deleteProduct } from '../../actions/remote_api'
import { withDetailPopupProvider } from '../detailPopup'
import { withTableConfigs, withDeleteModal } from '../table'
import ProductDetailPopup from './ProductDetailPopup'
import ProductTableFilterPanel from './ProductTableFilterPanel'
import { escape } from '../../utils/regex'

class ProductTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = filter => {
    this.props.fetchProducts({
      page: filter.currentPage,
      limit: filter.sizePerPage,
      sort: filter.sort,
      order: filter.order,
      ...this.createQueryFilter(filter.query),
    })
  }

  createQueryFilter = filter => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.name) queryfilter.name_like = escape(filter.name)

    return queryfilter
  }

  render() {
    return <ProductTable {...this.props} onDataChange={this.handleDataChange} />
  }
}

export default connect(
  ({ fetch }) => ({
    instances: get(fetch, 'product.list', []),
    totalCount: get(fetch, 'product.totalCount'),
  }),
  { fetchProducts, deleteInstance: deleteProduct },
)(ProductTableContainer)

let ProductTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
}) => {
  const { fields, table } = I18n.t('productPage')
  return (
    <div>
      <h1 className="page-header">{table.header}</h1>
      {renderFilterPanel}
      {renderToolbar}
      <BootstrapTable {...defaultSettings} options={defaultOptions}>
        <TableHeaderColumn dataField="name" dataFormat={detailLink} dataSort>
          {fields.name}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="services" dataFormat={servicesFormat}>
          {fields.services}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="edit"
          editable={false}
          dataFormat={editButton}
          width="100px"
          dataAlign="center"
          hiddenOnInsert
        />
      </BootstrapTable>
    </div>
  )
}

const servicesFormat = services => (
  <dl className="dl-horizontal">
    {services &&
      services.map((s, index) => (
        <div key={index}>
          <dd>{s.displayName}</dd>
        </div>
      ))}
  </dl>
)

ProductTable = withTableConfigs('product')(
  ProductTable,
  ProductTableFilterPanel,
)
ProductTable = withDetailPopupProvider(ProductTable, ProductDetailPopup)
ProductTable = withDeleteModal(ProductTable)
