import moment from 'moment'
import _ from 'lodash'

export const required = value =>
  value === undefined || value === '' || value === null ? 'Required' : undefined

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const date = value =>
  value && !moment.isMoment(value) ? 'Invalid date/time' : undefined

export const isPositiveNumber = value => {
  if (!value) return undefined
  return _.toNumber(value) > 0 ? undefined : 'Must be positive number'
}

export const isPriceNumber = value => {
  if (!value) return undefined
  return _.toNumber(value) >= 0 ? undefined : 'Must be positive number'
}

export const isInteger = value => {
  if (!value) return undefined
  return _.isInteger(_.toNumber(value)) ? undefined : 'Must be integer'
}

export const validateWith = (value, validates) => {
  for (let i in validates) {
    const error = validates[i](value)
    if (error) return error
  }
  return undefined
}

export const textLimit = limit => value => {
  if (value === undefined) return undefined
  return value.length > limit
    ? `Text cannot be longer than ${limit}`
    : undefined
}

export default {
  required,
  email,
  validateWith,
  textLimit,
}
