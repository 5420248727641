import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import InvoiceFormPage from './InvoiceFormPage'
import {
  AllInvoiceTableContainer,
  DraftInvoiceTableContainer,
  PendingInvoiceTableContainer,
} from './InvoiceTableContainer'
import { findAccessLevel } from '../../modules/accessRoute'

export class InvoicePage extends React.Component {
  handleSubmit = () => {
    this.props.history.goBack()
  }

  table = () => <AllInvoiceTableContainer path="/invoice" />

  draftTable = () => <DraftInvoiceTableContainer path="/invoice" />

  pendingTable = () => <PendingInvoiceTableContainer path="/invoice" />

  formPage = ({
    match: {
      params: { id },
    },
  }) => <InvoiceFormPage id={id} onSubmit={this.handleSubmit} />

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/invoice/all"
          component={this.table}
          ifAnyGranted={findAccessLevel('/invoice')}
        />

        <PrivateRoute
          exact
          path="/invoice/draft"
          component={this.draftTable}
          ifAnyGranted={findAccessLevel('/invoice')}
        />

        <PrivateRoute
          exact
          path="/invoice/pending"
          component={this.pendingTable}
          ifAnyGranted={findAccessLevel('/invoice')}
        />

        <PrivateRoute
          path="/invoice/new"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/invoice')}
        />

        <PrivateRoute
          path="/invoice/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/invoice')}
        />
      </div>
    )
  }
}
export default withRouter(InvoicePage)
