import React from 'react'
import { Panel, Form, Col, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import Select from 'react-select'

export default ({ isShow, filter, onFilterChange, onClearFilter }) => {
  const fieldsLabel = I18n.t('invoiceIntervalPage.fields')
  return (
    <Panel collapsible expanded={isShow}>
      <Form horizontal>
        <Col md={4}>
          <Select
            value={filter.status}
            onChange={value => onFilterChange('status', value)}
            placeholder={fieldsLabel.status}
            simpleValue
            options={statusOptions}
          />
          <br />
        </Col>
        <Col md={4} />
        <Col md={4}>
          <Button className="pull-right" onClick={onClearFilter}>
            <span className="glyphicon glyphicon-trash" /> Clear Filter
          </Button>
        </Col>
      </Form>
    </Panel>
  )
}

const statusOptions = [
  { label: 'DRAFT', value: 'draft' },
  { label: 'PROCESSING', value: 'processing' },
  { label: 'COMPLETED', value: 'completed' },
]
