export default {
  runDev: process.env['NODE_ENV'] === 'development',
  invoice: {
    notifyOverdue: {
      after: 30,
      unitOfTime: 'days',
    },
  },
  invoiceInterval: {
    process: {
      enableRecoveryControlAfterProcessRunFor: {
        unitOfTime: 'minutes',
        value: 15,
      },
    },
  },
}
