import styled from 'styled-components'

export const RadioGroup = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 10px !important;
  }
`

export const Radio = styled.label`
  display: flex;

  input {
    margin: auto;
  }

  > * {
    margin-right: 7px !important;
  }
`
