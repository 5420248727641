import actionTypes from '../constants'

export const updatePricingProduct = priceIndex => ({
  type: actionTypes.UPDATE_STEP_PRICING_PRODUCT,
  priceIndex,
})

export const removeTrailingStepPricingProduct = priceIndex => ({
  type: actionTypes.REMOVE_TRAILING_STEP_PRICING_PRODUCT,
  priceIndex,
})

export const initialSteppedPricingProduct = (priceIndex, initialPrice) => ({
  type: actionTypes.INIT_STEP_PRICING_PRODUCT,
  priceIndex,
  initialPrice,
})
