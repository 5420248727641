import React from 'react'
import UserForm from './UserForm'
import { connect } from 'react-redux'
import {
  fetchUser,
  createUser,
  updateUser,
  clearUser,
} from '../../actions/remote_api'
import { submit, stopSubmit } from 'redux-form'

class UserFormPage extends React.Component {
  formId = 'user'
  state = { formData: { username: '' } }

  componentDidMount() {
    const { fetchUser, id, clearUser } = this.props
    clearUser()
    if (id !== undefined) {
      fetchUser(id)
    }
  }

  componentWillUnmount() {
    this.props.clearUser()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.user },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }
  }

  withTrimmed = user => ({
    ...user,
    username: user.username.trim(),
    password: user.password ? user.password.trim() : undefined,
    email: user.email ? user.email.trim() : undefined,
  })

  handleSubmit = formData => {
    const { createUser, updateUser, id } = this.props
    const user = this.withTrimmed(formData)
    if (id === undefined) createUser(user, { onResponse: this.handleResponse })
    else updateUser(user, { onResponse: this.handleResponse })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit()
  }

  submit = e => {
    this.props.submit(this.formId)
    e.preventDefault()
  }

  signatureUploadURL = () =>
    this.state.formData._id
      ? `/api/users/${this.state.formData._id}/signature`
      : undefined

  render() {
    return (
      <form onSubmit={this.submit}>
        <UserForm
          form={this.formId}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          signatureUploadURL={this.signatureUploadURL()}
          enableReinitialize
        />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { user } }) => ({
    user: user ? user.single : {},
    errors: user ? user.singleErrors : null,
  }),
  { createUser, updateUser, fetchUser, clearUser, submit, stopSubmit },
)(UserFormPage)
