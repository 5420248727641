import React from 'react'
import { Translate, I18n } from 'react-redux-i18n'
import { memoize, toNumber, isEmpty } from 'lodash'
import { TextField } from '../../form/index'

const inputToNumber = value =>
  !isEmpty(value) ? toNumber(value) : null

class SteppedPricingProductPriceTableStepItem extends React.Component {
  onInputChange = memoize((key, transform) => e => {
    this.props.onChange(key, e.target.value)
  })

  onInputBlur = memoize((key, transform) => e => {
    this.props.onBlur(key, e.target.value)
  })

  render() {
    const {
      step: { start, limit },
      seq,
      formKeyPath,
    } = this.props
    return (
      <tr>
        <td>#{seq}</td>
        <td>
          <TextField
            name={formKeyPath + '.limit'}
            type="number"
            parse={inputToNumber}
            didChange={this.onInputChange('limit')}
            onBlur={this.onInputBlur('limit')}
            placeholder={I18n.t(
              'pricingContractPage.pricingProductStepped.noLimitPlaceHolder',
            )}
            isFormGroupValidate
          />
        </td>
        <td>
          {start} -{' '}
          {limit ? (
            start + limit - 1
          ) : (
            <Translate value="pricingContractPage.pricingProduct.noLimit" />
          )}
        </td>
        <td className="text-right">
          <TextField
            name={formKeyPath + '.price'}
            parse={inputToNumber}
            className="text-right"
            type="number"
            isFormGroupValidate
          />
        </td>
        <td className="text-right">
          <TextField
            name={formKeyPath + '.addendumPrice'}
            parse={inputToNumber}
            className="text-right"
            type="number"
            isFormGroupValidate
          />
        </td>
      </tr>
    )
  }
}

export default SteppedPricingProductPriceTableStepItem
