import React from "react";
import AirlineReport from "./BetaAirlineReport";
import { requestReport } from "../../api";
import moment from "moment";
import { handleReportRequest } from "../../modules/report";
import { rateLimit } from "../../utils/rate-limit";

export default class AirlineReportContainer extends React.Component {
  state = {
    airlineId: null,
    stationId: null,
    service: null,
    startDate: moment(),
    endDate: moment(),
    flightNumber: "",
    isLoading: false,
  };

  reportName = "/api/reports/queue/airlineReportBeta";

  createQuery = function(type) {
    return {
      type,
      airlineId: this.state.airlineId,
      stationId: this.state.stationId,
      flightNumber: this.state.flightNumber,
      startDate: moment(this.state.startDate).toISOString(),
      endDate: moment(this.state.endDate).toISOString(),
      serviceId: this.state.service ? this.state.service._id : undefined,
      createFromKiosk: true,
    };
  };

  handleFilterChange = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  handleDownloadPDF = () => {
    rateLimit(async () =>
      await handleReportRequest(
        requestReport(this.reportName, this.createQuery("pdf"))
      ),
      3000,
      (isLoading) => {
        this.setState(state => ({
          ...state,
          isLoading,
        }))
      }
    )
  };

  handleDownloadCSV = () => {
    rateLimit(async () =>
      await handleReportRequest(
        requestReport(this.reportName, this.createQuery("csv"))
      ),
      3000,
      (isLoading) => {
        this.setState(state => ({
          ...state,
          isLoading,
        }))
      }
    )
  };

  handleViewWebClick = () => {
    window.open(this.downloadHTMLLink(), '_blank')
  }

  downloadHTMLLink = () => {
    const url = new URL('/report/web', window.location.href)
    const query = this.createQuery()
    url.searchParams.set('name', 'airlineReportBeta')
    for (const key of Object.keys(query)) {
      if (query[key])
        url.searchParams.set(key, query[key])
    }
    return url.href
  }

  isDownloadable = () => {
    if (
      this.state.isLoading ||
      !this.state.startDate ||
      typeof this.state.startDate !== "object" ||
      !this.state.endDate ||
      typeof this.state.endDate !== "object"
    )
      return false;

    return (
      this.state.startDate
        .startOf("day")
        .isBefore(this.state.endDate.endOf("day")) &&
      this.state.service !== null
    );
  };

  render() {
    return (
      <AirlineReport
        onFilterChange={this.handleFilterChange}
        onDownloadCSV={this.handleDownloadCSV}
        onDownloadPDF={this.handleDownloadPDF}
        onViewWebClick={this.handleViewWebClick}
        isDownloadable={this.isDownloadable()}
        filter={this.state}
      />
    );
  }
}
