export default {
  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',
  TRIGGER_ALERT_ACTION: 'TRIGGER_ALERT_ACTION',
  ALERT_ACTION_END: 'ALERT_ACTION_END',
  UPDATE_QUERY_FILTER: 'UPDATE_QUERY_FILTER',
  INIT_FILTER: 'INIT_FILTER',
  UPDATE_FILTER_PROPS: 'UPDATE_FILTER_PROPS',
  CLEAR_FILTER: 'CLEAR_FILTER',
  UPDATE_STEP_PRICING_PRODUCT: 'UPDATE_STEP_PRICING_PRODUCT',
  REMOVE_TRAILING_STEP_PRICING_PRODUCT: 'REMOVE_TRAILING_STEP_PRICING_PRODUCT',
  INIT_STEP_PRICING_PRODUCT: 'INIT_STEP_PRICING_PRODUCT',
}
