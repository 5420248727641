import React from 'react'
import { Col } from 'react-bootstrap'
import {
  TextField,
  TextAreaField,
  DateTimeField,
  SelectField,
} from '../form'
import { I18n } from 'react-redux-i18n'
import { reduxForm, Form } from 'redux-form'
import { required, date, validateWith } from '../form/validator'
import styled from 'styled-components'
import AirlinePrivilegeClassSearchSelectField from '../service/AirlinePrivilegeClassSearchSelectField'

const validate = values => {
  const errors = {}
  errors.customerFirstName = validateWith(values.customerFirstName, [required])
  errors.customerLastName = validateWith(values.customerLastName, [required])
  errors.flightNumber = validateWith(values.flightNumber, [required])
  errors.seatNumber = validateWith(values.seatNumber, [required])
  errors.status = validateWith(values.status, [required, finalStatusValidate])

  values.serviceInfo = values.serviceInfo || {}
  errors.serviceInfo = {
    inTime: validateWith(values.serviceInfo.inTime, [date]),
    outTime: validateWith(values.serviceInfo.outTime, [date]),
    numberOfGuest: validateWith(values.serviceInfo.numberOfGuest, [required]),
    luggageAmount: validateWith(values.serviceInfo.luggageAmount, [required]),
    handbagAmount: validateWith(values.serviceInfo.handbagAmount, [required]),
    confirmFlightTime: validateWith(values.serviceInfo.confirmFlightTime, [
      required,
      date,
    ]),
    finishDateTime: validateWith(values.serviceInfo.finishDateTime, [
      required,
      date,
    ]),
    boardingTime: validateWith(values.serviceInfo.boardingTime, [date]),
  }
  return errors
}

const ServiceFormView = ({ serviceTxn, handleSubmit, isCancel }) => {
  if (!serviceTxn) return renderEmptyState()

  const classForForm = () => (isCancel ? 'canceled-form' : '')
  // const { serviceInfo } = serviceTxn
  const i18n = I18n.t('serviceFormPage.form')
  const dateFormat = I18n.t('date.display')
  const timeFormat = I18n.t('time.display')
  return (
    <div>
      <Form onSubmit={handleSubmit} className={classForForm()}>
        <Col md={12}>
          <h3>
            <strong>Service:</strong> {serviceTxn.service.displayName} |
            <strong> Check-In Type:</strong>{' '}
            {serviceTxn.checkInType
              ? serviceTxn.checkInType.toUpperCase()
              : '-'}
          </h3>
          <hr />
        </Col>
        <Col md={3}>
          <TextField
            name="customerFirstName"
            label={i18n.customerFirstName}
            addStar={true}
            disabled={isCancel}
          />
        </Col>
        <Col md={3}>
          <TextField
            name="customerLastName"
            label={i18n.customerLastName}
            addStar={true}
            disabled={isCancel}
          />
        </Col>
        <Col md={3}>
          <SelectField
            label={i18n.customerSatisfactionRate}
            options={customerSatisfactionOptions}
            disabled={isCancel}
            name="serviceInfo.customerSatisfactionRate"
          />
        </Col>

        <Col md={3}>
          <SelectField
            label={i18n.status}
            options={statusOptions}
            disabled={isCancel}
            name="status"
            addStar={true}
          />
        </Col>

        <Col md={3}>
          <TextField
            name="flightNumber"
            label={i18n.flightNumber}
            addStar={true}
            disabled={isCancel}
          />
        </Col>
        <Col md={3}>
          <TextField
            name="seatNumber"
            label={i18n.seatNumber}
            addStar={true}
            disabled={isCancel}
          />
        </Col>
        <Col md={3}>
          <AirlinePrivilegeClassSearchSelectField
            name="travelClass"
            label={i18n.travelClass}
            disabled={isCancel}
            selectValue={privilegeClass => privilegeClass.name}
          />
        </Col>

        <Col md={3}>
          <DateTimeField
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            name="serviceInfo.confirmFlightTime"
            label={i18n.confirmFlightTime}
            addStar={true}
            inputProps={{ disabled: isCancel }}
          />
        </Col>
        {/* <Col md={6}>
          <TextField
            name="serviceInfo.numberOfGuest"
            type="number"
            step={1}
            label={i18n.numberOfGuest} />
        </Col> */}

        <Col md={2}>
          <TextField
            name="serviceInfo.guestPaxAdult"
            min={0}
            label={i18n.guestPaxAdultFull}
            type="number"
            step={1}
            disabled={isCancel}
          />
        </Col>
        <Col md={2}>
          <TextField
            name="serviceInfo.guestPaxChild"
            min={0}
            label={i18n.guestPaxChildFull}
            type="number"
            step={1}
            disabled={isCancel}
          />
        </Col>
        <Col md={2}>
          <TextField
            name="serviceInfo.guestPaxInfant"
            min={0}
            label={i18n.guestPaxInfantFull}
            type="number"
            step={1}
            disabled={isCancel}
          />
        </Col>

        <Col md={3}>
          <TextField
            name="serviceInfo.luggageAmount"
            type="number"
            min={0}
            step={1}
            label={i18n.baggageAmount}
            addStar={true}
            disabled={isCancel}
          />
        </Col>
        <Col md={3}>
          <TextField
            name="serviceInfo.handbagAmount"
            type="number"
            min={0}
            step={1}
            label={i18n.handbagAmount}
            addStar={true}
            disabled={isCancel}
          />
        </Col>
        <Col md={3}>
          <DateTimeField
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            name="serviceInfo.finishDateTime"
            inputProps={{ disabled: isCancel }}
            label={i18n.finishDateTime}
            addStar={true}
          />
        </Col>
        <Col md={3}>
          <DateTimeField
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            name="serviceInfo.boardingTime"
            label={i18n.boardingTime}
            inputProps={{ disabled: isCancel }}
          />
        </Col>
        <Col md={3}>
          <DateTimeField
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            name="serviceInfo.inTime"
            label={i18n.inTime}
            inputProps={{ disabled: isCancel }}
          />
        </Col>
        <Col md={3}>
          <DateTimeField
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            name="serviceInfo.outTime"
            label={i18n.outTime}
            inputProps={{ disabled: isCancel }}
          />
        </Col>
        
        <Col md={3}>
          <TextField
            name="reference1"
            label={i18n.reference1}
            disabled={isCancel}
          />
        </Col>

        <Col md={3}>
          <TextField
            name="reference2"
            label={i18n.reference2}
            disabled={isCancel}
          />
        </Col>

        
        <Col md={6}>
          <TextAreaField
            name="remark"
            label={i18n.remark}
            rows={3}
            disabled={isCancel}
          />
        </Col>
        <Col md={6}>
          <TextAreaField name="serviceInfo.note" label={i18n.note} rows={3} />
        </Col>
      </Form>

      <CancelTextDisplay isShow={isCancel}>CANCELED</CancelTextDisplay>
    </div>
  )
}

const CancelTextDisplay = styled.div`
  display: ${props => (props.isShow ? 'block' : 'none')};
  position: absolute;
  top: 44%;
  left: 46.5%;
  font-size: 40px;
  font-weight: bold;
`
const statusOptions = [
  { label: 'COMPLETED', value: 'completed' },
  { label: 'MISSED CASE', value: 'missedCase' },
  { label: 'NOSHOW', value: 'noShow' },
]

const customerSatisfactionOptions = [
  { label: 'EXCELLENT', value: 3 },
  { label: 'GOOD', value: 2 },
  { label: 'FAIR', value: 1 },
]

const finalStatus = statusOptions.map(options => options.value)
const finalStatusValidate = value =>
  finalStatus.some(s => s === value) ? undefined : 'Final Status is Required'

const renderEmptyState = () => (
  <div className="text-center">
    <h3>No transaction of service in your selected date.</h3>
  </div>
)

export default reduxForm({
  validate,
})(ServiceFormView)
