import immutable from 'object-path-immutable'
import { get, map, sumBy } from 'lodash'
import { actionTypes } from 'redux-form'
import {
  financialValue,
  toRoundedFinanceNumber,
} from '../modules/financialValue'
import { isMoment } from 'moment'

export default (state, action) => {
  switch (action.type) {
    case actionTypes.CHANGE: {
      const {
        meta: { field },
      } = action
      if (/items\[\d+\].+(price|quantity|isTaxIncluded)/.test(field)) {
        return updateItemsOnFieldsChange(state, field)
      } else if (field === 'taxPercentage') {
        return updateItemsOnTaxPercentageChange(state)
      } else if (field === 'discount') {
        return updateGrandTotalOnDiscountChange(state)
      } else if (/(billingDate|dueDayCount)/.test(field)) {
        return updateDueDate(state)
      } else if (field === 'dueDate') {
        return updateDueDayCount(state)
      }
      return state
    }
    default:
      return state
  }
}

function updateDueDayCount(state) {
  const { dueDate, billingDate } = state.values
  if (isMoment(dueDate) && isMoment(billingDate)) {
    const dueDayCount = dueDate.diff(billingDate, 'days')
    return immutable(state)
      .set('values.dueDayCount', dueDayCount)
      .value()
  }

  return state
}

function updateDueDate(state) {
  const { dueDayCount, billingDate } = state.values
  if (dueDayCount === undefined || !isMoment(billingDate)) return state

  const dueDate = billingDate.clone().add(dueDayCount, 'days')
  return immutable(state)
    .set('values.dueDate', dueDate)
    .value()
}

function updateGrandTotalOnDiscountChange(state) {
  const { items, discount } = state.values
  return immutable(state)
    .set('values.grandTotal', grandTotal(items, discount))
    .value()
}

function updateItemsOnFieldsChange(state, field) {
  // field should looks like 'item[2].price' or 'item[1].quantity'
  const itemIndex = Number(/\d+/.exec(field)[0])
  const itemPath = `values.items.${itemIndex}`

  const item = get(state, itemPath)
  const values = financialValue({
    quantity: Number(item.quantity),
    isTaxIncluded: item.isTaxIncluded,
    price: Number(item.price),
    taxPercentage: Number(state.values.taxPercentage),
  })
  const updatedItem = { ...item, ...values }
  const items = state.values.items.map((item, index) =>
    index === itemIndex ? updatedItem : item,
  )
  return updateItemsFinancialValues(state, items)
}

function updateItemsOnTaxPercentageChange(state) {
  const { taxPercentage, items } = state.values
  const updatedItems = map(items, item => ({
    ...item,
    ...financialValue({
      quantity: Number(item.quantity),
      isTaxIncluded: item.isTaxIncluded,
      price: Number(item.price),
      taxPercentage,
    }),
  }))
  return updateItemsFinancialValues(state, updatedItems)
}

function updateItemsFinancialValues(state, items) {
  return immutable(state)
    .set('values.items', items)
    .set('values.subTotal', subTotal(items))
    .set('values.tax', tax(items))
    .set('values.grandTotal', grandTotal(items, state.values.discount))
    .value()
}

const subTotal = items =>
  toRoundedFinanceNumber(sumBy(items, item => item.amount))
const tax = items => toRoundedFinanceNumber(sumBy(items, item => item.tax))
const grandTotal = (items, discount = 0) =>
  toRoundedFinanceNumber(sumBy(items, item => item.total)) - discount
