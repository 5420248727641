import React from 'react'
import ServiceFormToolbar from './ServiceFormToolbar'
import ServiceFormContainer from './ServiceFormContainer'
import { Button } from 'react-bootstrap';

export default ({
  pageNumber,
  totalPage,
  onPageChange,
  dateValue,
  onDateChange,
  goPrevPage,
  goNextPage,
  hasPrevPage,
  hasNextPage,
  currentServiceTxn,
  onFormSubmit,
  onDownloadAllServiceTxn,
  onDownloadServiceTxnBySeq,
  onDownloadServiceTxnInCSV,
  onViewAllServiceTxn,
  onViewServiceTxnBySeq,
  isNeedWorkSheet,
}) => (
    <div className="panel panel-default">
      <div className="panel-heading">
        <div className="row">
          <div className="col-md-1 text-left">
            <Button className=" btn btn-primary pager-btn" onClick={goPrevPage} disabled={hasPrevPage ? false : true}><span className="glyphicon glyphicon-chevron-left"></span></Button>
          </div>
          <div className="col-md-10">
            <ServiceFormToolbar
              dateValue={dateValue}
              onDownloadAllServiceTxn={onDownloadAllServiceTxn}
              onDownloadServiceTxnBySeq={onDownloadServiceTxnBySeq}
              onDownloadServiceTxnInCSV={onDownloadServiceTxnInCSV}
              onViewAllServiceTxn={onViewAllServiceTxn}
              onViewServiceTxnBySeq={onViewServiceTxnBySeq}
              isNeedWorkSheet={isNeedWorkSheet}
              onDateChange={onDateChange}
              pageNumber={pageNumber}
              totalPage={totalPage}
              onPageChange={onPageChange} />
          </div>
          <div className="col-md-1 text-right pager-btn">
            <Button className=" btn btn-primary" disabled={hasNextPage ? false : true} onClick={goNextPage}><span className="glyphicon glyphicon-chevron-right"></span></Button>
          </div>
        </div>
      </div>
      <ServiceFormContainer
        onSubmit={onFormSubmit}
        serviceTxn={currentServiceTxn} />
    </div>
  )
