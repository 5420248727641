import React from 'react'
import styled from 'styled-components'
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class SettingSideMenu extends React.Component {

  goToSetting = path => () => {
    this.props.history.push("/settings" + path)
  }

  menus = [
    { title: 'DROP OFF AREA', onClick: this.goToSetting('/dropOffArea') },
    { title: 'COMING SOON...',  className: "soon", disabled: true },
    { title: 'COMING SOON...',  className: "soon", disabled: true },
  ]

  render() {
    return (
      <SideMenuLayout>
        <ListGroup>
          {this.menus && this.menus.map((menu, i) => (
            <ListGroupItem
              disabled={menu.disabled}
              className={menu.className ? menu.className : 'text-strong'}
              key={i}
              onClick={menu.onClick}>
              <strong>{menu.title}</strong>
            </ListGroupItem>
          ))}
        </ListGroup>
      </SideMenuLayout>
    )
  }
}

const SideMenuLayout = styled.div`
  flex: 1;
  min-width: 200px;
  margin-right: 16px;
`

export default withRouter(SettingSideMenu)