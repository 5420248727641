import { createURL } from './remote_api_utils'

export const fetchDropOffAreas = (query, { fetchId } = {}) => ({
  type: `FETCH_DROP_OFF_AREA_OPTIONS`,
  meta: { fetch_id: fetchId || 'dropOffArea' },
  $payload: {
    url: createURL('/api/options/dropOffAreas'),
  },
})

export const saveDropOffAreas = (
  dropOffAreas,
  { fetchId, onResponse } = {},
) => ({
  type: `UPDATE_DROP_OFF_AREA_OPTIONS`,
  meta: { fetch_id: fetchId || 'dropOffArea', type: 'list' },
  $payload: {
    url: createURL('/api/options/dropOffAreas'),
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify({ dropOffAreas }),
    },
    onResponse,
  },
})

export const clearDropOffAreas = (type, { fetchId } = {}) => ({
  type: `CLEAR_FETCH_DATA`,
  meta: { fetch_id: fetchId || 'dropOffArea', type: type || 'list' },
})
