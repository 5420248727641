import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { debounce, memoize } from 'lodash'

import { fetchDebtors } from '../../actions/remote_api'
import { withDetailPopupProvider } from '../detailPopup'
import { withTableConfigs, withDeleteModal } from '../table'
import DebtorDetailPopup from './DebtorDetailPopup'
import DebtorTableFilterPanel from './DebtorTableFilterPanel'
import DebtorSideMenus from './DebtorSideMenus'
import styledComponents from 'styled-components'
import { createFetchId, getDebtor } from './index'
import { escape } from '../../utils/regex'

class DebtorTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = filter => {
    this.props.fetchDebtors(
      {
        type: this.props.debtorType,
        page: filter.currentPage,
        limit: filter.sizePerPage,
        sort: filter.sort,
        order: filter.order,
        ...this.createQueryFilter(filter.query),
      },
      { fetchId: createFetchId(this.props.debtorType) },
    )
  }

  createQueryFilter = filter => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.name) queryfilter['name_like'] = escape(filter.name)
    if (filter.taxId) queryfilter['taxId_like'] = escape(filter.taxId)
    if (filter.address) queryfilter['address_like'] = escape(filter.address)
    if (filter.invoiceProfile)
      queryfilter['invoiceProfile'] = filter.invoiceProfile

    return queryfilter
  }

  render() {
    return (
      <DebtorTable
        {...this.props}
        onDataChange={this.handleDataChange}
        customCreateButtonGroup={customCreateButtonGroup}
      />
    )
  }
}

export default connect(
  (state, props) => {
    const debtors = getDebtor(state, props.debtorType)
    return {
      instances: debtors ? debtors.list : [],
      totalCount: debtors ? debtors.totalCount : undefined,
    }
  },
  {
    fetchDebtors,
    deleteInstance: null,
  },
)(DebtorTableContainer)

let DebtorTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
  debtorType,
}) => {
  const { fields, table } = I18n.t('debtorPage')
  return (
    <Layout>
      <DebtorSideMenus />
      <Content>
        <h1 className="page-header">{table.header}</h1>
        {renderFilterPanel}
        {renderToolbar}
        <BootstrapTable {...defaultSettings} options={defaultOptions}>
          <TableHeaderColumn dataField="name" dataFormat={detailLink} dataSort>
            {fields.name}
          </TableHeaderColumn>
          <TableHeaderColumn dataField="taxId" dataSort>
            {fields.taxId}
          </TableHeaderColumn>
          <TableHeaderColumn dataField="address" dataSort>
            {fields.address}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="edit"
            editable={false}
            dataFormat={renderActions(editButton, debtorType)}
            width="100px"
            dataAlign="center"
            hiddenOnInsert
          />
        </BootstrapTable>
      </Content>
    </Layout>
  )
}

const customCreateButtonGroup = ownProps => props => null

const renderActions = memoize((editButton, debtorType) => (_, debtor) => (
  <div className="btn-group-vertical">
    {editButton(_, debtor, {
      path: `/debtor/${debtorType}/edit/${debtor.id}`,
    })}
  </div>
))

const Layout = styledComponents.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 505px;
`

const Content = styledComponents.div`
  flex: 5;
  min-width: 500px;
`

DebtorTable = withTableConfigs(({ debtorType }) => createFetchId(debtorType))(
  DebtorTable,
  DebtorTableFilterPanel,
)
DebtorTable = withDetailPopupProvider(DebtorTable, DebtorDetailPopup)
DebtorTable = withDeleteModal(DebtorTable)
