import React from 'react'
import StaffForm from './StaffForm'
import { connect } from 'react-redux'
import { fetchStaff, createStaff, updateStaff, clearStaff } from '../../actions/remote_api'
import { submit, stopSubmit } from 'redux-form'

class StaffFormPage extends React.Component {
  formId = 'staff'
  state = { formData: {} }

  componentDidMount() {
    const { fetchStaff, id, clearStaff } = this.props;

    clearStaff()
    if (id !== undefined) {
      fetchStaff(id)
    }
  }

  componentWillUnmount() {
    this.props.clearStaff()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.staff) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.staff }
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }
  }

  handleSubmit = (formData) => {
    const { createStaff, updateStaff, id } = this.props;
    const staff = formData

    if (staff.photo && staff.photo.split("?")[0])
    staff.photo = staff.photo.split("?")[0]

    if (id === undefined)
      createStaff(staff, { onResponse: this.handleResponse })
    else
      updateStaff(staff, { onResponse: this.handleResponse })
  }

  handleResponse = ({ ok }) => {
    if (ok)
      this.props.onSubmit()
  }

  submit = e => {
    this.props.submit(this.formId)
    e.preventDefault()
  }

  photoUploadURL = () => this.state.formData._id ? `/api/staffs/${this.state.formData._id}/photo` : undefined 

  render() {

    return (
      <form onSubmit={this.submit}>
        <StaffForm
          form={this.formId}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          photoUploadURL={this.photoUploadURL()}
          enableReinitialize />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { staff } }) => ({
    staff: staff ? staff.single : { firstName: '', lastName: '' },
    errors: staff ? staff.singleErrors : null
  }),
  { createStaff, updateStaff, fetchStaff, clearStaff, submit, stopSubmit }
)(StaffFormPage)