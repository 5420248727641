import React from 'react'
import {
  FormControl,
  InputGroup,
} from 'react-bootstrap'

export default ({
  pageNumber,
  totalPage,
  onPageGo,
  onPageChange,
}) => (
      <InputGroup className="page-label">
        <FormControl
          type="number"
          step={1}
          onChange={e => onPageChange(e.target.value)}
          value={pageNumber || ''}
        />
        <InputGroup.Addon><b>of {renderNumber(totalPage)}</b></InputGroup.Addon>
      </InputGroup>
  )

const renderNumber = number =>
  number !== undefined ? String(number) : '?'