import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import { get } from 'lodash'

import { fetchStaff } from '../../actions/remote_api'
import { withDetailPopup } from '../detailPopup'
import { dateFormat, ThumbnailContainer } from '../table'

const genderFormat = gender => (gender ? I18n.t(`fields.${gender}`) : undefined)
const ageFormat = birthDate =>
  birthDate ? moment(birthDate).fromNow(true) : undefined

const thumbnailFormat = thumbnail => (
  <ThumbnailContainer
    thumbnail={thumbnail}
    className="img-responsive"
    alt="thumbnail"
  />
)

const StaffDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('staffPage')
  return (
    <form className="form-horizontal">
      <Row>
        <Col xs={6}>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.photo}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.photo
                  ? thumbnailFormat(instance.photo)
                  : thumbnailFormat('/assets/images/no_image.png')}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.code}</label>
            <div className="col-sm-8">
              <p className="form-control-static">{instance && instance.code}</p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.firstName}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.firstName}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.lastName}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.lastName}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.gender}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && genderFormat(instance.gender)}
              </p>
            </div>
          </div>
        </Col>
        <Col xs={6}>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.birthDate}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && dateFormat(instance.birthDate)}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.age}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && ageFormat(instance.birthDate)}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.phone1}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.phone1}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.phone2}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.phone2}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.email}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.email}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">
              {fields.telegramUsername}
            </label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.telegramUsername}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </form>
  )
}

let StaffDetailPopupContainer = withDetailPopup(StaffDetailPopup, () => ({
  header: I18n.t('staffPage.detail.header'),
  customClass: 'modal-lg',
}))

export default connect(
  state => ({ instance: get(state, 'fetch.staff.single') }),
  { fetchInstance: fetchStaff },
)(StaffDetailPopupContainer)
