import React from 'react'
import { Panel, Form, Row, Col, FormControl, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import DateTime from 'react-datetime'
import Select from 'react-select'
import styledComponents from 'styled-components'
import ServiceSearchSelect from '../service/ServiceSearchSelect'
import moment from "moment";

export default ({ isShow, filter, onFilterChange, onClearFilter }) => {
  const fieldsLabel = I18n.t('serviceTxnPage.fields')

  const startDateValid = (current) => {
    if (filter.serviceDateTimeEnd)
      return current
        .startOf('day')
        .isBefore(filter.serviceDateTimeEnd.endOf('day'))
    return true
  }

  const endDateValid = (current) => {
    if (filter.serviceDateTimeStart)
      return current
        .endOf('day')
        .isAfter(filter.serviceDateTimeStart.startOf('day'))
    return true
  }

  return (
    <Panel collapsible expanded={isShow}>
      <Form horizontal>
        <Row>
          <Col md={8}>
            <FormRow>
              <Col md={3}>
                <ServiceSearchSelect
                  value={filter.service}
                  placeholder={fieldsLabel.service}
                  onChange={(value) => onFilterChange('service', value)}
                />
              </Col>
              <Col md={3}>
                <FormControl
                  type="text"
                  value={filter.flightNumber || ''}
                  onChange={(e) =>
                    onFilterChange('flightNumber', e.currentTarget.value)
                  }
                  placeholder={fieldsLabel.flightNumber}
                />
              </Col>
              <Col md={3}>
                <FormControl
                  type="text"
                  value={filter.mainPax || ''}
                  onChange={(e) =>
                    onFilterChange('mainPax', e.currentTarget.value)
                  }
                  placeholder={fieldsLabel.mainPax}
                />
              </Col>
              <Col md={3}>
                <Select
                  value={filter.status}
                  onChange={(value) => onFilterChange('status', value)}
                  placeholder={fieldsLabel.status}
                  simpleValue
                  options={statusOptions}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col md={4}>
                <FormControl
                  type="text"
                  value={filter.confirmationNumber || ''}
                  onChange={(e) =>
                    onFilterChange('confirmationNumber', e.currentTarget.value)
                  }
                  placeholder={fieldsLabel.confirmationNumber}
                />
              </Col>
            </FormRow>
          </Col>
          <Col md={2}>
            <FormRow>
              <DateTime
                value={filter.serviceDateTimeStart}
                onChange={(value) => {
                  if (value && !moment.isMoment(value)) return
                  onFilterChange('serviceDateTimeStart', value)
                }}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                isValidDate={startDateValid}
                inputProps={{
                  placeholder: fieldsLabel.serviceDateTimeStart,
                  style: { marginButtom: '4px' },
                }}
              />
            </FormRow>

            <FormRow>
              <DateTime
                value={filter.serviceDateTimeEnd}
                onChange={(value) => {
                  if (value && !moment.isMoment(value)) return
                  onFilterChange('serviceDateTimeEnd', value)
                }}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                isValidDate={endDateValid}
                inputProps={{ placeholder: fieldsLabel.serviceDateTimeEnd }}
              />
            </FormRow>
          </Col>

          <Col md={2}>
            <Button className="pull-right" onClick={onClearFilter}>
              <span className="glyphicon glyphicon-trash" /> Clear Filter
            </Button>
          </Col>
        </Row>
      </Form>
    </Panel>
  )
}

const statusOptions = [
  { label: 'PROCESSING', value: 'processing' },
  { label: 'COMPLETED', value: 'completed' },
  { label: 'MISSED CASE', value: 'missedCase' },
  { label: 'NOSHOW', value: 'noShow' },
  { label: 'CANCELED', value: 'canceled' },
]

const FormRow = styledComponents(Row)`
  margin-bottom: 16px;
`
