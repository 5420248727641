import React from 'react'
import { connect } from 'react-redux'
import { defaultTo } from 'lodash'

import { fetchServices, clearService } from '../../actions/remote_api'
import SearchSelect from '../../modules/select/SearchSelect'
import { makeServiceTitle } from '../index'

class ServiceSearchSelect extends React.Component {
  render() {
    return (
      <SearchSelect
        {...this.props}
        options={this.props.options}
        createQuery={defaultTo(this.props.createQuery, createQuery)}
        createLabel={createLabel}
        defaultValueQuery={defaultValueQuery}
        selectId="serviceOptions"
        fetchType="select"
      />
    )
  }
}

const createQuery = searchText => ({
  q: searchText ? searchText.toLowerCase() : undefined,
  order: 'asc',
  sort: '_id',
})

const defaultValueQuery = initialId => ({
  _id: initialId,
})

const createLabel = makeServiceTitle

export default connect(null, {
  fetchOptions: fetchServices,
  clearOptions: clearService,
})(ServiceSearchSelect)
