import React from 'react';
import './custom-bootstrap.css';
import 'font-awesome/css/font-awesome.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './react-datetime.css';
import 'react-select/dist/react-select.css';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css'
import './App.css';
import './tailwind.css';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import ReduxToastr from 'react-redux-toastr'
import AppRouter from './AppRouter'


import auth_routes from './components/auth/auth_routes'


class App extends React.Component {
  state = { isSidebarOpen: true }

  toggleSidebar = () => {
    this.setState(({ isSidebarOpen }) => ({ isSidebarOpen: !isSidebarOpen }))
  }

  render() {
    return (
      <div>
        <Router>
          <Switch>
            {auth_routes()}
            <AppRouter />
          </Switch>
        </Router>

        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut" />

      </div>
    );
  }
}

export default App;
