export const isServiceHasWarning = (service, jobAssignments) => {
  const assignAmount = getAssignedAmount(service, jobAssignments)
  if (!service.jobAssignmentRequired) return false
  return assignAmount > 0 ? false : true
}

export const getAssignedAmount = (serviceInstance, jobAssignments) =>
  (jobAssignments || []).filter((j) =>
    j.service ? j.service._id === serviceInstance._id : false,
  ).length

export const isReservationHasJobAssignmentWarning = (reservation) => {
  if (!reservation || !reservation.product || !reservation.product.services)
    return false
  return reservation.product.services.some((service) =>
    isServiceHasWarning(service, reservation.jobAssignments),
  )
}

