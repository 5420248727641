import React from 'react'

export default function WebServiceTxnReport({
  data: {
    options: { data, dataset },
  },
}) {
  const { logo } = data
  const length = new Set(dataset.serviceTxns.map(({ seq }) => seq)).size
  return (
    <div className="w-[840px] mx-auto space-y-10">
      {dataset.serviceTxns.map(
        (
          {
            seq,
            qrcodeImage,
            service,
            stationName,
            flightNumber,
            flightTime,
            product,
            customerName,
            staffs,
            agentName,
            serviceDate,
            serviceTime,
            confirmationNumber,
            importantNote,
            publicNote,
            guestPaxAdult,
            guestPaxChild,
            guestPaxInfan,
            status,
          },
          index,
        ) => {
          return (
            <div
              className="relative pt-2 pb-10 px-6 flex flex-col space-y-10 justify-between w-full h-[1180px] bg-white print:bg-transparent shadow print:shadow-none"
              key={seq}
            >
              {status === 'canceled' && (
                <div className="absolute w-full h-full inset-0 flex flex-col items-center justify-center">
                  <span className="border-solid border-2 border-red-500 rounded-2xl text-red-500 text-6xl p-4 mb-40 uppercase">
                    Canceled
                  </span>
                </div>
              )}
              <div className="w-full flex items-center p-4">
                <span className="text-4xl font-bold uppercase">SEQ: {seq}</span>
                <div className="flex-1 flex flex-col items-center pt-4 space-y-4">
                  <img className="object-contain h-12" src={logo} alt="coral" />
                  <span className="text-2xl font-semibold uppercase">
                    Job Assignment Form
                  </span>
                </div>

                <img
                  className="object-contain h-32 w-32"
                  src={`data:image/png;base64,${qrcodeImage}`}
                  alt="coral"
                />
              </div>

              <div className="w-full flex flex-col">
                <div className="flex">
                  <span className="flex-1 space-x-2">
                    <span className="font-semibold uppercase">Product:</span>
                    <span>{product}</span>
                  </span>
                  <span className="space-x-2">
                    <span className="font-semibold uppercase">Date:</span>
                    <span>{serviceDate}</span>
                  </span>
                </div>
                <div className="flex">
                  <span className="flex-1 space-x-2">
                    <span className="font-semibold uppercase">Service:</span>
                    <span>{service}</span>
                  </span>
                  {stationName && (
                    <span className="flex-1 space-x-2">
                      <span className="font-semibold uppercase">Station:</span>
                      <span>{stationName}</span>
                    </span>
                  )}
                  <span className="space-x-2">
                    <span className="font-semibold uppercase">Time:</span>
                    <span>{serviceTime}</span>
                  </span>
                </div>
              </div>

              <div className="w-full flex justify-center">
                <span className="flex font-semibold space-x-6">
                  <span className="uppercase">Guest:</span>
                  <span className="uppercase">
                    {guestPaxAdult.toLocaleString()} Adult
                  </span>
                  <span className="uppercase">
                    {guestPaxChild.toLocaleString()} Child
                  </span>
                  <span className="uppercase">
                    {guestPaxInfan.toLocaleString()} Infant
                  </span>
                </span>
              </div>

              {staffs.length > 0 ? (
                <table className="table table-fixed w-full text-base text-left rtl:text-right text-stone-500 rounded-lg overflow-hidden mt-4">
                  <thead className="text-base text-stone-700 uppercase bg-stone-100">
                    <tr>
                      <th className="px-3 py-2 w-[50px] text-right">No.</th>
                      <th className="px-3 py-2">Staff Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {staffs.map(({ name }, index) => {
                      return (
                        <tr
                          key={index}
                          className="odd:bg-white even:bg-stone-50 border-b"
                        >
                          <td className="px-3 py-2 text-right">{index + 1}</td>
                          <td className="px-3 py-2">{name}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="p-10 w-full text-center text-2xl font-semibold">
                  No Results
                </div>
              )}

              <div className="w-full flex flex-col items-end space-y-4 pt-6">
                <div className="flex items-baseline space-x-2">
                  <span>Passenger Sign:</span>
                  <span className="w-80 h-px bg-gray-400 inline-block"></span>
                </div>
                <div className="flex items-center space-x-6">
                  <span>Rate us: </span>

                  <span className="flex items-center space-x-1">
                    <span className="w-8 h-8 rounded-lg border border-solid border-gray-400 inline-block"></span>
                    <span>Excellent</span>
                  </span>

                  <span className="flex items-center space-x-1">
                    <span className="w-8 h-8 rounded-lg border border-solid border-gray-400 inline-block"></span>
                    <span>Good</span>
                  </span>

                  <span className="flex items-center space-x-1">
                    <span className="w-8 h-8 rounded-lg border border-solid border-gray-400 inline-block"></span>
                    <span>Fair</span>
                  </span>
                </div>
              </div>

              <div className="w-full flex flex-col">
                <div className="flex">
                  <span className="flex-1 space-x-2">
                    <span className="font-semibold uppercase">Name:</span>
                    <span>{customerName}</span>
                  </span>
                  <span className="space-x-2">
                    <span className="font-semibold uppercase">Flight:</span>
                    <span>
                      {flightNumber} ({flightTime})
                    </span>
                  </span>
                </div>
                <div className="flex">
                  <span className="flex-1 space-x-2">
                    <span className="font-semibold uppercase">Agent:</span>
                    <span>{agentName}</span>
                  </span>
                  <span className="space-x-2">
                    <span className="font-semibold uppercase">Confirmed:</span>
                    <span>{confirmationNumber}</span>
                  </span>
                </div>
                <div className="flex">
                  <span className="flex-1 space-x-2">
                    <span className="font-semibold uppercase">Summary:</span>
                  </span>
                </div>
                <div className="flex">
                  <span className="flex-1 space-x-2">
                    <span className="font-semibold uppercase">
                      Important Note:
                    </span>
                    <span>{importantNote || '-'}</span>
                  </span>
                </div>
                <div className="flex">
                  <span className="flex-1 space-x-2">
                    <span className="font-semibold uppercase">
                      Public Note:
                    </span>
                    <span>{publicNote || '-'}</span>
                  </span>
                </div>
              </div>

              <div className="flex-1"></div>

              <div className="w-full flex flex-col space-y-2">
                <div className="flex items-baseline space-x-6 uppercase">
                  <div className="flex items-baseline space-x-2 uppercase">
                    <span>** Confirmed Flight Time:</span>
                    <span className="w-60 h-px bg-gray-400 inline-block"></span>
                  </div>
                  <div className="flex items-baseline space-x-2 uppercase">
                    <span>** Finish Time:</span>
                    <span className="w-40 h-px bg-gray-400 inline-block"></span>
                  </div>
                </div>

                <div className="flex items-baseline space-x-6 uppercase">
                  <div className="flex items-baseline space-x-2 uppercase">
                    <span>** Number of guest:</span>
                    <span className="w-20 h-px bg-gray-400 inline-block"></span>
                  </div>
                  <div className="flex items-baseline space-x-2 uppercase">
                    <span>** Baggage:</span>
                    <span className="w-20 h-px bg-gray-400 inline-block"></span>
                  </div>
                  <div className="flex items-baseline space-x-2 uppercase">
                    <span>** Handbag:</span>
                    <span className="w-20 h-px bg-gray-400 inline-block"></span>
                  </div>
                </div>
              </div>

              <div className="w-full flex items-center space-x-6">
                <span className="uppercase">For Staff: </span>

                <span className="flex items-center space-x-1">
                  <span className="w-8 h-8 rounded-lg border border-solid border-gray-400 inline-block"></span>
                  <span>Completed</span>
                </span>

                <span className="flex items-center space-x-1">
                  <span className="w-8 h-8 rounded-lg border border-solid border-gray-400 inline-block"></span>
                  <span>No Show</span>
                </span>

                <span className="flex items-center space-x-1">
                  <span className="w-8 h-8 rounded-lg border border-solid border-gray-400 inline-block"></span>
                  <span>Missed Case</span>
                </span>
              </div>

              {index < length - 1 && <div className="break-after-page"></div>}
            </div>
          )
        },
      )}
    </div>
  )
}
