import FileSaver from 'file-saver'
import { removeUndefinedFromObject } from '../utils/url'

let store
let jwt

export const initAPI = (_store) => {
  store = _store
  store.subscribe(stateListener)
}

export const stateListener = () => {
  jwt =
    store.getState().auth && store.getState().auth.jwt
      ? store.getState().auth.jwt
      : undefined
}

const createAuthFetch = () => (url, options) => {
  if (!jwt) return Promise.reject('Authentication token is not found')

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    ...options,
  })
}

const withAuthFetch = (func) => {
  return func(createAuthFetch())
}

export const uploadImage = (url, file) => {
  const data = new FormData()
  data.append('file', file)
  return fetch(url, {
    method: 'POST',
    body: data,
    headers: { ...makeAuthorizationHeader() },
  }).then((response) => {
    return response.text()
  })
}

export const removeImage = (url) =>
  fetch(url, {
    method: 'DELETE',
    headers: { ...makeAuthorizationHeader() },
  })

const makeAuthorizationHeader = () => ({ Authorization: `Bearer ${jwt}` })

export const downloadReport = async (url, params) => {
  if (params)
    url += `?${new URLSearchParams(removeUndefinedFromObject(params)).toString()}`
  const data = await fetch(url, {
    method: 'GET',
    headers: makeAuthorizationHeader(),
  })
  const contentType = data.headers.get('content-type')
  if (contentType === 'application/pdf') {
    const filename = data.headers
      .get('Content-Disposition')
      .match(/filename=(.+)/)[1]
    const blob = await data.blob()
    FileSaver.saveAs(blob, filename)
    return Promise.resolve(data)
  }
  return Promise.reject(data)
}

export const downloadCSV = async (url, params) => {
  if (params)
    url += `?${new URLSearchParams(removeUndefinedFromObject(params)).toString()}`
  const data = await fetch(url, {
    method: 'GET',
    headers: makeAuthorizationHeader(),
  })

  const contentType = data.headers.get('content-type')

  if (contentType === 'text/csv; charset=utf-8') {
    const filename = data.headers
      .get('Content-Disposition')
      .match(/filename=(.+)/)[1]
    const blob = await data.blob()
    FileSaver.saveAs(blob, filename)
    return Promise.resolve(data)
  }
  return Promise.reject(data)
}

export const fetchAgents = withAuthFetch(
  (authFetch) => (query) =>
    authFetch(
      `api/agents?${new URLSearchParams(removeUndefinedFromObject(query)).toString()}`,
    ).then((data) => data.json()),
)

export const fetchReport = async (name, query) => {
  return fetch(
    `/api/reports/data/${name}?${new URLSearchParams(removeUndefinedFromObject(query)).toString()}`,
    {
      method: 'GET',
      headers: makeAuthorizationHeader(),
    },
  ).then((data) => data.json())
}

export const requestReport = async (url, params) => {
  if (params)
    url += `?${new URLSearchParams(removeUndefinedFromObject(params)).toString()}`
  return await fetch(url, {
    method: 'POST',
    headers: makeAuthorizationHeader(),
  })
}
