import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import WarningReservationTableContainer from './WarningReservationTableContainer'
import ReservationFormPage from './ReservationFormPage'
import AssignJobFormPage from './AssignJobFormPage'
import { findAccessLevel } from '../../modules/accessRoute';

export class WarningReservationPage extends React.Component {
  handleSubmit = () => {
    this.props.history.push(`/warning-reservation`)
  }

  table = () => <WarningReservationTableContainer path="/warning-reservation" />

  formPage = ({
    match: {
      params: { id },
    },
  }) => <ReservationFormPage id={id} onSubmit={this.handleSubmit} />
  assignJobPage = ({
    match: {
      params: { id },
    },
  }) => <AssignJobFormPage id={id} onSubmit={this.handleSubmit} />

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/warning-reservation"
          component={this.table}
          ifAnyGranted={findAccessLevel('/warning-reservation')}
        />

        <PrivateRoute
          path="/warning-reservation/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/warning-reservation')}
        />

        <PrivateRoute
          path="/warning-reservation/:id/jobAssignments"
          component={this.assignJobPage}
          ifAnyGranted={findAccessLevel('/warning-reservation')}
        />
      </div>
    )
  }
}
export default withRouter(WarningReservationPage)
