import React from 'react'
import { map } from 'lodash'
import { reduxForm, FieldArray } from 'redux-form'
import {
  Toolbar,
  DateTimeField,
  TextField,
  SwitchField,
  TextAreaField,
  FieldLabel,
} from '../form'
import { Button, Row, Col } from 'react-bootstrap'
import * as R from 'ramda'

import { required, validateWith, date, isPriceNumber } from '../form/validator'
import { I18n } from 'react-redux-i18n'
import PriceLabelField from '../form/PriceLabelField'
import styledComponents from 'styled-components'
import { withShowDebtModal } from './withShowDebtModal'

const validate = values => {
  const { debtor = {}, items = [] } = values
  return {
    code: validateWith(values.code, [required]),
    debtor: {
      name: validateWith(debtor.name, [required]),
      taxId: validateWith(debtor.taxId, [required]),
      address: validateWith(debtor.address, [required]),
      emails: validateWith(debtor.emails, [required]),
    },
    billingDate: validateWith(values.billingDate, [required, date]),
    dueDayCount: validateWith(values.dueDayCount, [required]),
    dueDate: validateWith(values.dueDate, [required, date]),
    taxPercentage: validateWith(values.taxPercentage, [
      required,
      isPriceNumber,
    ]),
    items: map(items, item => ({
      title: validateWith(item.title, [required]),
      quantity: validateWith(item.quantity, [required, isPriceNumber]),
      price: validateWith(item.price, [required, isPriceNumber]),
    })),
    discount: validateWith(values.discount, [required, isPriceNumber]),
    paymentDetail: validateWith(values.paymentDetail, [required]),
  }
}

const InvoiceForm = ({ initialValues, formValues = {} }) => {
  const { form, fields } = I18n.t('invoicePage')
  const { display: dateDisplay } = I18n.t('date')
  return (
    <div>
      <Toolbar title={formValues._id ? form.update : form.new}>
        <Button className="btn-primary" type="submit">
          Submit
        </Button>
      </Toolbar>
      <Row>
        <Col sm={3}>
          <TextField name="code" label={fields.code} addStar={true} />
        </Col>
      </Row>

      <Row>
        <Col sm={3}>
          <TextField
            name="debtor.taxId"
            label={fields.debtorTaxId}
            addStar={true}
          />
        </Col>
        <Col sm={4}>
          <TextField
            name="debtor.name"
            label={fields.debtorName}
            addStar={true}
          />
        </Col>
        <Col sm={4}>
          <TextField
            name="debtor.emails"
            label={fields.debtorEmail}
            addStar={true}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <TextAreaField
            name="debtor.address"
            label={fields.debtorAddress}
            addStar={true}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={3}>
          <DateTimeField
            name="billingDate"
            label={fields.billingDate}
            dateFormat={dateDisplay}
            timeFormat={false}
            addStar={true}
          />
        </Col>
        <Col sm={3}>
          <TextField
            name="dueDayCount"
            label={fields.dueDayCount}
            addStar={true}
            type="number"
            step={1}
          />
        </Col>
        <Col sm={3}>
          <DateTimeField
            name="dueDate"
            label={fields.dueDate}
            dateFormat={dateDisplay}
            timeFormat={false}
            addStar={true}
          />
        </Col>
        <Col sm={3}>
          <TextField
            name="taxPercentage"
            type="number"
            label={fields.taxPercentage}
            addStar={true}
          />
        </Col>
        <Col sm={3}>
          <TextField name="reference" label={fields.reference} addStar={true} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldArray
            name="items"
            component={renderInvoiceItems}
            invoice={formValues}
          />
        </Col>
      </Row>
    </div>
  )
}

const ReferenceText = styledComponents.h6`
  margin-top: -8px;
  font-style: italic;
`

const renderInvoiceItems = ({
  fields,
  meta: { error, submitFailed },
  invoice,
}) => (
  <div>
    <InvoiceItemTable className="table table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th width="35%">{I18n.t('invoicePage.fields.invoiceItem.title')}</th>
          <th width="10%" className="text-right">
            {I18n.t('invoicePage.fields.invoiceItem.quantity')}
          </th>
          <th width="10%" className="text-right">
            {I18n.t('invoicePage.fields.invoiceItem.price')}
          </th>
          <th>{I18n.t('invoicePage.fields.invoiceItem.isTaxIncluded')}</th>
          <th width="10%" className="text-right">
            {I18n.t('invoicePage.fields.invoiceItem.amount')}
          </th>
          <th width="10%" className="text-right">
            {I18n.t('invoicePage.fields.invoiceItem.tax')}
          </th>
          <th width="10%" className="text-right">
            {I18n.t('invoicePage.fields.invoiceItem.total')}
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {fields.map((item, index) => (
          <tr key={`${item._id}_${index}`}>
            <td>{index + 1}</td>
            <td>
              <div>
                <div>
                  <TextAreaField name={`${item}.title`} isFormGroupValidate />
                </div>
                <FieldLabel
                  name={`${item}.reference`}
                  component={R.compose(
                    R.ifElse(R.isEmpty, R.always(null), value => (
                      <ReferenceText>Ref: {value}</ReferenceText>
                    )),
                    R.prop('value'),
                  )}
                />
              </div>
            </td>
            <td>
              <TextField
                className="text-right"
                type="number"
                name={`${item}.quantity`}
                isFormGroupValidate
              />
            </td>
            <td>
              <TextField
                className="text-right"
                type="number"
                name={`${item}.price`}
                isFormGroupValidate
              />
            </td>
            <td>
              <SwitchField name={`${item}.isTaxIncluded`} />
            </td>
            <td className="text-right">
              <PriceLabelField name={`${item}.amount`} />
            </td>
            <td className="text-right">
              <PriceLabelField name={`${item}.tax`} />
            </td>
            <td className="text-right">
              <PriceLabelField name={`${item}.total`} />
            </td>
            <td>
              <Button onClick={() => fields.remove(index)} bsStyle="danger">
                {I18n.t('action.remove')}
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </InvoiceItemTable>

    <ButtonLayout>
      <Button
        onClick={() =>
          fields.push({ reference: null, isTaxIncluded: false, quantity: 1 })
        }
      >
        {I18n.t('action.add')}
      </Button>
      <ShowOriginalDebtsModal invoice={invoice} />
    </ButtonLayout>

    <Row>
      <Col lgOffset={8} lg={4} mdOffset={6} md={6} className="text-right">
        <DetailList className="dl-horizontal">
          <dt>
            <strong>{I18n.t('invoicePage.fields.subTotal')}</strong>
          </dt>
          <dd>
            <PriceLabelField name="subTotal" />
          </dd>
        </DetailList>
      </Col>
      <Col lgOffset={8} lg={4} mdOffset={6} md={6} className="text-right">
        <DetailList className="dl-horizontal">
          <dt>
            <strong>{I18n.t('invoicePage.fields.discount')}</strong>
          </dt>
          <dd>
            <TextField
              name="discount"
              className="text-right"
              isNoMargin
              type="number"
              isFormGroupValidate
            />
          </dd>
        </DetailList>
      </Col>
      <Col lgOffset={8} lg={4} mdOffset={6} md={6} className="text-right">
        <DetailList className="dl-horizontal">
          <dt>
            <strong>{I18n.t('invoicePage.fields.tax')}</strong>
          </dt>
          <dd>
            <PriceLabelField name="tax" />
          </dd>
        </DetailList>
      </Col>
      <Col lgOffset={8} lg={4} mdOffset={6} md={6} className="text-right">
        <DetailList className="dl-horizontal">
          <dt>
            <strong>{I18n.t('invoicePage.fields.grandTotal')}</strong>
          </dt>
          <dd>
            <PriceLabelField name="grandTotal" />
          </dd>
        </DetailList>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <TextAreaField
          name="paymentDetail"
          i18nLabel="invoicePage.fields.paymentDetail"
          rows={4}
          addStar
        />
      </Col>
      <Col md={6}>
        <TextAreaField
          name="note"
          i18nLabel="invoicePage.fields.note"
          rows={4}
        />
      </Col>
    </Row>
  </div>
)

const DetailList = styledComponents.dl`
  margin-bottom: 8px;
`

const ShowOriginalDebtsModal = withShowDebtModal(
  ({ showDebtModal, isDebtModalAvailable }) => {
    if (!isDebtModalAvailable) return null
    return (
      <Button onClick={showDebtModal} bsStyle="info">
        {I18n.t('invoicePage.form.showOriginalDebts')}
      </Button>
    )
  },
)

const InvoiceItemTable = styledComponents.table`
  margin-bottom: 0;
`

const ButtonLayout = styledComponents.div`
  display: flex;
  > *:not(:last-child) {
    margin-right: 8px;
  }
`

export default reduxForm({
  validate,
})(InvoiceForm)
