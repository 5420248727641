import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import StationTable from './StationTable'
import StationFormPage from './StationFormPage'
import { findAccessLevel } from '../../modules/accessRoute'

export class StationPage extends React.Component {
  handleSubmit = () => {
    this.props.history.push(`/station`)
  }

  table = () => <StationTable path="/station" />

  formPage = ({
    match: {
      params: { id },
    },
  }) => <StationFormPage id={id} onSubmit={this.handleSubmit} />

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/station"
          component={this.table}
          ifAnyGranted={findAccessLevel('/station')}
        />

        <PrivateRoute
          path="/station/new"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/station')}
        />

        <PrivateRoute
          path="/station/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/station')}
        />
      </div>
    )
  }
}
export default withRouter(StationPage)
