import React from 'react'
import { Button, Col, Form, FormGroup, ControlLabel } from 'react-bootstrap'
import DateTime from 'react-datetime'
import Select from 'react-select'
import moment from 'moment'

import CardSearchSelect from '../card/CardSearchSelect'
import StationSearchSelect from '../station/StationSearchSelect'
import WalkinRadioGroup from '../walkin/BetaWalkinRadioGroup'

export default ({
  onFilterChange,
  filter,
  onDownloadPDF,
  onDownloadCSV,
  onViewWebClick,
  isDownloadable,
}) => (
  <div className="container">
    <div className="panel panel-default">
      <div className="panel-body">
        <h1 className="page-header">Card Report</h1>
        <hr />
        <Form horizontal>
          <FormGroup controlId="startDate">
            <Col componentClass={ControlLabel} sm={4}>
              Start Date*
            </Col>
            <Col sm={6}>
              <DateTime
                value={filter.startDate}
                onChange={(value) => {
                  if (value && !moment.isMoment(value)) return
                  onFilterChange('startDate', value)
                }}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ placeholder: 'Start Date' }}
              />
            </Col>
          </FormGroup>

          <FormGroup controlId="endDate">
            <Col componentClass={ControlLabel} sm={4}>
              End Date*
            </Col>
            <Col sm={6}>
              <DateTime
                value={filter.endDate}
                onChange={(value) => {
                  if (value && !moment.isMoment(value)) return
                  onFilterChange('endDate', value)
                }}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ placeholder: 'End Date' }}
              />
            </Col>
          </FormGroup>

          <FormGroup controlId="formHorizontalCard">
            <Col componentClass={ControlLabel} sm={4}>
              Walkin Type
            </Col>

            <Col sm={8}>
              <WalkinRadioGroup
                value={filter.walkinType}
                onChange={(value) => onFilterChange('walkinType', value)}
              />
            </Col>
          </FormGroup>

          {filter.walkinType === 'card' && (
            <FormGroup controlId="formHorizontalCard">
              <Col componentClass={ControlLabel} sm={4}>
                Card*
              </Col>

              <Col sm={6}>
                <CardSearchSelect
                  required
                  value={filter.cardId}
                  onChange={(value) => onFilterChange('cardId', value)}
                  placeholder="Card"
                  selectValue={(card) => card._id}
                />
              </Col>
            </FormGroup>
          )}

          <FormGroup controlId="formHorizontalCard">
            <Col componentClass={ControlLabel} sm={4}>
              Extra Priviledge
            </Col>

            <Col sm={6}>
              <Select
                name="extraPrivilege"
                value={filter.extraPrivilege}
                onChange={(value) => onFilterChange('extraPrivilege', value)}
                placeholder="Extra Privilege"
                simpleValue
                options={extraPrivileges}
              />
            </Col>
          </FormGroup>

          <FormGroup controlId="formHorizontalCard">
            <Col componentClass={ControlLabel} sm={4}>
              Station
            </Col>

            <Col sm={6}>
              <StationSearchSelect
                name="stationId"
                value={filter.stationId}
                onChange={(value) => onFilterChange('stationId', value)}
                placeholder="Station"
                selectValue={(station) => station._id}
              />
            </Col>
          </FormGroup>

          <FormGroup className="space-y-4 md:space-y-0">
            <Col smOffset={0} sm={4}>
              <Button
                bsStyle="primary"
                onClick={onDownloadCSV}
                disabled={!isDownloadable}
                className="flex flex-row w-full justify-center items-center space-x-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                </svg>
                <span>Send me CSV</span>
              </Button>
            </Col>

            <Col sm={4}>
              <Button
                bsStyle="primary"
                onClick={onDownloadPDF}
                disabled={!isDownloadable}
                className="flex flex-row w-full justify-center items-center space-x-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                </svg>
                <span>Send me PDF</span>
              </Button>
            </Col>

            <Col sm={4}>
              <Button
                bsStyle="primary"
                onClick={onViewWebClick}
                disabled={!isDownloadable}
                className="flex flex-row w-full justify-center items-center space-x-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625ZM7.5 15a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 7.5 15Zm.75 2.25a.75.75 0 0 0 0 1.5H12a.75.75 0 0 0 0-1.5H8.25Z"
                    clipRule="evenodd"
                  />
                  <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                </svg>
                <span>View on Web</span>
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    </div>
  </div>
)

const extraPrivileges = [
  { label: 'Voucher', value: 'voucher' },
  { label: 'Complimentary', value: 'complimentary' },
  { label: 'Guest', value: 'guest' },
]
