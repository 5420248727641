import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter, Redirect, Switch } from 'react-router-dom'
import DebtorTable from './DebtorTable'
import DebtorFormPage from './DebtorFormPage'
import { findAccessLevel } from '../../modules/accessRoute'

class DebtorPage extends React.Component {
  handleSubmit = debtorType => {
    this.props.history.push(`/debtor/` + debtorType || '')
  }

  table = ({
    match: {
      params: { debtorType },
    },
  }) => {
    if (debtorType !== 'template') {
      return (
        <DebtorTable
          path="/debtor"
          debtorType={debtorType}
          isTemplate={false}
        />
      )
    }
    return <DebtorTable path="/debtor" isTemplate />
  }

  formPage = ({
    match: {
      params: { id, debtorType },
    },
  }) => (
    <DebtorFormPage
      id={id}
      debtorType={debtorType}
      onSubmit={this.handleSubmit}
      debtorTemplateId={
        this.props.location.state
          ? this.props.location.state.debtorTemplateId
          : undefined
      }
    />
  )

  render() {
    return (
      <Switch>
        <Redirect to="/debtor/agent" from="/debtor" exact />

        <PrivateRoute
          exact
          path="/debtor/agent"
          component={() => (
            <DebtorTable path="/debtor" debtorType="agent" isTemplate={false} />
          )}
          ifAnyGranted={findAccessLevel('/debtor')}
        />

        <PrivateRoute
          exact
          path="/debtor/airline"
          component={() => (
            <DebtorTable
              path="/debtor"
              debtorType="airline"
              isTemplate={false}
            />
          )}
          ifAnyGranted={findAccessLevel('/debtor')}
        />

        <PrivateRoute
          exact
          path="/debtor/card"
          component={() => (
            <DebtorTable path="/debtor" debtorType="card" isTemplate={false} />
          )}
          ifAnyGranted={findAccessLevel('/debtor')}
        />

        <PrivateRoute
          exact
          path="/debtor/template"
          component={() => <DebtorTable path="/debtor" isTemplate />}
          ifAnyGranted={findAccessLevel('/debtor')}
        />

        <PrivateRoute
          path="/debtor/:debtorType(agent|airline|card|template)/new"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/debtor')}
        />

        <PrivateRoute
          path="/debtor/:debtorType(agent|airline|card|template)/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/debtor')}
        />

        <PrivateRoute
          path="/debtor/:debtorType(agent|airline|card|template)/pricing/:id"
          component={this.productPricingFormPage}
          ifAnyGranted={findAccessLevel('/debtor')}
        />
      </Switch>
    )
  }
}

export default withRouter(DebtorPage)
