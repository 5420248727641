import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'

import { updateInvoice } from '../../../actions/remote_api'
import { invoiceStatus } from '../../../services/invoice'

function unmarkManual(invoice) {
  return {
    ...invoice,
    status: invoiceStatus.draft,
  }
}

export const withUnmarkManualInvoice = compose(
  connect(
    null,
    (dispatch, { invoice }) => ({
      updateInvoice: onResponse => {
        dispatch(updateInvoice(unmarkManual(invoice), { onResponse }))
      },
    }),
  ),
  withProps(({ updateInvoice }) => ({
    unmarkManual: updateInvoice,
  })),
)

export default withUnmarkManualInvoice(({ unmarkManual, children }) =>
  children(unmarkManual),
)
