import { escape } from '../../utils/regex'

export const createQueryFilter = (filter) => {
  if (!filter) return undefined
  const queryfilter = {}
  if (filter.mainPaxName)
    queryfilter.mainPaxName_like = escape(filter.mainPaxName)

  if (filter.confirmationNumber)
    queryfilter.confirmationNumber_like = escape(filter.confirmationNumber)

  if (filter.flightNumber)
    queryfilter.flightNumber_like = escape(filter.flightNumber)

  if (filter.serviceDateTimeStart) {
    queryfilter.serviceDateTime_gte = filter.serviceDateTimeStart
      .startOf('day')
      .toISOString()
  }

  if (filter.serviceDateTimeEnd) {
    queryfilter.serviceDateTime_lte = filter.serviceDateTimeEnd
      .endOf('day')
      .toISOString()
  }

  if (filter.status) queryfilter.status = filter.status

  if (filter.jobAssignStatus)
    queryfilter.jobAssignStatus = filter.jobAssignStatus

  if (filter.productId) queryfilter.productId = filter.productId

  if (filter.agentId) queryfilter.agentId = filter.agentId

  if (filter.stationId) queryfilter.stationId = filter.stationId

  return queryfilter
}
