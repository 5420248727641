import React from 'react'
import { Table } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import { memoize, curry } from 'lodash'
import SteppedPricingProductPriceTableStepItem from './SteppedPricingProductPriceTableStepItem'

class SteppedPricingProductPriceTable extends React.Component {
  handleStepChange = memoize(
    curry((index, key, value) => {
      this.props.onChange(index)(key, value)
    }),
  )

  handleStepBlur = memoize(
    curry((index, key, value) => {
      this.props.onBlur(index)(key, value)
    }),
  )

  render() {
    const { steps, formKeyPath } = this.props
    return (
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>
              <Translate value="pricingContractPage.pricingProductStepped.limit" />
            </th>
            <th>
              <Translate value="pricingContractPage.pricingProductStepped.range" />
            </th>
            <th className="text-right">
              <Translate value="pricingContractPage.pricingProductStepped.price" />
            </th>
          </tr>
        </thead>
        <tbody>
          {steps &&
            steps.map((step, index) => (
              <SteppedPricingProductPriceTableStepItem
                key={step._id || index}
                step={step}
                seq={index + 1}
                onChange={this.handleStepChange(index)}
                onBlur={this.handleStepBlur(index)}
                formKeyPath={`${formKeyPath}.steps.${index}`}
              />
            ))}
        </tbody>
      </Table>
    )
  }
}

export default SteppedPricingProductPriceTable
