import React from 'react'
import { reduxForm } from 'redux-form'
import { TextField, Toolbar, ImageUploadField } from '../form'
import { required, validateWith } from '../form/validator'
import { Button, Col, Row } from 'react-bootstrap'

import { I18n } from 'react-redux-i18n'
import AirlineClassToServiceMappingField from './AirlineClassToServiceMappingField'

const validate = values => {
  const errors = {}
  errors.code = validateWith(values.code, [required])
  errors.name = validateWith(values.name, [required])
  errors.classToServiceMapping = (values.classToServiceMapping || []).map(
    c => ({
      privilegeClass: validateWith(c.privilegeClass, [required]),
      meta: {
        serviceMappingId: validateWith(
          c.meta ? c.meta.serviceMappingId : undefined,
          [required],
        ),
      },
    }),
  )
  return errors
}

const AirlineForm = ({
  initialValues,
  logoUploadURL,
  makeNewClassToServiceMapping,
}) => {
  const { form, fields } = I18n.t('airlinePage')
  return (
    <div className="container">
      <Row>
        <Toolbar title={initialValues._id ? form.update : form.new}>
          <Button className="btn-primary" type="submit">
            Submit
          </Button>
        </Toolbar>
      </Row>
      <Row>
        <Col sm={6}>
          <Row>
            <Col sm={12}>
              <TextField name="code" label={fields.code} />
            </Col>
            <Col sm={12}>
              <TextField name="name" label={fields.name} />
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
          <Col sm={12}>
            {logoUploadURL ? (
              <ImageUploadField
                name="logo"
                label={fields.logo}
                width={'50%'}
                uploadURL={logoUploadURL}
                emptyLabel={fields.logoUploadEmpty}
              />
            ) : (
              fields.logoUploadInfo
            )}
          </Col>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <AirlineClassToServiceMappingField
            name="classToServiceMapping"
            label={fields.classToServiceMapping}
            makeNewItem={makeNewClassToServiceMapping}
          />
        </Col>
      </Row>
    </div>
  )
}

export default reduxForm({
  validate,
})(AirlineForm)
