import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { toUpper } from 'lodash'
import { I18n } from 'react-redux-i18n'
import { ButtonGroup, Button } from 'react-bootstrap'
import Icon from 'react-icons-kit'

import { withDeleteModal, withTableConfigs } from '../table'
import { withDetailPopupProvider } from '../detailPopup'
import AgentDetailPopup from './AgentDetailPopup'
import AgentTableFilterPanel from './AgentTableFilterPanel'
import { agentTypes } from '../../services/agentType'
import { iosContactOutline } from 'react-icons-kit/ionicons'
import AgentCashTxnModalContainer from './agentCashTxnModal/AgentCashTxnModalContainer'
import { priceFormat } from '../../services/currency'
import Loader from '../Loader'
import TableLoading from '../table/TableLoading'

let AgentTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
  refetch,
  isLoading,
}) => {
  const { fields, table } = I18n.t('agentPage')
  return (
    <div>
      <h1 className="page-header">{table.header}</h1>
      {renderFilterPanel}
      {renderToolbar}
      <Loader loading={<TableLoading />} isLoading={isLoading}>
        <BootstrapTable {...defaultSettings} options={defaultOptions}>
          <TableHeaderColumn
            width="8%"
            dataField="code"
            dataFormat={detailLink}
            dataSort
          >
            {fields.code}
          </TableHeaderColumn>
          <TableHeaderColumn width="28%" dataField="name" dataSort>
            {fields.name}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="100px"
            dataField="type"
            dataSort
            dataFormat={toUpper}
          >
            {fields.type}
          </TableHeaderColumn>
          <TableHeaderColumn width="15%" dataField="phone1" dataSort>
            {fields.phone1}
          </TableHeaderColumn>
          <TableHeaderColumn width="15%" dataField="phone2" dataSort>
            {fields.phone2}
          </TableHeaderColumn>
          <TableHeaderColumn width="20%" dataField="email" dataSort>
            {fields.email}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="10%"
            dataField="remainingTopUp"
            dataFormat={remainingTopUpFormat}
            dataAlign="right"
          >
            {fields.remainingTopUp}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="edit"
            editable={false}
            dataFormat={createButton(editButton, refetch)}
            width="150px"
            dataAlign="center"
            hiddenOnInsert
          />
        </BootstrapTable>
      </Loader>
    </div>
  )
}

function remainingTopUpFormat(remainingTopUp, agent) {
  return agent.type === agentTypes.prepaid ? priceFormat(remainingTopUp) : '-'
}

function createButton(editButton, refetch) {
  return (cell, agent) => {
    return (
      <ButtonGroup vertical>
        {editButton(cell, agent)}
        {agent.type === agentTypes.prepaid && (
          <AgentCashTxnModalContainer agent={agent} onAdjustBalance={refetch}>
            {(onShow, modal) => (
              <Button onClick={onShow}>
                <Icon icon={iosContactOutline} />
                {I18n.t('agentPage.fields.topupInfo')}
                {modal}
              </Button>
            )}
          </AgentCashTxnModalContainer>
        )}
      </ButtonGroup>
    )
  }
}

AgentTable = withTableConfigs('agent')(AgentTable, AgentTableFilterPanel)
AgentTable = withDetailPopupProvider(AgentTable, AgentDetailPopup)
AgentTable = withDeleteModal(AgentTable)

export default AgentTable