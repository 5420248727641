import React from 'react'
import AgentForm from './AgentForm'
import { connect } from 'react-redux'
import {
  fetchAgent,
  createAgent,
  updateAgent,
  clearAgent,
} from '../../actions/remote_api'
import { submit, stopSubmit, getFormValues } from 'redux-form'

const formId = 'agent'

class AgentFormPage extends React.Component {
  state = { formData: { isReserveRestricted: false } }

  componentDidMount() {
    const { fetchAgent, id, clearAgent } = this.props

    clearAgent()
    if (id !== undefined) {
      fetchAgent(id)
    }
  }

  componentWillUnmount() {
    this.props.clearAgent()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.agent) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.agent },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(formId, nextProps.errors)
    }
  }

  handleSubmit = formData => {
    const { createAgent, updateAgent, id } = this.props
    const agent = formData
    if (id === undefined)
      createAgent(agent, { onResponse: this.handleResponse })
    else updateAgent(agent, { onResponse: this.handleResponse })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit()
  }

  submit = e => {
    this.props.submit(formId)
    e.preventDefault()
  }

  render() {
    return (
      <form onSubmit={this.submit}>
        <AgentForm
          form={formId}
          initialValues={this.state.formData}
          formValues={this.props.formValues}
          onSubmit={this.handleSubmit}
          enableReinitialize
        />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { agent }, ...state }) => ({
    agent: agent ? agent.single : { firstName: '', lastName: '' },
    errors: agent ? agent.singleErrors : null,
    formValues: getFormValues(formId)(state),
  }),
  { createAgent, updateAgent, fetchAgent, clearAgent, submit, stopSubmit },
)(AgentFormPage)
