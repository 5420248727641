import React from 'react'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { connect } from 'react-redux'
import { debounce, get } from 'lodash'

import { fetchServiceTxns } from '../../actions/remote_api'
import ServiceTxnTable from './ServiceTxnTable'
import { escape } from '../../utils/regex'

class ServiceTxnTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = filter => {
    this.props.fetchServiceTxns({
      page: filter.currentPage,
      limit: filter.sizePerPage,
      sort: filter.sort,
      order: filter.order,
      ...this.createQueryFilter(filter.query),
    })
  }

  customCreateButtonGroup = props => <div />

  createQueryFilter = filter => {
    if (!filter) return undefined
    const queryfilter = {}
    if (filter.service) queryfilter['service._id'] = filter.service._id
    if (filter.flightNumber) queryfilter.flightNumber_like = escape(filter.flightNumber)
    if (filter.status) queryfilter.status = filter.status
    if (filter.mainPax) queryfilter.reservationMainPaxName_like = escape(filter.mainPax)
    if (filter.confirmationNumber)
      queryfilter.reservationConfirmationNumber_like = escape(filter.confirmationNumber)

    if (filter.serviceDateTimeStart) {
      queryfilter.serviceDateTime_gte = filter.serviceDateTimeStart
        .startOf('day')
        .toISOString()
    }

    if (filter.serviceDateTimeEnd) {
      queryfilter.serviceDateTime_lte = filter.serviceDateTimeEnd
        .endOf('day')
        .toISOString()
    }
    return queryfilter
  }

  render() {
    return (
      <ServiceTxnTable
        {...this.props}
        defaultSortName="serviceDateTime"
        defaultSortOrder="desc"
        options={{ btnGroup: this.customCreateButtonGroup }}
        settings={{ insertRow: false, deleteRow: false, selectRow: undefined }}
        customDefaultSizePerPage={10}
        onDataChange={this.handleDataChange}
      />
    )
  }
}

export default connect(
  ({ fetch }) => ({
    isLoading: get(fetch, 'serviceTxn.isLoading.list', false),
    instances: get(fetch, 'serviceTxn.list', []),
    totalCount: get(fetch, 'serviceTxn.totalCount'),
  }),
  { fetchServiceTxns },
)(ServiceTxnTableContainer)
