import React from 'react'
import {
  Route
} from 'react-router-dom'

import LoginContainer from './LoginContainer'

const routes = () => (
  <Route exact path="/login" component={LoginContainer} />
)

export default routes;