import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { debounce, get } from 'lodash'

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { fetchAirlines, deleteAirline } from '../../actions/remote_api'
import {
  renderClassToServiceMapping,
  withTableConfigs,
  withDeleteModal,
  makeRenderThumbnail,
} from '../table'
import { withDetailPopupProvider } from '../detailPopup'
import AirlineDetailPopup from './AirlineDetailPopup'
import AirlineTableFilterPanel from './AirlineTableFilterPanel'
import Loader from '../Loader'
import TableLoading from '../table/TableLoading'
import { escape } from '../../utils/regex'

class AirlineTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = filter => {
    this.props.fetchAirlines({
      page: filter.currentPage,
      limit: filter.sizePerPage,
      sort: filter.sort,
      order: filter.order,
      ...this.createQueryFilter(filter.query),
    })
  }

  createQueryFilter = filter => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.code) queryfilter.code_like = escape(filter.code)
    if (filter.name) queryfilter.name_like = escape(filter.name)

    return queryfilter
  }

  render() {
    return <AirlineTable {...this.props} onDataChange={this.handleDataChange} />
  }
}

export default connect(
  ({ fetch }) => ({
    isLoading: get(fetch, 'airline.isLoading.list', false),
    instances: get(fetch, 'airline.list', []),
    totalCount: get(fetch, 'airline.totalCount'),
  }),
  { fetchAirlines, deleteInstance: deleteAirline },
)(AirlineTableContainer)

let AirlineTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
  isLoading,
}) => {
  const { fields, table } = I18n.t('airlinePage')
  return (
    <div>
      <h1 className="page-header">{table.header}</h1>
      {renderFilterPanel}
      {renderToolbar}
      <Loader loading={<TableLoading />} isLoading={isLoading}>
        <BootstrapTable
          trClassName="airline-table-row"
          {...defaultSettings}
          options={defaultOptions}
        >
          <TableHeaderColumn
            dataField="logo"
            dataFormat={renderThumbnail}
            width="125px"
            dataAlign="center"
          >
            {fields.logo}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="15%"
            dataField="code"
            dataFormat={detailLink}
            dataSort
          >
            {fields.code}
          </TableHeaderColumn>
          <TableHeaderColumn width="15%" dataField="name" dataSort>
            {fields.name}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="40%"
            dataField="classToServiceMapping"
            dataFormat={renderClassToServiceMapping}
          >
            {fields.classToServiceMapping}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="edit"
            editable={false}
            dataFormat={editButton}
            width="100px"
            dataAlign="center"
            hiddenOnInsert
          />
        </BootstrapTable>
      </Loader>
    </div>
  )
}

const renderThumbnail = makeRenderThumbnail({
  maxHeight: '60px',
  maxWidth: '60px',
})

AirlineTable = withTableConfigs('airline')(
  AirlineTable,
  AirlineTableFilterPanel,
)
AirlineTable = withDetailPopupProvider(AirlineTable, AirlineDetailPopup)
AirlineTable = withDeleteModal(AirlineTable)
