import { toastr } from 'react-redux-toastr'

export { withDetailPopupProvider } from './DetailPopupProvider'
export { withDetailPopup } from './DetailPopup'
export { RightBarButtonHolder } from './DetailPopup'

export const createShowToast = message => response => {
  if (response.ok) toastr.success(message)
  else toastr.error(response.statusText)
}
