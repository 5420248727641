import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { withConfirmModalHandlers } from './withConfirmModalHandlers'

const ConfirmModal = ({
  isShow,
  onShow,
  onHide,
  onConfirm,
  children,
  title,
  subtitle,
}) =>
  children({
    onShow,
    modal: (
      <Modal show={isShow} onHide={onHide} bsSize="sm">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{subtitle}</Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={onHide}>
            {I18n.t('action.cancel')}
          </Button>
          <Button bsStyle="primary" onClick={onConfirm}>
            {I18n.t('action.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    ),
  })

export default withConfirmModalHandlers(ConfirmModal)
