import React from 'react'
import { withRouter } from 'react-router-dom'
import ServiceFormAPI from './ServiceFormAPI'

export default withRouter(({ match, history, location }) => (
  <div className="container">
    <ServiceFormAPI
      loadServiceTxn={
        location.state ? location.state.loadServiceTxn : undefined
      }
    />
  </div>
))
