import React from 'react'
import {
  Modal,
  Row,
  Col,
  Button,
  FormControl,
  FormGroup,
  ControlLabel,
  InputGroup,
} from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { priceFormat } from '../../../services/currency'
import AgentCashTxnTable from './AgentCashTxnTable'

const AgentCashTxnModal = ({
  isShow,
  onClose,
  agent,
  adjustTopupValue,
  onAdjustTopupChange,
  onAdjustBalance,
  agentCashTxns,
}) => (
  <Modal show={isShow} onHide={onClose} backdrop={false} bsSize="large">
    <Modal.Header closeButton>
      <Modal.Title>{I18n.t('agentPage.agentCashTxnModal.header')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Col md={6}>
          <div className="form-group">
            <label className="control-label">
              {I18n.t('agentPage.agentCashTxnModal.remainingTopUp')}
            </label>
            <p className="form-control-static">
              {agent && priceFormat(agent.remainingTopUp)}
            </p>
          </div>
        </Col>
        <Col md={6}>
          <FormGroup>
            <ControlLabel>
              {I18n.t('agentPage.agentCashTxnModal.adjustingBalance')}
            </ControlLabel>
            <InputGroup>
              <FormControl
                type="number"
                value={adjustTopupValue}
                onChange={onAdjustTopupChange}
              />
              <InputGroup.Button>
                <Button bsStyle="success" onClick={onAdjustBalance}>
                  {I18n.t('agentPage.agentCashTxnModal.adjustBalance')}
                </Button>
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>

      <AgentCashTxnTable agentCashTxns={agentCashTxns} />
    </Modal.Body>
  </Modal>
)

export default AgentCashTxnModal
