import React from 'react'
import { fetchReport } from '../../api'
import WebAirlineReport from './WebAirlineReport'
import WebCardReport from './WebCardReport'
import WebReservationReport from './WebReservationReport'
import WebServiceTxnReport from './WebServiceTxnReport'

export const route = '/report/web'
export function isReportWebRoute(location) {
  return location.pathname === route
}

function WebReportHeader() {
  return (
    <div className="fixed z-10 print:hidden w-full bg-gray-50 shadow">
      <div className="mx-auto container flex justify-end px-6 py-2">
        <div className="space-x-4 flex flex-row">
          <button
            className="flex items-center px-4 py-1 bg-gray-200 hover:bg-stone-300 rounded border-none text-lg font-semibold space-x-2"
            onClick={() => {
              window.open('/report/web/help', '_blank')
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
              />
            </svg>
          </button>
          <button
            className="flex items-center px-4 py-1 bg-gray-200 hover:bg-stone-300 rounded border-none text-lg font-semibold space-x-2"
            onClick={() => {
              window.print()
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 0 0 3 3h.27l-.155 1.705A1.875 1.875 0 0 0 7.232 22.5h9.536a1.875 1.875 0 0 0 1.867-2.045l-.155-1.705h.27a3 3 0 0 0 3-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0 0 18 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM16.5 6.205v-2.83A.375.375 0 0 0 16.125 3h-8.25a.375.375 0 0 0-.375.375v2.83a49.353 49.353 0 0 1 9 0Zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 0 1-.374.409H7.232a.375.375 0 0 1-.374-.409l.526-5.784a.373.373 0 0 1 .333-.337 41.741 41.741 0 0 1 8.566 0Zm.967-3.97a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H18a.75.75 0 0 1-.75-.75V10.5ZM15 9.75a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V10.5a.75.75 0 0 0-.75-.75H15Z"
                clipRule="evenodd"
              />
            </svg>
            <span>Save as PDF</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default class WebReport extends React.Component {
  state = {
    isLoading: false,
    data: undefined,
    name: undefined,
  }

  componentDidMount() {
    this.fetchReportInfo()
  }

  fetchReportInfo = async () => {
    this.setState({ ...this.state, isLoading: true })
    const searchParams = new URLSearchParams(window.location.search)
    const params = {}
    for (const [key, value] of searchParams.entries()) params[key] = value

    const { name, ...query } = params
    const { data } = await fetchReport(name, query)
    this.setState({ ...this.state, isLoading: false, name, data })
    document.title = data.fileName
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="w-full h-full p-10 flex justify-center">
          <span className="text-lg font-semibold">Loading...</span>
        </div>
      )
    }

    return (
      <div className="relative bg-gray-50 print:bg-transparent min-h-screen">
        <WebReportHeader />
        <div className="text-xl font-report pt-[5.75rem] pb-[2.5rem] print:pt-0 print:pb-0">
          {(() => {
            switch (this.state.name) {
              case 'airlineReportBeta':
                return <WebAirlineReport data={this.state.data} />

              case 'cardReportBeta':
                return <WebCardReport data={this.state.data} />

              case 'reservationReportBeta':
                return <WebReservationReport data={this.state.data} />

              case 'serviceTxnReport':
                return <WebServiceTxnReport data={this.state.data} />

              default:
                return (
                  <div className="w-full h-full p-10 flex justify-center">
                    <span className="text-base font-semibold">
                      Report not found
                    </span>
                  </div>
                )
            }
          })()}
        </div>
      </div>
    )
  }
}
