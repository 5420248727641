import React from 'react'
import { arrayPush, arrayRemove, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { isEmpty, findIndex } from 'lodash'
import { compose } from 'recompose'

import ProductCheckboxSelectTable, {
  ProductCheckboxSelectTableItem,
} from './ProductCheckboxSelectTable'
import withSearchProduct from './withSearchProduct'

class ProductIdCheckboxSelectTableContainer extends React.Component {
  componentDidMount() {
    if (!this.props.formId || !this.props.fieldName)
      throw new Error('No formId or fieldName specified')
  }

  handleCheckProduct = e => {
    const selectingProductId = e.target.name
    if (e.target.checked) {
      this.props.arrayPush(
        this.props.formId,
        this.props.fieldName,
        selectingProductId,
      )
    } else {
      const targetIndex = findIndex(
        this.props.selectedProductIds,
        id => id === selectingProductId,
      )
      if (targetIndex >= 0)
        this.props.arrayRemove(
          this.props.formId,
          this.props.fieldName,
          targetIndex,
        )
    }
  }

  isChecked = product => {
    let isChecked = false
    this.props.selectedProductIds.forEach(id => {
      if (id === product.id) isChecked = true
    })
    return isChecked
  }

  render() {
    const { productItems, disabled } = this.props
    return (
      <ProductCheckboxSelectTable
        searchText={this.props.searchText}
        onSearchTextChange={this.props.onSearchTextChange}
        isLoading={this.props.isLoading}
        isEmpty={isEmpty(this.props.productItems)}
      >
        {productItems &&
          productItems.map((product, index) => (
            <ProductCheckboxSelectTableItem
              key={product.id}
              input={{
                name: product.id,
                checked: this.isChecked(product),
                onChange: this.handleCheckProduct,
              }}              
              disabled={disabled}
              productName={product.name}
            />
          ))}
      </ProductCheckboxSelectTable>
    )
  }
}

export default compose(
  connect(
    (state, props) => ({
      selectedProductIds:
        formValueSelector(props.formId)(state, props.fieldName) || [],
    }),
    {
      arrayRemove,
      arrayPush,
    },
  ),
  withSearchProduct,
)(ProductIdCheckboxSelectTableContainer)
