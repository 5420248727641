import React from 'react'
import { Table } from 'react-bootstrap'
import PricingProductInput from './pricingContractInput/PricingProductInput'

const UpdatePricingProductTable = ({
  fields,
  pricingContract,
  formSyncErrors,
}) => (
  <Table>
    <thead>
      <tr>
        <th>{fields.product}</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {pricingContract.prices &&
        pricingContract.prices.map((pricingProduct, index) => (
          <tr key={pricingProduct._id}>
            <td>{pricingProduct.product.name}</td>
            <td>
              <PricingProductInput
                index={index}
                pricingContract={pricingContract}
                pricingProduct={pricingProduct}
                formSyncErrors={formSyncErrors}
              />
            </td>
          </tr>
        ))}
    </tbody>
  </Table>
)

export default UpdatePricingProductTable
