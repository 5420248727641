import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import CardTable from './CardTable'
import CardFormPage from './CardFormPage'
import { findAccessLevel } from '../../modules/accessRoute'

export class CardPage extends React.Component {
  handleSubmit = () => {
    this.props.history.push(`/card`)
  }

  table = () => <CardTable path="/card" />

  formPage = ({
    match: {
      params: { id },
    },
  }) => <CardFormPage id={id} onSubmit={this.handleSubmit} />

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/card"
          component={this.table}
          ifAnyGranted={findAccessLevel('/card')}
        />

        <PrivateRoute
          path="/card/new"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/card')}
        />

        <PrivateRoute
          path="/card/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/card')}
        />
      </div>
    )
  }
}
export default withRouter(CardPage)
